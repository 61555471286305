import { css, styled } from 'styled-components';

import { STATIC_COLORS } from '@components/web/src/foundations';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';

export const CheckboxContainer = styled(Flexbox)`
  position: relative;
`;

export const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  position: absolute;
  opacity: 0;
  margin: 0;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  z-index: -1;
`;

export const StyledCheckbox = styled(Flexbox)<{ $isChecked: boolean; $isDisabled: boolean }>`
  width: 16px;
  height: 16px;
  border-radius: 4px;
  border: 1px solid;
  flex-shrink: 0;
  border-color: ${STATIC_COLORS.gray['300']};
  background-color: ${STATIC_COLORS.base.white};
  transition: all 0.2s ease;

  ${({ $isChecked }) =>
    $isChecked &&
    css`
      border-color: ${STATIC_COLORS.teal['600']};
      background-color: ${STATIC_COLORS.teal['50']};
    `}

  &:hover {
    border-color: ${STATIC_COLORS.teal['600']};
    background-color: ${STATIC_COLORS.teal['50']};
  }

  &:focus {
    box-shadow: 0px 0px 0px 4px ${STATIC_COLORS.green['25']};
    background-color: ${STATIC_COLORS.base.white};
    border-color: ${STATIC_COLORS.teal['600']};
  }

  ${({ $isDisabled }) =>
    $isDisabled &&
    css`
      cursor: not-allowed;
      pointer-events: none;
      background-color: ${STATIC_COLORS.gray['100']};
      border-color: ${STATIC_COLORS.gray['200']};
    `}
`;

export const Icon = styled(Flexbox)`
  font-size: 12px;
`;

export const CheckboxLabel = styled.label<{ $isDisabled: boolean }>`
  cursor: ${({ $isDisabled }) => ($isDisabled ? 'not-allowed' : 'pointer')};
`;
