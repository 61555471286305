import { useMemo } from 'react';
import { ZodType, z } from 'zod';

import { localeCommon } from '@lib/tools/locale/source/web/common';
import { LocaleUtils } from '@lib/tools/locale/utils';

import AuthFormServerError from '@components/web/src/components/Authentication/AuthFormServerError/AuthFormServerError';
import GenericAuthForm from '@components/web/src/components/Authentication/GenericAuthForm/GenericAuthForm';
import { AuthFormItemTypes, LoginFormInputObject } from '@components/web/src/pages/Authentication/AuthFormInterface';

const LoginEmailForm = ({ handleFormSubmit, errors, handleForgotPasswordClick }) => {
  const { loginPage, commonMessages } = localeCommon;

  const { publishedTerms } = LocaleUtils;

  const emailValidationText = publishedTerms[commonMessages.emailRequired?.id];
  const validEmailValidationText = publishedTerms[commonMessages.validEmailValidation?.id];
  const passwordTooShortValidationText = publishedTerms[loginPage.passwordTooShortValidation?.id];
  const emailPlaceholderText = publishedTerms[commonMessages.emailPlaceholder?.id];

  const loginSchema: ZodType<LoginFormInputObject> = useMemo(
    () =>
      z.object({
        email: z.string().nonempty(emailValidationText).email({ message: validEmailValidationText }),
        password: z.string().nonempty(passwordTooShortValidationText),
      }),
    [],
  );

  const loginFormFields: AuthFormItemTypes<keyof LoginFormInputObject>[] = useMemo(
    () => [
      {
        label: commonMessages.emailField,
        name: 'email',
        placeholder: emailPlaceholderText,
        type: 'email',
      },
      {
        label: commonMessages.passwordField,
        name: 'password',
        placeholder: '',
        type: 'password',
      },
    ],
    [],
  );

  return (
    <div>
      <GenericAuthForm
        disableCTA={!!errors}
        forgotPasswordBtnText={commonMessages.forgotPasswordText}
        formFields={loginFormFields}
        handleLinkClick={handleForgotPasswordClick}
        isCTAHidden={false}
        submitBtnText={loginPage.submitBtnText}
        validationSchema={loginSchema}
        onSubmitHandler={handleFormSubmit}
      />
      <AuthFormServerError authErrors={errors} />
    </div>
  );
};

export default LoginEmailForm;
