import styled from 'styled-components';

import { SCREEN_SIZE, STATIC_COLORS } from '@components/web/src/foundations';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';
import { Image } from '@components/web/src/foundations/Image/Image';

export const CharacterDetailsContainer = styled(Flexbox)<{ $productCategory: string }>`
  position: fixed;
  max-width: ${SCREEN_SIZE.vhApp};
  width: 100%;
  color: ${STATIC_COLORS.base.white};
  border-radius: 0;
  background-color: ${({ $productCategory }) => STATIC_COLORS.productColors[$productCategory]['500']};
`;

export const CharacterImage = styled(Image)`
  min-width: 137px;
  padding-top: 16px;
  background: radial-gradient(48.4% 50% at 51.6% 50%, rgba(255, 255, 255, 0.33) 42%, rgba(255, 255, 255, 0) 99.58%);
`;

export const CharacterDescriptionWrapper = styled.div`
  padding: 16px 0 8px 0;
`;

export const CtaButton = styled.button`
  all: unset;
  max-height: 18px;
  text-decoration: underline;
  cursor: pointer;
`;

export const ImageWrapper = styled(Flexbox)<{ $productCategory: string }>`
  width: 137px;
  background: radial-gradient(48.4% 50% at 51.6% 50%, rgba(255, 255, 255, 0.33) 42%, rgba(255, 255, 255, 0) 99.58%);

  svg {
    path {
      fill: ${({ $productCategory }) => STATIC_COLORS.productColors[$productCategory]['500']};
    }
  }
`;
