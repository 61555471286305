import { FC, useEffect, useRef, useState } from 'react';

import { TCharactersByProductCategory } from '@lib/core/characters/types';
import { TProductCategory } from '@lib/core/products/types';
import { localeCommon } from '@lib/tools/locale/source/web/common';

import { ReactComponent as LeftChevronIcon } from '@components/web/src/assets/legacy/icons/icon_chevron-left.svg';
import { ReactComponent as RightChevronIcon } from '@components/web/src/assets/legacy/icons/icon_chevron-right.svg';
import { STATIC_COLORS } from '@components/web/src/foundations';
import { Text } from '@components/web/src/foundations/Text/Text';
import CharactersMap from '@components/web/src/organisms/CharactersMapScroller/CharactersMap/CharactersMap';
import * as S from '@components/web/src/organisms/CharactersMapScroller/styles';

interface IProps {
  productCategory: TProductCategory;
  charactersCount: number | null;
  charactersBySubCategory: TCharactersByProductCategory;
  lang?: string;
  userCharacterName?: string;
  groupCount?: number | null;
}

const CharactersMapScroller: FC<IProps> = ({
  productCategory,
  charactersCount,
  charactersBySubCategory,
  lang,
  userCharacterName,
  groupCount,
}) => {
  const scrollRef = useRef(null);
  const [scrollTargetItem, setScrollTargetItem] = useState<HTMLDivElement | null>(null);

  const scrollToUserCharacter = () => {
    if (scrollRef.current && userCharacterName) {
      const targetItem = scrollRef.current.querySelector(`[data-itemid=${userCharacterName}]`);
      if (targetItem) {
        setScrollTargetItem(targetItem);
      }
    }
  };

  useEffect(() => {
    if (scrollTargetItem) {
      scrollRef.current.scrollTo({
        behavior: 'smooth',
        left: scrollTargetItem.offsetLeft,
      });
    }
  }, [scrollTargetItem]);

  useEffect(() => {
    scrollToUserCharacter();
  }, [userCharacterName, scrollRef, charactersBySubCategory]);

  const handleScroll = scrollDirection => {
    if (scrollDirection === 'right') {
      scrollRef.current.scrollLeft += scrollRef.current?.offsetWidth / 1.5;
    } else {
      scrollRef.current.scrollLeft -= scrollRef.current?.offsetWidth / 1.5;
    }
  };
  const { productCategories, productCategoriesPlural } = localeCommon;
  const { productIntro, productCharactersName, charactersCountDescription } = localeCommon.charactersMapScroller;
  const productCategoryText = productCategories[productCategory];
  const productCategoryPluralText = productCategoriesPlural[productCategory];

  return (
    <S.CharactersMapScrollerContainer isFullWidth align="flex-start" direction="column" gap={0}>
      <S.CharactersInfoHeader isFullWidth direction="column" gap={8}>
        <Text
          color={STATIC_COLORS.productColors?.[productCategory]?.['500']}
          fontFamily="Fraunces"
          localeIndex={{ productCategory }}
          localeVariables={{ productCategoryText }}
          size="h6"
          text={productIntro}
          weight="semibold"
        />
        <Text
          fontFamily="Fraunces"
          size="h4"
          text={productCharactersName}
          weight="semibold"
          localeIndex={{
            productCategory,
          }}
          localeVariables={{
            productCategoryPluralText,
          }}
        />
        <Text
          localeIndex={{ productCategory }}
          size="body1"
          text={charactersCountDescription}
          localeOptions={{
            charactersCount: String(charactersCount),
            groupCount: String(groupCount),
          }}
          localeVariables={{
            productCategoryPluralText,
          }}
        />
      </S.CharactersInfoHeader>
      <S.CharactersMapScrollWrapper isFullWidth direction="column" gap={0}>
        <S.ScrollBody ref={scrollRef} align="flex-start" direction="column" gap={0} justify="flex-start">
          <S.ScrolledCharacterMap align="flex-start" gap={80} justify="flex-start">
            {Object.keys(charactersBySubCategory)?.map((character, index) => {
              const characters = charactersBySubCategory[character];
              return (
                <CharactersMap key={index} characterSubCategoryText={character} characters={characters} lang={lang} />
              );
            })}
          </S.ScrolledCharacterMap>
        </S.ScrollBody>
        <S.ScrollButtonsContainer align="center" gap={0} justify="space-between">
          <S.ScrollButtonIconContainer aria-hidden onClick={() => handleScroll('left')}>
            <LeftChevronIcon />
          </S.ScrollButtonIconContainer>
          <S.ScrollButtonIconContainer aria-hidden onClick={() => handleScroll('right')}>
            <RightChevronIcon />
          </S.ScrollButtonIconContainer>
        </S.ScrollButtonsContainer>
      </S.CharactersMapScrollWrapper>
    </S.CharactersMapScrollerContainer>
  );
};

export default CharactersMapScroller;
