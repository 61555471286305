import React, { FC } from 'react';

import { TProductCategory, TProductInstance } from '@lib/core/products/types';
import { parseProductInstance } from '@lib/core/products/utils';
import { localeCommon } from '@lib/tools/locale/source/web/common';
import { localeWidget } from '@lib/tools/locale/source/web/widget';
import { LocaleUtils } from '@lib/tools/locale/utils';

import { STATIC_COLORS } from '@components/web/src/foundations';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';
import { Text } from '@components/web/src/foundations/Text/Text';
import * as S from '@components/web/src/templates/ThirdPartyConsentPage/styles';

interface IProps {
  productCategory: TProductCategory;
  productInstanceData: TProductInstance;
  contentRef: React.MutableRefObject<HTMLDivElement>;
}

export const DefaultThirdPartyContent: FC<IProps> = ({ productCategory, contentRef, productInstanceData }) => {
  const { productCategories } = localeCommon;
  const { defaultTitle } = localeWidget.thirdPartyConsentPage;
  const productCategoryText = LocaleUtils.publishedTerms[productCategories[productCategory]?.id];

  const { productName, productImage, productCharacterTechnicalCaption } = parseProductInstance({
    productInstanceData,
  });

  return (
    <Flexbox ref={contentRef} direction="column" gap={8} padding="32px 16px">
      <Text
        color={STATIC_COLORS.base.white}
        linesLimit={1}
        size="body1"
        text={defaultTitle}
        weight="medium"
        localeOptions={{
          productCategory,
          productCategoryText,
        }}
      />
      <Flexbox align="center" direction="column" gap={16}>
        <Text
          color={STATIC_COLORS.base.white}
          fontFamily="Fraunces"
          linesLimit={2}
          size="h4"
          text={productName}
          weight="semibold"
        />
        <S.ProductImageWrapper align="center" justify="center">
          <img alt="product" src={productImage} />
        </S.ProductImageWrapper>
        <Text
          color={STATIC_COLORS.base.white}
          linesLimit={1}
          size="body1"
          text={productCharacterTechnicalCaption}
          weight="medium"
        />
      </Flexbox>
    </Flexbox>
  );
};
