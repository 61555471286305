import { localeCommon } from '@lib/tools/locale/source/web/common';
import { localeWidget } from '@lib/tools/locale/source/web/widget';
import { currentYear } from '@lib/tools/shared/helpers/utils';

import * as S from '@components/web/src/atoms/HeaderFooter/HeaderFooterWidget/styles';
import { STATIC_COLORS } from '@components/web/src/foundations';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';
import { Text } from '@components/web/src/foundations/Text/Text';
import FooterWave from '@components/web/src/shared/Footer/FooterWave';

export const FooterWidgetAnonymousWave = () => {
  const { copyrightText } = localeCommon.commonMessages;
  const { footerTitle, footerSubtitle } = localeWidget.homePage;

  return (
    <Flexbox isFullWidth direction="column" gap={0}>
      <FooterWave />
      <S.FooterContent direction="column" gap={16} padding="16px 16px 40px">
        <Text
          color={STATIC_COLORS.base.white}
          fontFamily="Fraunces"
          size="subtitle1"
          text={footerTitle}
          weight="semibold"
        />
        <Text color={STATIC_COLORS.base.white} size="body2" text={footerSubtitle} weight="semibold" />
        <Text color={STATIC_COLORS.base.white} localeOptions={{ currentYear }} size="body3" text={copyrightText} />
      </S.FooterContent>
    </Flexbox>
  );
};
