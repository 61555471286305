import { FC } from 'react';

import { isApplicationKiosk } from '@lib/core/service/consts';
import { localeWidget } from '@lib/tools/locale/source/web/widget';

import { Text } from '@components/web/src/foundations/Text/Text';
import KioskCirclesBackground from '@components/web/src/kiosk/KioskCirclesBackground/KioskCirclesBackground';
import * as S from '@components/web/src/templates/LegalPages/PrivacyPolicyPage/styles';

interface IProps {
  isApplicationKioskProp?: boolean;
}
const PrivacyPolicyPage: FC<IProps> = ({ isApplicationKioskProp = false }) => {
  const { privacyPolicyPage } = localeWidget;
  const actualIsApplicationKiosk = isApplicationKiosk || isApplicationKioskProp;
  const {
    mainTitle,
    mainDescription,
    // privacy
    privacyImportantTitle,
    privacyImportantParagraph1,
    privacyImportantParagraph2,
    privacyImportantParagraph3,
    privacyImportantParagraph4,
    privacyImportantParagraph5,
    privacyImportantParagraph6,
    privacyImportantItem1,
    privacyImportantItem2,
    privacyImportantItem3,
    privacyImportantItem4,
    privacyImportantItem5,
    privacyImportantItem6,
    // data controller
    dataControllerTitle,
    dataControllerParagraph1,
    dataControllerParagraph2,
    // data process
    dataProcessMainTitle,
    dataProcessItem1,
    dataProcessItem2,
    dataProcessSubItem1,
    dataProcessSubItem2,
    dataProcessParagraph1,
    dataProcessParagraph2,
    // data collection
    dataCollectTitle,
    dataCollectParagraph1,
    dataUseForTitle,
    dataUseForParagraph1,
    dataUseForParagraph2,
    dataUseForParagraph3,
    dataUseForParagraph4,
    dataUseForItem1,
    dataUseForItem2,
    dataUseForItem3,
    dataUseForItem4,
    dataUseForItem5,
    dataProcessingMethodTitle,
    dataProcessingMethodParagraph1,
    dataProcessingMethodParagraph2,
    // data context
    dataContextShareTitle,
    dataContextShareParagraph1,
    dataContextShareParagraph2,
    dataContextShareParagraph3,
    dataContextShareParagraph4,
    dataContextShareForItem1,
    dataContextShareForItem2,
    dataContextShareForItem3,
    dataContextShareForItem4,
    dataContextShareForItem5,
    // automated process
    automatedProcessTitle,
    automatedProcessParagraph1,
    automatedProcessParagraph2,
    automatedProcessParagraph3,
    automatedProcessParagraph4,
    automatedProcessParagraph5,
    automatedProcessParagraph6,
    // transfer data
    transferDataTitle,
    transferDataParagraph1,
    transferDataItem1,
    transferDataItem2,
    // rights
    yourRightsTitle,
    yourRightsParagraph1,
    yourRightsParagraph2,
    yourRightsItem1,
    yourRightsItem2,
    yourRightsItem3,
    yourRightsItem4,
    yourRightsItem5,
    yourRightsItem6,
    yourRightsItem7,
    exerciseRightsTitle,
    exerciseRightsParagraph1,
    privacyPolicyParagraph1,
  } = privacyPolicyPage;

  return (
    <S.PrivacyPolicyPage $isApplicationKiosk={actualIsApplicationKiosk}>
      {isApplicationKiosk && <KioskCirclesBackground />}

      <S.MainTitle $isApplicationKiosk={actualIsApplicationKiosk}>
        <Text fontFamily="Fraunces" size="h5" text={mainTitle} weight="semibold" />
      </S.MainTitle>
      <Text size="body2" text={mainDescription} />

      <S.Section $isApplicationKiosk={actualIsApplicationKiosk}>
        <S.SectionTitle>
          <Text size="body2" text={privacyImportantTitle} weight="bold" />
        </S.SectionTitle>
        <Text size="body2" text={privacyImportantParagraph1} />
        <Text size="body2" text={privacyImportantParagraph2} />
        <Text size="body2" text={privacyImportantParagraph3} />
        <Text size="body2" text={privacyImportantParagraph4} />
        <ul>
          <li>
            <Text size="body2" text={privacyImportantItem1} />
          </li>
          <li>
            <Text size="body2" text={privacyImportantItem2} />
          </li>
          <li>
            <Text size="body2" text={privacyImportantItem3} />
          </li>
          <li>
            <Text size="body2" text={privacyImportantItem4} />
          </li>
        </ul>
        <Text size="body2" text={privacyImportantParagraph5} />
        <Text size="body2" text={privacyImportantParagraph6} />
        <ul>
          <li>
            <Text size="body2" text={privacyImportantItem5} />
          </li>
          <li>
            <Text size="body2" text={privacyImportantItem6} />
          </li>
        </ul>
      </S.Section>

      <S.Section $isApplicationKiosk={actualIsApplicationKiosk}>
        <S.SectionTitle>
          <Text size="body2" text={dataControllerTitle} weight="bold" />
        </S.SectionTitle>
        <Text size="body2" text={dataControllerParagraph1} />
        <Text size="body2" text={dataControllerParagraph2} />
      </S.Section>

      <S.Section $isApplicationKiosk={actualIsApplicationKiosk}>
        <S.SectionMainTitle>
          <Text size="subtitle2" text={dataProcessMainTitle} weight="medium" />
        </S.SectionMainTitle>

        <ul>
          <li>
            <Text size="body2" text={dataProcessItem1} />
          </li>
          <li>
            <Text size="body2" text={dataProcessItem2} />
          </li>
          <ul>
            <li>
              <Text size="body2" text={dataProcessSubItem1} />
            </li>
            <li>
              <Text size="body2" text={dataProcessSubItem2} />
            </li>
          </ul>
        </ul>
        <Text size="body2" text={dataProcessParagraph1} />
        <Text size="body2" text={dataProcessParagraph2} />
      </S.Section>

      <S.Section $isApplicationKiosk={actualIsApplicationKiosk}>
        <S.SectionMainTitle>
          <Text size="subtitle2" text={dataCollectTitle} weight="medium" />
        </S.SectionMainTitle>
        <Text size="body2" text={dataCollectParagraph1} />
      </S.Section>

      <S.Section $isApplicationKiosk={actualIsApplicationKiosk}>
        <S.SectionMainTitle>
          <Text size="subtitle2" text={dataUseForTitle} weight="medium" />
        </S.SectionMainTitle>
        <Text size="body2" text={dataUseForParagraph1} />

        <ul>
          <li>
            <Text size="body2" text={dataUseForItem1} />
          </li>
          <li>
            <Text size="body2" text={dataUseForItem2} />
          </li>
          <li>
            <Text size="body2" text={dataUseForItem3} />
          </li>
          <li>
            <Text size="body2" text={dataUseForItem4} />
          </li>
          <li>
            <Text size="body2" text={dataUseForItem5} />
          </li>
        </ul>

        <Text size="body2" text={dataUseForParagraph2} />
        <Text size="body2" text={dataUseForParagraph3} />
        <Text size="body2" text={dataUseForParagraph4} />
      </S.Section>

      <S.Section $isApplicationKiosk={actualIsApplicationKiosk}>
        <S.SectionMainTitle>
          <Text size="subtitle2" text={dataProcessingMethodTitle} weight="medium" />
        </S.SectionMainTitle>
        <Text size="body2" text={dataProcessingMethodParagraph1} />
        <Text size="body2" text={dataProcessingMethodParagraph2} />
      </S.Section>

      <S.Section $isApplicationKiosk={actualIsApplicationKiosk}>
        <S.SectionMainTitle>
          <Text size="subtitle2" text={dataContextShareTitle} weight="medium" />
        </S.SectionMainTitle>
        <Text size="body2" text={dataContextShareParagraph1} />
        <Text size="body2" text={dataContextShareParagraph2} />

        <ul>
          <li>
            <Text size="body2" text={dataContextShareForItem1} />
          </li>
          <ul>
            <li>
              <Text size="body2" text={dataContextShareForItem2} />
            </li>
            <li>
              <Text size="body2" text={dataContextShareForItem3} />
            </li>
            <li>
              <Text size="body2" text={dataContextShareForItem4} />
            </li>
            <li>
              <Text size="body2" text={dataContextShareForItem5} />
            </li>
          </ul>
        </ul>
        <Text size="body2" text={dataContextShareParagraph3} />
        <Text size="body2" text={dataContextShareParagraph4} />
      </S.Section>

      <S.Section $isApplicationKiosk={actualIsApplicationKiosk}>
        <S.SectionMainTitle>
          <Text size="subtitle2" text={automatedProcessTitle} weight="medium" />
        </S.SectionMainTitle>
        <Text size="body2" text={automatedProcessParagraph1} />
        <Text size="body2" text={automatedProcessParagraph2} />
        <Text size="body2" text={automatedProcessParagraph3} />
        <Text size="body2" text={automatedProcessParagraph4} />
        <Text size="body2" text={automatedProcessParagraph5} />
        <Text size="body2" text={automatedProcessParagraph6} />
      </S.Section>

      <S.Section $isApplicationKiosk={actualIsApplicationKiosk}>
        <S.SectionMainTitle>
          <Text size="subtitle2" text={transferDataTitle} weight="medium" />
        </S.SectionMainTitle>
        <Text size="body2" text={transferDataParagraph1} />

        <ul>
          <li>
            <Text size="body2" text={transferDataItem1} />
          </li>
          <li>
            <Text size="body2" text={transferDataItem2} />
          </li>
        </ul>
      </S.Section>

      <S.Section $isApplicationKiosk={actualIsApplicationKiosk}>
        <S.SectionMainTitle>
          <Text size="subtitle2" text={yourRightsTitle} weight="medium" />
        </S.SectionMainTitle>
        <Text size="body2" text={yourRightsParagraph1} />

        <ul>
          <li>
            <Text size="body2" text={yourRightsItem1} />
          </li>
          <li>
            <Text size="body2" text={yourRightsItem2} />
          </li>
          <li>
            <Text size="body2" text={yourRightsItem3} />
          </li>
          <li>
            <Text size="body2" text={yourRightsItem4} />
          </li>
          <li>
            <Text size="body2" text={yourRightsItem5} />
          </li>
          <li>
            <Text size="body2" text={yourRightsItem6} />
          </li>
          <li>
            <Text size="body2" text={yourRightsItem7} />
          </li>
        </ul>
        <Text size="body2" text={yourRightsParagraph2} />
      </S.Section>

      <S.Section $isApplicationKiosk={actualIsApplicationKiosk}>
        <S.SectionMainTitle>
          <Text size="subtitle2" text={exerciseRightsTitle} weight="medium" />
        </S.SectionMainTitle>
        <Text size="body2" text={exerciseRightsParagraph1} />
      </S.Section>

      <S.Section $isApplicationKiosk={actualIsApplicationKiosk}>
        <Text size="body2" text={privacyPolicyParagraph1} />
      </S.Section>
    </S.PrivacyPolicyPage>
  );
};

export default PrivacyPolicyPage;
