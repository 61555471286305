import styled from 'styled-components';

import { TTabsGradientVariant, TTabsVariant } from '@components/web/src/atoms/Tabs/Tabs/Tabs';
import { DEFAULT_COLOR_THEME, STATIC_COLORS, STATIC_GRADIENTS, customGradient } from '@components/web/src/foundations';

export const TabsContainer = styled.div`
  position: relative;
  z-index: 3;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  background-color: ${DEFAULT_COLOR_THEME.secondaryColor['-100']};
`;

export const TabsWrapper = styled.div<{ $customZIndex?: number }>`
  position: relative;
  width: 100%;
  z-index: ${({ $customZIndex }) => $customZIndex || 1};
`;

export const Tabs = styled.div<{
  $gradientVariant: TTabsGradientVariant;
  $isHiddenTabs: boolean;
  $tabsVariant: TTabsVariant;
}>`
  display: ${({ $isHiddenTabs }) => ($isHiddenTabs ? 'none' : 'flex')};
  flex-flow: row nowrap;
  gap: ${({ $tabsVariant }) => ($tabsVariant === 'default' ? '32px' : '0')};
  align-items: flex-end;
  justify-content: ${({ $tabsVariant }) => ($tabsVariant === 'autoSpaced' ? 'space-between' : 'flex-start')};
  padding: 16px ${({ $tabsVariant }) => ($tabsVariant === 'fullSize' ? '0' : '16px')} 0;
  overflow-y: auto;
  background: ${({ $gradientVariant }) => {
    if ($gradientVariant === 'default-up') {
      return STATIC_GRADIENTS.warmGradientReversed();
    }
    if ($gradientVariant === 'default-down') {
      return STATIC_GRADIENTS.warmGradient();
    }
    if ($gradientVariant === 'custom-up') {
      return customGradient('var(--color-secondary-25)', 'var(--color-secondary-100)');
    }
    return customGradient('var(--color-secondary-100)', 'var(--color-secondary-25)');
  }};

  /* Hide scrollbar for WebKit-based browsers */
  &::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for Firefox */
  scrollbar-width: none;

  /* Hide scrollbar for Safari and Internet Explorer 10+ */
  -ms-overflow-style: none;
`;

export const Divider = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  border-bottom: 2px solid ${STATIC_COLORS.warmGray[300]};
`;
