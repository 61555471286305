import { FC, ReactNode } from 'react';
import { ToastContainer } from 'react-toastify';

import VinhoodHeader, { HeaderAppProps } from '@components/web/src/app/HeaderApp/HeaderApp';
import { FooterAppAnonymous, FooterAppLogged } from '@components/web/src/atoms/Footers';
import { IFooterAppLoggedProps } from '@components/web/src/atoms/Footers/FooterAppLogged/FooterAppLogged';

type Props = {
  children: ReactNode;
  headerProps: HeaderAppProps;
  footerProps: IFooterAppLoggedProps & { showGearIcon?: boolean; shouldHideFooter?: boolean };
  isAuthenticated?: boolean;
  showGearIcon?: boolean;
  isAuthFooter?: boolean;
};

const HeaderFooterApp: FC<Props> = ({ children, headerProps, footerProps, isAuthFooter }) => {
  return (
    <>
      <div className="app-body-content">
        <div className="top-foot-hoc">
          <VinhoodHeader {...headerProps} />
          <div className="app-body">
            <div className="content" id="root-scroll-element">
              {children}
            </div>
            {isAuthFooter && <FooterAppLogged {...footerProps} />}
            {!isAuthFooter && !footerProps.shouldHideFooter && (
              <FooterAppAnonymous showGearIcon={footerProps.showGearIcon} />
            )}
          </div>
        </div>
      </div>
      <ToastContainer
        closeOnClick
        draggable
        pauseOnFocusLoss
        pauseOnHover
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        position="top-right"
        rtl={false}
        theme="light"
      />
    </>
  );
};

export default HeaderFooterApp;
