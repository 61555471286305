import { useSelector } from 'react-redux';

import { selectCharactersByCategory } from '@lib/core/characters/selectors';
import { selectLocale } from '@lib/core/service/selectors';

import CharactersMapScroller from '@components/web/src/organisms/CharactersMapScroller/CharactersMapScroller';

const CharacterMapScrollerContainer = ({ productCategory, characterName }) => {
  // TodO retest this container
  const charactersByCategory = useSelector(selectCharactersByCategory);

  const charactersCount = Object.values(charactersByCategory).reduce((sum, currentArray = []) => {
    return sum + currentArray.length;
  }, 0);

  const lang = useSelector(selectLocale);

  return (
    <CharactersMapScroller
      charactersBySubCategory={charactersByCategory}
      charactersCount={charactersCount}
      groupCount={Object.keys(charactersByCategory).length}
      lang={lang}
      productCategory={productCategory}
      userCharacterName={characterName}
    />
  );
};

export default CharacterMapScrollerContainer;
