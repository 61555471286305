export const platformServiceTermsCategories = {
  cookies: 'cookies',
  marketing: 'marketing',
  policy: 'policy',
  profiling: 'profiling',
} as const;

export const retailerServiceTermsCategories = {
  thirdParty: '3-rd-party',
} as const;

export const SERVICE_TERMS_CATEGORIES = { ...platformServiceTermsCategories, ...retailerServiceTermsCategories };
