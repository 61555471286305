import { FC } from 'react';

import { ILocaleText } from '@lib/tools/locale/views/LocaleFragment';

import { Image } from '@components/web/src/foundations/Image/Image';
import { Text } from '@components/web/src/foundations/Text/Text';
import * as S from '@components/web/src/organisms/Cards/ChefRecommendsCard/styled';

export interface IRecipe {
  title: ILocaleText;
  subtitle: ILocaleText;
  image: string;
}

const ChefRecommendsCard: FC<IRecipe> = ({ title, subtitle, image }) => {
  return (
    <S.ChefRecommendsCardContainer>
      <Image alt={`recipe ${title}`} height="240px" objectFit="cover" src={image} width="100%" />
      <S.ContentContainer>
        <Text size="body2" text={subtitle} />
        <br />
        <Text size="subtitle1" text={title} weight="semibold" />
      </S.ContentContainer>
    </S.ChefRecommendsCardContainer>
  );
};

export default ChefRecommendsCard;
