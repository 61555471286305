import { useNavigate } from 'react-router-dom';

import PrivacyPolicyPage from '@components/web/src/templates/LegalPages/PrivacyPolicyPage/PrivacyPolicyPage';
import * as S from '@components/web/src/templates/LegalPages/PrivacyPolicyPage/styles';

const PrivacyPolicyContainer = () => {
  const navigate = useNavigate();

  return (
    <S.PrivacyPolicyContainer>
      <S.StyledBackButton handleClick={() => navigate(-1)} />
      <PrivacyPolicyPage />;
    </S.PrivacyPolicyContainer>
  );
};

export default PrivacyPolicyContainer;
