import { FC } from 'react';

import { TCharacter } from '@lib/core/characters/types';

import { STATIC_COLORS } from '@components/web/src/foundations';
import * as S from '@components/web/src/organisms/CharacterScroller/styles';

interface IProps {
  character: TCharacter;
  isActive: boolean;
}

export const CharacterScrollerItem: FC<IProps> = ({ character, isActive }) => {
  const { image, product_category: characterProductCategory } = character;
  return (
    <S.CharacterScrollerItemContainer $isActive={isActive}>
      <img alt="icon" src={image} />
      <S.BackgroundIcon
        $backgroundColor={STATIC_COLORS.productColors[characterProductCategory][50]}
        $isActive={isActive}
      />
    </S.CharacterScrollerItemContainer>
  );
};
