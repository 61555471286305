import cn from 'classnames';
import { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { IB2CExperience } from '@app/native/src/interfaces/experience';

import { EXPERIENCES_TYPES } from '@lib/core/service/consts';
import { prependBasename } from '@lib/core/service/utils';
import { actionApplyCatalogFilter } from '@lib/tools/filterManager/slices/productFilter';
import { IFilterChangeAction } from '@lib/tools/filterManager/slices/sublistFilterSlice';
import { localeApp } from '@lib/tools/locale/source/web/app';
import LocaleFragment from '@lib/tools/locale/views/LocaleFragment';
import { PAGES } from '@lib/tools/views/urls';

import ExperienceResult from '@components/web/src/app/ExperienceResult/ExperienceResult';
import ExperienceSearch from '@components/web/src/app/ExperienceSearch/ExperienceSearch';
import backArrowImg from '@components/web/src/assets/legacy/b2c/icons/back-arrow.svg';
import Banner, { TBannerCard } from '@components/web/src/atoms/Banner/BannerCard';
import { ExperienceFilterContainer } from '@components/web/src/pages/app/ExperienceSearchPage/ExperienceFilterContainer';

interface IExperienceSearchCatalogProps {
  topBanner: TBannerCard;
  getRandomBanner: () => TBannerCard;
  searchProps: {
    searchHandler: (searchText: string) => void;
    inputValue: string;
  };
  experienceType: string;
  experiencesData: IB2CExperience[];
  searchCount: number;
  isExperienceRequestLoading: boolean;
  handleExperiencesRequest: ({ isPagination }: { isPagination?: boolean }) => void;
  handleBannerClick?: (route?: string, linkParams?: string) => void;
  handleUpdateFilterType: (filterItem: IFilterChangeAction) => void;
  handleResetPriceRange: () => void;
  handleUpdatePromotionToggleActive: (value: boolean) => void;
  handleUpdateCharactersToggleActive: (value: boolean) => void;
}

const ExperienceSearchPage: FC<IExperienceSearchCatalogProps> = ({
  searchProps,
  experiencesData,
  experienceType,
  topBanner,
  getRandomBanner,
  searchCount,
  isExperienceRequestLoading,
  handleExperiencesRequest,
  handleBannerClick,
  handleUpdateFilterType,
  handleResetPriceRange,
  handleUpdatePromotionToggleActive,
  handleUpdateCharactersToggleActive,
}) => {
  const dispatch = useDispatch();
  const { experienceSearchPage } = localeApp;

  const {
    backButtonText,
    tastingsTitle,
    experiencesTitle,
    onlineActivitiesTitle,
    coursesTitle,
    onlineClassesTitle,
    eventsTitle,
    overnightStaysTitle,
    searchCountText,
  } = experienceSearchPage;

  const titleText = {
    [EXPERIENCES_TYPES.courses]: coursesTitle,
    [EXPERIENCES_TYPES.events]: eventsTitle,
    [EXPERIENCES_TYPES.experiences]: experiencesTitle,
    [EXPERIENCES_TYPES.onlineActivities]: onlineActivitiesTitle,
    [EXPERIENCES_TYPES.onlineClasses]: onlineClassesTitle,
    [EXPERIENCES_TYPES.overnightStays]: overnightStaysTitle,
    [EXPERIENCES_TYPES.tastings]: tastingsTitle,
  };
  const [isFilterOpened, setIsFilterOpened] = useState(false);

  const navigate = useNavigate();

  const backButtonNavigateTo = () => navigate(prependBasename(PAGES.vinhood.experiences));

  const handleFilterApply = () => {
    setIsFilterOpened(false);
    dispatch(actionApplyCatalogFilter());
  };

  // ToDo [kukharets] use new container
  return isFilterOpened ? (
    <ExperienceFilterContainer
      handleApply={handleFilterApply}
      handleUpdateCharactersToggleActive={handleUpdateCharactersToggleActive}
      handleUpdatePromotionToggleActive={handleUpdatePromotionToggleActive}
      handleClose={() => {
        // ToDo use context API or something like that
        document.querySelector('#root-element').scrollTo(0, 0);
        setIsFilterOpened(false);
      }}
    />
  ) : (
    <div className="experience-search-catalog-container">
      <div className="experience-search-catalog-header">
        <div
          className="header-back-button"
          role="button"
          tabIndex={-1}
          onClick={backButtonNavigateTo}
          onKeyDown={backButtonNavigateTo}
        >
          <img alt="goBack" src={backArrowImg} />
          <span className="back-button-title">
            <LocaleFragment message={backButtonText} />
          </span>
        </div>
        <span className="header-title">
          <LocaleFragment message={titleText[experienceType]} />
        </span>
        {!!topBanner && <Banner {...topBanner} onClickHandler={handleBannerClick} />}
        <ExperienceSearch
          {...searchProps}
          experienceType={titleText[experienceType]}
          handleFilterApply={handleFilterApply}
          handleResetPriceRange={handleResetPriceRange}
          handleUpdateFilterType={handleUpdateFilterType}
          onFilterClick={() => {
            setIsFilterOpened(true);
          }}
        />
        <div className={cn({ hidden: !searchCount }, 'search-count')}>
          <div className="header-count">
            <span className="count">{searchCount ?? 0}</span>
            <span className="text">
              <LocaleFragment message={searchCountText} />
            </span>
          </div>
        </div>
      </div>
      <ExperienceResult
        experiencesData={experiencesData}
        getRandomBanner={getRandomBanner}
        handleBannerClick={handleBannerClick}
        handleExperiencesRequest={handleExperiencesRequest}
        isExperienceRequestLoading={isExperienceRequestLoading}
        searchCount={searchCount}
      />
    </div>
  );
};

export default ExperienceSearchPage;
