import Compressor from 'compressorjs';
import React, { FC, useRef } from 'react';

import { convertImageFiletoBase64 } from '@lib/core/service/utils';
import { localeApp } from '@lib/tools/locale/source/web/app';

import { ReactComponent as EditIcon } from '@components/web/src/assets/legacy/b2c/icons/icon_edit_white.svg';
import { ReactComponent as UserProfileDummyIcon } from '@components/web/src/assets/legacy/icons/user_profile_icon.svg';
import Button from '@components/web/src/atoms/Buttons/Button';
import { STATIC_COLORS } from '@components/web/src/foundations';
import { IMAGE_ERRORS } from '@components/web/src/foundations/consts';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';
import { Image } from '@components/web/src/foundations/Image/Image';
import { Text } from '@components/web/src/foundations/Text/Text';
import * as S from '@components/web/src/templates/TasteId/TasteIdSettingsPage/ProfilePictureSelection/styles';

const MAXIMUM_IMAGE_CONVERT_SIZE = 4000000;
const MAXIMUM_IMAGE_HEIGHT = 400;
const MAXIMUM_IMAGE_WIDTH = 400;
const MAXIMUM_IMAGE_QUALITY = 1;
const MAXIMUM_USER_UPLOAD_IMAGE_SIZE = 5;
const MAXIMUM_UPLOAD_IMAGE_SIZE_IN_KB = 500;

interface IProps {
  userImage: string;
  userImageUpdateError?: string;
  handleUserImageUpdate?: (base64ImageData: string) => void;
  handleUserImageUpdateError: React.Dispatch<React.SetStateAction<string>>;
}

const ProfilePictureSelection: FC<IProps> = ({
  userImage,
  userImageUpdateError,
  handleUserImageUpdateError,
  handleUserImageUpdate,
}) => {
  const profilePictureInputRef = useRef(null);
  const onProfilePictureEditHandler = () => profilePictureInputRef.current.click();

  const onImageChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files[0];
    handleUserImageUpdateError('');
    if (selectedFile) {
      const imageType =
        selectedFile.type === 'image/jpg' || selectedFile.type === 'image/png' || selectedFile.type === 'image/jpeg';
      if (!imageType) {
        handleUserImageUpdateError(IMAGE_ERRORS[415]);
        return;
      }
      if (selectedFile.size / 1024 / 1024 >= MAXIMUM_USER_UPLOAD_IMAGE_SIZE) {
        handleUserImageUpdateError(IMAGE_ERRORS[513]);
      } else if (selectedFile.size / 1024 >= MAXIMUM_UPLOAD_IMAGE_SIZE_IN_KB) {
        // eslint-disable-next-line no-new
        await new Compressor(selectedFile, {
          convertSize: MAXIMUM_IMAGE_CONVERT_SIZE,
          height: MAXIMUM_IMAGE_HEIGHT,
          quality: MAXIMUM_IMAGE_QUALITY,
          success: async compressedImage => {
            try {
              const base64ImageData = await convertImageFiletoBase64(compressedImage);
              handleUserImageUpdate(base64ImageData);
            } catch (err) {
              console.error(err);
            }
          },
          width: MAXIMUM_IMAGE_WIDTH,
        });
      } else {
        try {
          const base64ImageData = await convertImageFiletoBase64(event.target.files[0]);
          handleUserImageUpdate(base64ImageData);
        } catch (err) {
          console.error(err);
        }
      }
    }
  };
  const {
    profilePictureSelection: {
      profileHeaderText,
      profileImageInfoText,
      profileLargeImageErrorText,
      profileImageInvalidErrorText,
    },
  } = localeApp;

  return (
    <Flexbox direction="column" gap={0}>
      <Text color={STATIC_COLORS.gray[700]} size="body1" text={profileHeaderText} weight="medium" />
      <Flexbox isFullWidth align="center" gap={16} padding="6px 0 14px 6px">
        <S.ProfileAvatar align="center" justify="center">
          {userImage ? <Image alt="" borderRadius="200px" src={userImage} /> : <UserProfileDummyIcon />}
        </S.ProfileAvatar>
        <S.ProfileAction>
          <S.ProfileInput ref={profilePictureInputRef} accept="image/*" type="file" onChange={onImageChange} />
          <Button icon={<EditIcon />} size="sm" onClick={onProfilePictureEditHandler} />
        </S.ProfileAction>
        <Text color={STATIC_COLORS.gray[700]} size="body3" text={profileImageInfoText} />
      </Flexbox>
      {userImageUpdateError === IMAGE_ERRORS[513] && (
        <S.ProfileError>
          <Text color={STATIC_COLORS.base.red} size="body1" text={profileLargeImageErrorText} />
        </S.ProfileError>
      )}
      {userImageUpdateError === IMAGE_ERRORS[415] && (
        <S.ProfileError>
          <Text color={STATIC_COLORS.base.red} size="body1" text={profileImageInvalidErrorText} />
        </S.ProfileError>
      )}
    </Flexbox>
  );
};

export default ProfilePictureSelection;
