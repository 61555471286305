import { useEffect, useMemo, useRef, useState } from 'react';

import {
  BEER_CREAMINESS_ESSENCE_ID,
  HARDCODED_ESSENCE_DATA,
  WINE_STRUCTURE_ESSENCE_ID,
} from '@app/web/src/helpers/consts';

import { useCharacters } from '@lib/core/characters/hooks';
import { TCharacter } from '@lib/core/characters/types';
import { TProductCategory, TProductInstance } from '@lib/core/products/types';
import { getLimitedProductsListData, getProductsListData } from '@lib/core/products/utils';
import { useRetailerLocation } from '@lib/core/retailers/hooks/retailerLocation';
import { ASSOCIATED_RETAILER_SLUG_QUERY, GPRL_CHARACTER_QUERY, PRODUCT_CATEGORY_NONE } from '@lib/core/service/consts';
import { useApp } from '@lib/core/service/hooks';
import { useServiceInstance } from '@lib/core/service/hooks/useServiceInstance';
import { shuffleArray } from '@lib/core/service/utils';
import { useUser } from '@lib/core/users/hooks';
import { parseCharacterAdjectiveItemsData, parseCharacterAdjectiveModalData } from '@lib/tools/characterAdjective';
import {
  LIMITED_CATALOG,
  PRODUCT_CATEGORY_BEER,
  PRODUCT_CATEGORY_COFFEE,
  PRODUCT_CATEGORY_QUERY,
  PRODUCT_CATEGORY_WINE,
} from '@lib/tools/shared/helpers/consts';
import { useAddons } from '@lib/tools/views/hooks';

import { IQuizSectionQuizData } from '@components/web/src/organisms/TestResult/QuizSection/QuizSection';

const useTestResultData = () => {
  const { characters, isCharactersLoading } = useCharacters();

  const {
    isUserDataLoading,
    userBestMatchCharacterByProductCategory,
    userBestMatchCharacterForCurrentServiceProductCategory,
  } = useUser();
  const { isEnableTastePathAddon } = useAddons();

  const { locale, productCategory } = useApp();
  const { serviceInstanceProductCategory } = useServiceInstance();
  const { retailerLocationProductCategories, associatedRetailerLocation, retailerLocationCatalogType } =
    useRetailerLocation();
  const [isLoadingProduct, setIsLoadingProduct] = useState(false);
  const [resultProductInstanceData, setResultProductInstanceData] =
    useState<Record<TProductCategory, TProductInstance>>(undefined);
  const allProductCategories =
    productCategory === PRODUCT_CATEGORY_NONE ? retailerLocationProductCategories : [productCategory];
  const availableProductCategories = serviceInstanceProductCategory
    ? [serviceInstanceProductCategory]
    : allProductCategories;
  const productCategories = availableProductCategories.filter(
    category => category in userBestMatchCharacterByProductCategory,
  );

  const fetchProductsDataRef = useRef(false);
  useEffect(() => {
    const fetchProductsData = async () => {
      setIsLoadingProduct(true);

      const isLimitedCatalogType = retailerLocationCatalogType === LIMITED_CATALOG;

      try {
        const productInstances = await Promise.all(
          productCategories.map(async category => {
            const params = {
              [GPRL_CHARACTER_QUERY]: userBestMatchCharacterForCurrentServiceProductCategory?.identifier,
              [PRODUCT_CATEGORY_QUERY]: category,
              ...(isLimitedCatalogType && { [ASSOCIATED_RETAILER_SLUG_QUERY]: associatedRetailerLocation?.slug }),
              limit: 1,
              offset: 0,
            };

            if (isLimitedCatalogType) {
              const res = await getLimitedProductsListData(params);
              return res?.first_priority?.[0] || res?.second_priority?.[0] || res?.third_priority?.[0];
            }

            const res = await getProductsListData(params);
            return res.results?.[0];
          }),
        );

        const updatedProductInstanceData = productCategories.reduce((acc, category, index) => {
          acc[category] = productInstances[index];
          return acc;
        }, {});
        setResultProductInstanceData(updatedProductInstanceData);
      } finally {
        setIsLoadingProduct(false);
      }
    };

    if (
      !fetchProductsDataRef.current &&
      !isLoadingProduct &&
      productCategories.length &&
      !resultProductInstanceData &&
      !isEnableTastePathAddon
    ) {
      fetchProductsDataRef.current = true;
      fetchProductsData();
    }
  }, [productCategories, isLoadingProduct]);

  const quizData: Record<string, IQuizSectionQuizData[]> = useMemo(() => {
    if (!Object.keys(userBestMatchCharacterByProductCategory).length || !locale) return null;

    const getNonUserCharacters = (userCharacter: TCharacter) =>
      shuffleArray(
        characters.filter(
          product =>
            product.identifier !== userCharacter.identifier &&
            product.product_category === userCharacter.product_category,
        ),
      )[0];

    return productCategories.reduce((acc, category) => {
      const filteredCharacters = getNonUserCharacters(userBestMatchCharacterByProductCategory[category]);

      const getQuestion = characterData => {
        let question = '';
        if (category === PRODUCT_CATEGORY_WINE) {
          question = characterData.attributes[HARDCODED_ESSENCE_DATA[WINE_STRUCTURE_ESSENCE_ID].slug[locale]];
        } else if (category === PRODUCT_CATEGORY_BEER) {
          question = characterData.attributes[HARDCODED_ESSENCE_DATA[BEER_CREAMINESS_ESSENCE_ID].slug[locale]];
        } else if (category === PRODUCT_CATEGORY_COFFEE) {
          question = characterData.technical_description;
        }
        return question;
      };
      acc[category] = shuffleArray([
        {
          question: getQuestion(userBestMatchCharacterByProductCategory[category]),
          result: true,
        },
        {
          question: getQuestion(filteredCharacters),
          result: false,
        },
      ]);
      return acc;
    }, {});
  }, [userBestMatchCharacterByProductCategory, locale]);

  const characterAdjectiveItemsData = useMemo(
    () => parseCharacterAdjectiveItemsData(userBestMatchCharacterByProductCategory, locale, productCategories),
    [userBestMatchCharacterByProductCategory],
  );

  const characterAdjectiveModalData = useMemo(
    () => parseCharacterAdjectiveModalData(userBestMatchCharacterByProductCategory, locale, productCategories),
    [userBestMatchCharacterByProductCategory],
  );

  const isTestResultDataLoading =
    !Object.keys(userBestMatchCharacterByProductCategory).length ||
    !characterAdjectiveItemsData ||
    !characterAdjectiveModalData ||
    !quizData ||
    isUserDataLoading ||
    isCharactersLoading;

  return {
    characterAdjectiveItemsData,
    characterAdjectiveModalData,
    isLoadingProduct,
    isTestResultDataLoading,
    productCategories,
    quizData,
    resultProductInstanceData,
    userBestMatchCharacterByProductCategory,
  };
};

export default useTestResultData;
