import { useDispatch } from 'react-redux';

import { useRetailer } from '@lib/core/retailers/hooks';
import { useApp } from '@lib/core/service/hooks';
import { useThirdParty } from '@lib/core/service/hooks/useThirdParty';
import { setIsThirdPartyConnectShown } from '@lib/core/service/slices';
import { ThirdPartyConnectModalScenario } from '@lib/core/service/types/interface';
import { useModals } from '@lib/tools/modals/hooks';

import ThirdPartyConnectModal from '@components/web/src/templates/Modals/ThirdPartyConnectModal/ThirdPartyConnectModal';

const ThirdPartyConnectModalContainer = () => {
  const dispatch = useDispatch();
  const { isThirdPartyConnectModalOpened, closeAllModals } = useModals();
  const { retailerTags, retailerName } = useRetailer();

  const { scenarioForThirdPartyConnectModalOpened, productCategory } = useApp();
  const { thirdPartyNewsletterTexts, handleThirdPartyAcceptance } = useThirdParty();

  const closeModalWithScenario = () => {
    if (scenarioForThirdPartyConnectModalOpened.scenario) {
      dispatch(setIsThirdPartyConnectShown(scenarioForThirdPartyConnectModalOpened.scenario));
    }
    closeAllModals();
  };

  const handleBtnClick = (acceptance: boolean) => {
    handleThirdPartyAcceptance(acceptance);
    closeModalWithScenario();
  };

  return (
    <ThirdPartyConnectModal
      handleBtnClick={handleBtnClick}
      hideModal={() => closeModalWithScenario()}
      isOpen={isThirdPartyConnectModalOpened}
      productCategory={productCategory}
      productName={scenarioForThirdPartyConnectModalOpened.productName}
      retailerLogo={retailerTags?.headerLogo}
      retailerName={retailerName}
      scenario={scenarioForThirdPartyConnectModalOpened.scenario as ThirdPartyConnectModalScenario}
      thirdPartyNewsletterTexts={thirdPartyNewsletterTexts}
    />
  );
};

export default ThirdPartyConnectModalContainer;
