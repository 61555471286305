import { FC } from 'react';

import { TProductInstance } from '@lib/core/products/types';
import { parseProductInstance } from '@lib/core/products/utils';
import { TProductFeedbackValue } from '@lib/core/users/slices/productFeedback';

import Feedback from '@components/web/src/atoms/Feedback/Feedback/Feedback';
import * as S from '@components/web/src/components/TastePath/TastePathProductItem/styles';
import { STATIC_COLORS } from '@components/web/src/foundations';
import { FlexboxContainer } from '@components/web/src/foundations/Flexbox/Flexbox';
import { Text } from '@components/web/src/foundations/Text/Text';

interface IProps {
  productInstanceData: TProductInstance;
  feedback: TProductFeedbackValue;
  isUpdatedItem: boolean;
  isFeedbackModalDisabled: boolean;
  handleUpdateFeedback: (feedback: TProductFeedbackValue, productId: string, productName?: string) => void;
}

const TastePathProductItem: FC<IProps> = ({
  productInstanceData,
  feedback,
  isUpdatedItem,
  isFeedbackModalDisabled,
  handleUpdateFeedback,
}) => {
  const { productCharacterName, productCharacterTechnicalCaption, productName, productProducerName } =
    parseProductInstance({ productInstanceData });

  const characterText = `#${productCharacterName} • ${productCharacterTechnicalCaption}`;

  return (
    <S.TastePathProductItem $isUpdatedItem={isUpdatedItem}>
      <FlexboxContainer $direction="column" $gap={4}>
        <Text color={STATIC_COLORS.green[600]} linesLimit={1} size="body3" text={characterText} weight="medium" />
        <Text
          color={STATIC_COLORS.base.black}
          fontFamily="Fraunces"
          linesLimit={2}
          size="subtitle2"
          text={productName}
          weight="semibold"
        />
        <Text color={STATIC_COLORS.base.black} size="body2" text={productProducerName} />
      </FlexboxContainer>
      <Feedback
        discoveryQuiz={undefined}
        feedback={feedback}
        handleUpdateFeedback={handleUpdateFeedback}
        isFeedbackModalDisabled={isFeedbackModalDisabled}
        productInstanceData={productInstanceData}
      />
    </S.TastePathProductItem>
  );
};

export default TastePathProductItem;
