import { css, styled } from 'styled-components';

import { SCREEN_SIZE, STATIC_COLORS, STATIC_GRADIENTS } from '@components/web/src/foundations';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';
import LoadingSpinner from '@components/web/src/templates/Loading/LoadingSpinner/LoadingSpinner';

export const PartnerSplashScreenContainer = styled(Flexbox)<{ $retailerLogo: string }>`
  position: absolute;
  inset: 0;
  z-index: 999999999;
  height: 100vh !important;
  background: ${STATIC_COLORS.base.white};

  ${({ $retailerLogo }) =>
    $retailerLogo
      ? css`
          animation: screenTransition 3s forwards;
          .retailer_logo {
            position: absolute;
            animation:
              showLogo 1s forwards 0s,
              hideLogo 1s forwards 2.5s;
          }

          .vinhood_logo,
          .loading_gif {
            opacity: 0;
          }

          .vinhood_logo {
            position: absolute;
            animation:
              fadeInVinhood 2s forwards 4.5s,
              fadeOutVinhood 1s forwards 7.5s;
          }

          .loading_gif {
            animation: showLoading 2s forwards 8s;
            img {
              width: 150px;
              height: 150px;
              object-fit: cover;
            }
          }
          /* Keyframes for logo display */
          @keyframes showLogo {
            0% {
              opacity: 0;
              transform: scale(0.7);
            }
            90% {
              opacity: 0.9;
              transform: scale(0.99);
            }
            100% {
              opacity: 1;
              transform: scale(1);
            }
          }

          @keyframes hideLogo {
            0% {
              opacity: 1;
              transform: scale(1);
            }
            100% {
              opacity: 0;
              transform: scale(0.9);
            }
          }
        `
      : css`
          animation: screenTransition 0s forwards;
          .vinhood_logo {
            position: absolute;
            animation:
              fadeInVinhood 2s forwards 0s,
              fadeOutVinhood 1s forwards 3s;
          }
          .loading_gif {
            opacity: 0;
            animation: showLoading 2s forwards 3.5s;
            img {
              width: 150px;
              height: 150px;
              object-fit: cover;
            }
          }
        `}

  @media (min-width: ${SCREEN_SIZE.vhApp}) {
    width: ${SCREEN_SIZE.vhApp};
    margin: auto;
    height: 86vh !important;
  }

  /* Keyframes for the screen transition */
  @keyframes screenTransition {
    0% {
      background: ${STATIC_COLORS.base.white};
    }
    90% {
      background: ${STATIC_COLORS.base.white};
    }
    100% {
      background: ${STATIC_GRADIENTS.primaryGradient};
    }
  }

  /* Keyframes for VINHOOD fade in */
  @keyframes fadeInVinhood {
    0% {
      opacity: 0;
      transform: translateY(5px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

  /* Keyframes for VINHOOD fade out */
  @keyframes fadeOutVinhood {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  /* Keyframes for Loading GIF display */
  @keyframes showLoading {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

export const StyledLoadingSpinner = styled(LoadingSpinner)`
  background: ${STATIC_COLORS.base.white};

  @media (min-width: ${SCREEN_SIZE.vhApp}) {
    width: 500px;
    margin-inline: auto;
    height: 86vh !important;
  }
`;
