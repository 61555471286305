import { useEffect, useMemo, useState } from 'react';

import { parseCharacterInfo } from '@lib/core/characters/utils/parseCharacterInfo';
import { TProductInstance } from '@lib/core/products/types';
import { parseProductInstance } from '@lib/core/products/utils';
import { useRetailerLocation } from '@lib/core/retailers/hooks/retailerLocation';
import { useApp } from '@lib/core/service/hooks';
import {
  useDietaryPreferences,
  useLocationList,
  useProductFeedback,
  useProductList,
  useUser,
} from '@lib/core/users/hooks';
import { TProductFeedback } from '@lib/core/users/slices/productFeedback';
import { ILocation } from '@lib/core/users/types';
import { parseCharacterAdjectiveItemsData, parseCharacterAdjectiveModalData } from '@lib/tools/characterAdjective';
import { localeCommon } from '@lib/tools/locale/source/web/common';
import { DIETARY_PREFERENCE_SLUG } from '@lib/tools/shared/helpers/consts';
import { useAddons, useRetailerDesignSet } from '@lib/tools/views/hooks';

import { ICharacterInfoCardProps } from '@components/web/src/organisms/Cards/CharacterInfo/CharacterInfo';
import { IReadMoreCardProps } from '@components/web/src/organisms/Cards/ReadMoreCard/ReadMoreCard';

const useTasteIdData = () => {
  const [isProductsByCategoryLoading, setProductsByCategoryLoading] = useState(false);

  const { personality, typeOf } = localeCommon.tasteId;
  const { isDesignSetVinhoodApp } = useRetailerDesignSet();
  const { isUserDataLoading, userBestMatchCharacterByProductCategory } = useUser();

  const { locale, productCategory } = useApp();
  const { retailerLocationProductCategories } = useRetailerLocation();
  const { isTasteIdResultPageAddon } = useAddons();
  const { feedbackData, isFeedbackLoading } = useProductFeedback();
  const { wishlistProductInstanceData, isWishlistProductListLoading } = useProductList();
  const { locationList, isLocationListLoading } = useLocationList();

  const { userDietaryPreferenceTags } = useDietaryPreferences();

  const userCategories = retailerLocationProductCategories.filter(
    category => category in userBestMatchCharacterByProductCategory,
  );
  const productCategories = !isDesignSetVinhoodApp || isTasteIdResultPageAddon ? [productCategory] : userCategories;

  const locationsByCategoryData: Record<string, ILocation[]> = useMemo(() => {
    if (!locationList.length) return {};

    return productCategories.reduce(
      (acc, category) => {
        acc[category] = locationList.filter(location =>
          location.retailer_location.product_categories.some(pc => pc.name === category),
        );
        return acc;
      },
      {} as Record<string, ILocation[]>,
    );
  }, [locationList]);

  const transformFeedbackData = (feedback: TProductFeedback[]) => feedback.map(item => item.product);

  const [ratedProductsData, setRatedProductsData] = useState(() => {
    const savedProducts = localStorage.getItem('ratedProductsData');

    if (!savedProducts) {
      const transformedFeedback = transformFeedbackData(feedbackData);
      return [...wishlistProductInstanceData, ...transformedFeedback];
    }

    return JSON.parse(savedProducts);
  });

  useEffect(() => {
    const addedItemsSet = new Set(ratedProductsData.map(item => item.identifier));

    const addNewItemToRatedProducts = newItems => {
      const uniqueNewItems = newItems.filter(item => !addedItemsSet.has(item.identifier));
      if (uniqueNewItems.length > 0) {
        setRatedProductsData(prevRatedProducts => {
          const updatedRatedProducts = [...uniqueNewItems, ...prevRatedProducts];
          localStorage.setItem('ratedProductsData', JSON.stringify(updatedRatedProducts));
          return updatedRatedProducts;
        });
      }
    };

    const removeItemsFromRatedProducts = (newWishlist, newFeedback) => {
      const combinedItems = [...newWishlist, ...transformFeedbackData(newFeedback)];
      const combinedItemsSet = new Set(combinedItems.map(item => item.identifier));

      setRatedProductsData(prevRatedProducts => {
        const filteredRatedProducts = prevRatedProducts.filter(item => combinedItemsSet.has(item.identifier));

        if (filteredRatedProducts.length !== prevRatedProducts.length) {
          localStorage.setItem('ratedProductsData', JSON.stringify(filteredRatedProducts));
        }
        return filteredRatedProducts;
      });
    };

    addNewItemToRatedProducts(transformFeedbackData(feedbackData));
    addNewItemToRatedProducts(wishlistProductInstanceData);
    removeItemsFromRatedProducts(wishlistProductInstanceData, feedbackData);
  }, [feedbackData, wishlistProductInstanceData, isWishlistProductListLoading, isFeedbackLoading]);

  const productsByCategoryData = useMemo(() => {
    const categorizedProducts = ratedProductsData.reduce(
      (acc, productInstanceData) => {
        const { productCategory: pc } = parseProductInstance({ productInstanceData });

        if (!acc[pc]) acc[pc] = [];
        acc[pc].push(productInstanceData);
        return acc;
      },
      {} as { [key: string]: TProductInstance[] },
    );

    const limitedCategorizedProducts = Object.keys(categorizedProducts).reduce(
      (acc, category) => {
        const uniqueItems = [];

        categorizedProducts[category].forEach(item => {
          if (
            !uniqueItems.some(existingItem => existingItem.identifier === item.identifier) &&
            uniqueItems.length < 4
          ) {
            uniqueItems.push(item);
          }
        });

        acc[category] = uniqueItems;
        return acc;
      },
      {} as { [key: string]: TProductInstance[] },
    );

    return limitedCategorizedProducts;
  }, [isProductsByCategoryLoading, ratedProductsData, feedbackData, wishlistProductInstanceData]);

  useEffect(() => {
    if (
      (feedbackData.length > 0 || wishlistProductInstanceData.length > 0) &&
      Object.keys(productsByCategoryData).length === 0
    ) {
      setProductsByCategoryLoading(true);
    } else {
      setProductsByCategoryLoading(false);
    }
  }, [feedbackData, wishlistProductInstanceData, productsByCategoryData]);

  const characterAdjectiveItemsData = useMemo(
    () => parseCharacterAdjectiveItemsData(userBestMatchCharacterByProductCategory, locale, productCategories),
    [userBestMatchCharacterByProductCategory],
  );

  const characterAdjectiveModalData = useMemo(
    () => parseCharacterAdjectiveModalData(userBestMatchCharacterByProductCategory, locale, productCategories),
    [userBestMatchCharacterByProductCategory],
  );

  const characterInfoCardsData: Record<string, ICharacterInfoCardProps[]> = useMemo(() => {
    if (!Object.keys(userBestMatchCharacterByProductCategory).length || !locale) return null;

    return productCategories.reduce((acc, category) => {
      acc[category] = parseCharacterInfo({
        characterData: userBestMatchCharacterByProductCategory[category],
        locale,
        productCategory: category,
      });
      return acc;
    }, {});
  }, [userBestMatchCharacterByProductCategory]);

  const characterDetailCardsData: Record<string, IReadMoreCardProps[]> = useMemo(() => {
    if (!Object.keys(userBestMatchCharacterByProductCategory).length || !locale) return null;

    return productCategories.reduce((acc, category) => {
      const character = userBestMatchCharacterByProductCategory[category];
      acc[category] = [
        {
          cardTitle: personality,
          description: character?.character_description,
          productCategory: character?.product_category,
          title: character?.character_caption.replace(/ and /g, ' & '),
        },
        {
          cardTitle: typeOf,
          description: character?.technical_description,
          minimumCharacters: 135,
          productCategory: character?.product_category,
          title: character?.technical_caption,
        },
      ];
      return acc;
    }, {});
  }, [userBestMatchCharacterByProductCategory, locale]);

  const filteredUserDietaryPreferenceTags = useMemo(() => {
    if (userDietaryPreferenceTags.length) {
      return userDietaryPreferenceTags?.filter(
        preference => preference.dietary_preference.slug !== DIETARY_PREFERENCE_SLUG.NO_PREFERENCES,
      );
    }
    return [];
  }, [userDietaryPreferenceTags]);

  const isTasteIdDataLoading =
    !Object.keys(userBestMatchCharacterByProductCategory).length ||
    !characterAdjectiveItemsData ||
    !characterAdjectiveModalData ||
    !characterDetailCardsData ||
    !characterInfoCardsData ||
    isProductsByCategoryLoading ||
    isUserDataLoading ||
    isLocationListLoading;

  return {
    characterAdjectiveItemsData,
    characterAdjectiveModalData,
    characterDetailCardsData,
    characterInfoCardsData,
    filteredUserDietaryPreferenceTags,
    isTasteIdDataLoading,
    locationsByCategoryData,
    productCategories,
    productsByCategoryData,
    ratedProductsData,
    userBestMatchCharacterByProductCategory,
  };
};

export default useTasteIdData;
