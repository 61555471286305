import { FC } from 'react';

import { TProductCategory } from '@lib/core/products/types';
import {
  PRODUCT_CATEGORY_BEER,
  PRODUCT_CATEGORY_COFFEE,
  PRODUCT_CATEGORY_WINE,
} from '@lib/tools/shared/helpers/consts';

import highBeer from '@components/web/src/assets/icons/adjective/adjective_high_beer.gif';
import highCoffee from '@components/web/src/assets/icons/adjective/adjective_high_coffee.gif';
import highWine from '@components/web/src/assets/icons/adjective/adjective_high_wine.gif';
import lowBeer from '@components/web/src/assets/icons/adjective/adjective_low_beer.gif';
import lowCoffee from '@components/web/src/assets/icons/adjective/adjective_low_coffee.gif';
import lowWine from '@components/web/src/assets/icons/adjective/adjective_low_wine.gif';
import mediumBeer from '@components/web/src/assets/icons/adjective/adjective_medium_beer.gif';
import mediumCoffee from '@components/web/src/assets/icons/adjective/adjective_medium_coffee.gif';
import mediumWine from '@components/web/src/assets/icons/adjective/adjective_medium_wine.gif';
import veryHighBeer from '@components/web/src/assets/icons/adjective/adjective_very_high_beer.gif';
import veryHighCoffee from '@components/web/src/assets/icons/adjective/adjective_very_high_coffee.gif';
import veryHighWine from '@components/web/src/assets/icons/adjective/adjective_very_high_wine.gif';
import veryLowBeer from '@components/web/src/assets/icons/adjective/adjective_very_low_beer.gif';
import veryLowCoffee from '@components/web/src/assets/icons/adjective/adjective_very_low_coffee.gif';
import veryLowWine from '@components/web/src/assets/icons/adjective/adjective_very_low_wine.gif';
import zeroBeer from '@components/web/src/assets/icons/adjective/adjective_zero_beer.png';
import zeroCoffee from '@components/web/src/assets/icons/adjective/adjective_zero_coffee.png';
import zeroWine from '@components/web/src/assets/icons/adjective/adjective_zero_wine.png';
import * as S from '@components/web/src/atoms/CharacterAdjective/styles';
import { STATIC_COLORS } from '@components/web/src/foundations';

export interface ICharacterAdjectiveItemData {
  title: string;
  preTitle: string;
  score: number;
  identifier: string;
}

interface ICharacterAdjectiveExtendedProps extends ICharacterAdjectiveItemData {
  productCategory: TProductCategory;
  handleClick: () => void;
}

const CharacterAdjective: FC<ICharacterAdjectiveExtendedProps> = ({
  preTitle,
  title,
  score,
  productCategory,
  handleClick,
}) => {
  const image = {
    [PRODUCT_CATEGORY_BEER]: {
      0: zeroBeer,
      1: veryLowBeer,
      2: lowBeer,
      3: mediumBeer,
      4: highBeer,
      5: veryHighBeer,
    },
    [PRODUCT_CATEGORY_COFFEE]: {
      0: zeroCoffee,
      1: veryLowCoffee,
      2: lowCoffee,
      3: mediumCoffee,
      4: highCoffee,
      5: veryHighCoffee,
    },
    [PRODUCT_CATEGORY_WINE]: {
      0: zeroWine,
      1: veryLowWine,
      2: lowWine,
      3: mediumWine,
      4: highWine,
      5: veryHighWine,
    },
  };

  const currentImage = image[productCategory][score] || '';

  return (
    <S.CharacterAdjectiveContainer onClick={handleClick}>
      <S.Image alt="character adjective icon" src={currentImage} />
      <S.TextWrapper>
        <S.StyledText color={STATIC_COLORS.base.white} size="body2" text={preTitle} weight="medium" />
        <S.AdjectiveText
          $productCategory={productCategory}
          color={STATIC_COLORS.base.white}
          fontFamily="Fraunces"
          size="subtitle1"
          text={title}
          weight="semibold"
        />
      </S.TextWrapper>
    </S.CharacterAdjectiveContainer>
  );
};

export default CharacterAdjective;
