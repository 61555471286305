import { useNavigate } from 'react-router-dom';

import * as S from '@components/web/src/templates/LegalPages/TermsConditionsPage/styles';
import TermsConditionsPage from '@components/web/src/templates/LegalPages/TermsConditionsPage/TermsConditionsPage';

const TermsConditionsContainer = () => {
  const navigate = useNavigate();

  return (
    <S.TermsConditionsContainer>
      <S.StyledBackButton handleClick={() => navigate(-1)} />
      <TermsConditionsPage />;
    </S.TermsConditionsContainer>
  );
};

export default TermsConditionsContainer;
