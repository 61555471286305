import { FC } from 'react';

import { TProductCategory } from '@lib/core/products/types';
import { TDietaryPreference } from '@lib/core/users/types';
import { localeCommon } from '@lib/tools/locale/source/web/common';

import PlusIcon from '@components/web/src/assets/icons/plus_icon.svg';
import Badge from '@components/web/src/atoms/Badge/Badge';
import { STATIC_COLORS } from '@components/web/src/foundations';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';
import EmptyStateCard from '@components/web/src/organisms/EmptyStateCard/EmptyStateCard';
import * as S from '@components/web/src/templates/TasteId/TasteIdPage/BuyerPrinciples/styles';

type IProps = {
  filteredUserDietaryPreferenceTags: TDietaryPreference[];
  productCategory?: TProductCategory;
  navigateToTagsPage?: () => void;
};

const BuyerPrinciples: FC<IProps> = ({ filteredUserDietaryPreferenceTags, productCategory, navigateToTagsPage }) => {
  const { buyerPrinciplesTitle } = localeCommon.tasteId;

  return (
    <S.BuyerPrinciplesContainer>
      <S.StyledText
        color={STATIC_COLORS.base.black}
        fontFamily="Fraunces"
        size="h5"
        text={buyerPrinciplesTitle}
        weight="semibold"
      />
      <Flexbox isFullWidth align="center" gap={8} wrap="wrap">
        {filteredUserDietaryPreferenceTags?.length > 0 ? (
          <>
            {filteredUserDietaryPreferenceTags?.map((pref, index) => (
              <Badge key={index} productCategory={productCategory} text={pref?.dietary_preference?.name} />
            ))}
            <S.StyledBadge
              handleBadgeClick={navigateToTagsPage}
              icon={PlusIcon}
              isProductCategoryType={false}
              productCategory={productCategory}
            />
          </>
        ) : (
          <EmptyStateCard handleBtnClick={navigateToTagsPage} productCategory={productCategory} variant="tags" />
        )}
      </Flexbox>
    </S.BuyerPrinciplesContainer>
  );
};

export default BuyerPrinciples;
