import { useNavigate } from 'react-router-dom';

import CookiePolicyPage from '@components/web/src/templates/LegalPages/CookiePolicyPage/CookiePolicyPage';
import * as S from '@components/web/src/templates/LegalPages/CookiePolicyPage/styles';

const CookiePolicyContainer = () => {
  const navigate = useNavigate();

  return (
    <S.CookiePolicyContainer>
      <S.StyledBackButton handleClick={() => navigate(-1)} />
      <CookiePolicyPage />;
    </S.CookiePolicyContainer>
  );
};

export default CookiePolicyContainer;
