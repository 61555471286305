import React, { FC } from 'react';

import { localeWidget } from '@lib/tools/locale/source/web/widget';

import arrowIcon from '@components/web/src/assets/icons/arrow-icon.svg';
import { STATIC_COLORS } from '@components/web/src/foundations';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';
import { Text } from '@components/web/src/foundations/Text/Text';

interface IProps {
  contentRef: React.MutableRefObject<HTMLDivElement>;
  characterName: string;
}

export const TastePathThirdPartyContent: FC<IProps> = ({ contentRef, characterName }) => {
  const {
    tastePathTitle,
    tastePathSubtitle,
    discoverSubtitle,
    discoverTitle,
    tasteSubtitle,
    tasteTitle,
    rateSubtitle,
    rateTitle,
  } = localeWidget.thirdPartyConsentPage;

  const tastePathGuide = [
    { icon: true, subtitle: discoverSubtitle, title: discoverTitle },
    { icon: true, subtitle: tasteSubtitle, title: tasteTitle },
    { icon: false, subtitle: rateSubtitle, title: rateTitle },
  ];

  return (
    <Flexbox ref={contentRef} direction="column" gap={8} padding="32px 16px">
      <Text color={STATIC_COLORS.base.white} size="body1" text={tastePathTitle} weight="medium" />
      <Text
        color={STATIC_COLORS.base.white}
        fontFamily="Fraunces"
        size="h4"
        text={tastePathSubtitle}
        weight="semibold"
      />
      <Flexbox direction="column" gap={16}>
        {tastePathGuide.map(({ title, subtitle, icon }, idx) => (
          <Flexbox key={idx} align="center" direction="column" gap={16}>
            <Flexbox direction="column" gap={8}>
              <Text color={STATIC_COLORS.base.white} size="subtitle2" text={title} weight="bold" />
              <Text color={STATIC_COLORS.base.white} localeOptions={{ characterName }} size="body1" text={subtitle} />
            </Flexbox>
            {icon && <img alt="arrow" className="arrow" src={arrowIcon} />}
          </Flexbox>
        ))}
      </Flexbox>
    </Flexbox>
  );
};
