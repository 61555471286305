import { GoogleOAuthProvider } from '@react-oauth/google';
import { Suspense, useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider, useDispatch } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';

// eslint-disable-next-line import/order
import { store } from '@lib/core/service/store';
import { ENV_GOOGLE_APP_ID, QA_URL_PARAM_CURRENT_DATE } from '@lib/core/service/consts';
import ServiceLauncher from '@lib/core/service/providers/ServiceLauncher';
import { updateCurrentDateParams } from '@lib/core/service/slices/qaParameterSlice';
import { Navigator } from '@lib/core/service/utils/Navigator';
import UserProvider from '@lib/core/users/providers/UserProvider';
import CommsProvider from '@lib/tools/comms/providers';
import DatProvider from '@lib/tools/dat/views/DatProvider';
import { DevTools } from '@lib/tools/devtools';
import LocaleProvider from '@lib/tools/locale/providers/LocaleProvider';
import { DataProvider } from '@lib/tools/shared/views/providers/DataProvider';
import HocWrapper from '@lib/tools/shared/views/providers/HocWrapper';
import { ThemeProvider } from '@lib/tools/shared/views/providers/ThemeProvider';
import { useRetailerDesignSet } from '@lib/tools/views/hooks';

import LoadingSpinner from '@components/web/src/templates/Loading/LoadingSpinner/LoadingSpinner';

import WidgetHocStart from 'providers/HocStart';
import AsyncRouterApp from 'routes/RouterApp';
import AsyncRouterWeb from 'routes/RouterWeb';

// Styles
import 'rc-slider/assets/index.css';
import 'react-toastify/dist/ReactToastify.css';
import '@components/web/src/styles/_index.scss';

const persistor = persistStore(store);

const View = () => {
  const { location } = window;

  const dispatch = useDispatch();

  const { isDesignSetVinhoodExperience, isDesignSetVinhoodApp } = useRetailerDesignSet();

  let renderComponent = <LoadingSpinner />;

  useEffect(() => {
    // INFO: This part is done for QA accessibility so that we can change the params part
    // to check different unique items per day passing the date as current date on params
    if (location.search) {
      const searchParams = new URLSearchParams(location.search);
      const qaCurrentDate = searchParams.get(QA_URL_PARAM_CURRENT_DATE);

      if (qaCurrentDate) {
        dispatch(updateCurrentDateParams(qaCurrentDate));
      }
    }

    return () => {
      new AbortController().abort();
    };
  }, []);

  if (isDesignSetVinhoodApp) {
    renderComponent = <AsyncRouterApp />;
  } else if (isDesignSetVinhoodExperience) {
    renderComponent = (
      <WidgetHocStart>
        <CommsProvider>
          <AsyncRouterWeb />
        </CommsProvider>
      </WidgetHocStart>
    );
  }

  return (
    <LocaleProvider>
      <BrowserRouter>
        <Navigator />
        <DevTools />

        <Suspense fallback={<LoadingSpinner />}>
          <GoogleOAuthProvider clientId={ENV_GOOGLE_APP_ID}>
            <DatProvider>
              <UserProvider>
                <DataProvider>
                  <ThemeProvider>
                    <HocWrapper>{renderComponent}</HocWrapper>
                  </ThemeProvider>
                </DataProvider>
              </UserProvider>
            </DatProvider>
          </GoogleOAuthProvider>
        </Suspense>
      </BrowserRouter>
    </LocaleProvider>
  );
};

const App = ServiceLauncher(View);

createRoot(document.getElementById('root')!).render(
  <PersistGate loading={null} persistor={persistor}>
    <Provider store={store}>
      <App key="web" props={undefined} type={undefined} />
    </Provider>
  </PersistGate>,
);
