import { useDispatch, useSelector } from 'react-redux';

import { PRODUCT_REQUEST_FOR_HOME_PAGE } from '@lib/core/service/consts';
import {
  selectIsProductsRequestLoadingBeer,
  selectIsProductsRequestLoadingCoffee,
  selectIsProductsRequestLoadingWine,
  selectSwiperProductsData,
  selectSwiperProductsDataBeer,
  selectSwiperProductsDataCoffee,
  selectSwiperProductsDataWine,
} from '@lib/tools/productsSwiper/selectors/index';
import { fetchSwiperProducts } from '@lib/tools/productsSwiper/slices/index';
import { IFetchProductsOptions } from '@lib/tools/productsSwiper/types/interface';

export const useProductsSwiper = () => {
  const dispatch = useDispatch();
  const handleFetchSwiperProducts = (requestParams: IFetchProductsOptions) => {
    dispatch(fetchSwiperProducts({ params: requestParams, productResultFor: PRODUCT_REQUEST_FOR_HOME_PAGE }));
  };
  return {
    beerProducts: useSelector(selectSwiperProductsDataBeer),
    coffeeProducts: useSelector(selectSwiperProductsDataCoffee),
    handleFetchSwiperProducts,
    isBeerProductsLoading: useSelector(selectIsProductsRequestLoadingBeer),
    isCoffeeProductsLoading: useSelector(selectIsProductsRequestLoadingCoffee),
    isWineProductsLoading: useSelector(selectIsProductsRequestLoadingWine),
    productsData: useSelector(selectSwiperProductsData),
    wineProducts: useSelector(selectSwiperProductsDataWine),
  };
};
