import styled, { keyframes } from 'styled-components';

import CloseButton from '@components/web/src/atoms/Buttons/CloseButton/CloseButton';
import { STATIC_GRADIENTS } from '@components/web/src/foundations';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';
import { Text } from '@components/web/src/foundations/Text/Text';

export const RegisterDetailsBannerContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  height: fit-content;
  padding: 32px 16px;
  text-align: center;
  background: ${STATIC_GRADIENTS.primaryGradient};
`;

export const CloseIcon = styled(CloseButton)`
  margin: 0 0 -20px auto;
`;

export const StyledText = styled(Text)`
  & > br {
    display: none;
  }
`;
export const AuthOptionsWrapper = styled.div`
  margin: 32px 16px;
`;

export const AnimationContainer = styled(Flexbox)`
  width: 207px;
  height: 61px;
`;
const feedbackAnimation = keyframes`
  0%, 25%, 100% {
    transform: rotate(0deg) translateX(0);
  }
  50%, 75% {
    transform: rotate(25.6deg) translateX(7px);
  }
`;

const commentAnimation = keyframes`
  0%,25%,100% {
    transform: rotate(0deg);
  }

  50%,75% {
    transform: rotate(-30.6deg);
  }
`;

const wishlistIconAnimation = keyframes`
  0%,25%,100% {
    transform: rotate(0deg);
  }
  50%,75% {
    transform: rotate(-7.86deg);
  }
`;

export const FeedbackIconWrapper = styled.img`
  width: 88.629px;
  margin-top: -6px;
  margin-right: -29px;
  animation: ${feedbackAnimation} 1.4s ease-in-out infinite;
`;

export const WishlistIconWrapper = styled.img`
  width: 58.5px;
  animation: ${wishlistIconAnimation} 1.4s ease-in-out infinite;
`;

export const CommentIconWrapper = styled.img`
  width: 65px;
  margin-top: 3px;
  margin-left: -23px;
  animation: ${commentAnimation} 1.4s ease-in-out infinite;
`;
