import { useNavigate } from 'react-router-dom';

import { prependBasename } from '@lib/core/service/utils';

import TasteIdLegalPage from '@components/web/src/templates/TasteId/TasteIdLegalPage/TasteIdLegalPage';

const TasteIdLegalPageContainer = () => {
  const navigate = useNavigate();

  const handleBackBtnClick = () => navigate(-1);

  const handleLegalCardBtnClick = (page: string) => navigate(prependBasename(page));

  return <TasteIdLegalPage handleBackBtnClick={handleBackBtnClick} handleLegalCardBtnClick={handleLegalCardBtnClick} />;
};

export default TasteIdLegalPageContainer;
