import { styled } from 'styled-components';

import { SCREEN_SIZE, STATIC_COLORS, STATIC_GRADIENTS, STATIC_SHADOWS } from '@components/web/src/foundations';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';
import { Text } from '@components/web/src/foundations/Text/Text';

export const CharactersMapScrollerContainer = styled(Flexbox)`
  background: ${STATIC_GRADIENTS.warmGradientReversed()};
  margin-top: 32px;
`;

export const CharactersInfoHeader = styled(Flexbox)`
  padding: 16px 16px 16px;
`;

export const CharacterCountText = styled(Text)``;

export const CharactersMapScrollWrapper = styled(Flexbox)`
  padding: 16px 0 16px 16px;
`;

export const ScrollBody = styled(Flexbox)`
  min-height: 450px;
  padding: 15px 40px;
  overflow-x: scroll;
  background: ${STATIC_GRADIENTS.warmGradientReversed()};
  border-radius: 8px;
  box-shadow: ${STATIC_SHADOWS.lg};
  scroll-behavior: smooth;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const ScrolledCharacterMap = styled(Flexbox)`
  min-height: 402px;
  flex-flow: row nowrap;
`;

export const ScrollButtonsContainer = styled(Flexbox)`
  display: none;
  padding: 16px;

  @media screen and (min-width: ${SCREEN_SIZE.vhApp}) {
    display: flex;
    width: 100%;
  }
`;

export const ScrollButtonIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 44px;
  max-width: 44px;
  min-height: 44px;
  max-height: 44px;
  cursor: pointer;
  background: ${STATIC_COLORS.base.white};
  border-radius: 33px;
  box-shadow: ${STATIC_SHADOWS.lg};
`;
