import { QrScanner } from '@yudiel/react-qr-scanner';
import { FC, useEffect, useState } from 'react';

import { localeApp } from '@lib/tools/locale/source/web/app';
import LocaleFragment from '@lib/tools/locale/views/LocaleFragment';

import { ReactComponent as ValidatorIcon } from '@components/web/src/assets/icons/validator.svg';
import CloseButton from '@components/web/src/atoms/Buttons/CloseButton/CloseButton';
import ModalWrapper from '@components/web/src/templates/Modals/ModalWrapper/ModalWrapper';

interface IProps {
  isModalOpen: boolean;
  hideModal: () => void;
}

const QrScannerModal: FC<IProps> = ({ hideModal, isModalOpen }) => {
  const { notValidCodeErrorText, permissionDeniedErrorText } = localeApp.qrScannerModal;

  const [successResult, setSuccessResult] = useState('');
  const [notValidResult, setNotValidResult] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    if (successResult) {
      window.location.href = successResult;
      hideModal();
    }
  }, [successResult]);

  useEffect(() => {
    if (notValidResult) setTimeout(() => setNotValidResult(null), 2000);
  }, [notValidResult]);

  return (
    <ModalWrapper hideModal={hideModal} isOpen={isModalOpen} modalClassName="qr-scanner-modal-container">
      <div className="qr-scanner-modal-wrapper">
        <CloseButton handleClick={hideModal} />
        <div className="scanner-wrapper">
          <QrScanner
            audio={false}
            onDecode={result => {
              if (result.includes('vinhood')) {
                setSuccessResult(result);
              } else {
                setNotValidResult(notValidCodeErrorText);
              }
            }}
            onError={error => {
              if (error?.name === 'NotAllowedError') {
                setErrorMessage(permissionDeniedErrorText);
              } else {
                setErrorMessage(error?.name);
              }
            }}
          />
          <ValidatorIcon
            className={`custom-validator ${(errorMessage || notValidResult) && 'error'} ${successResult && 'success'}`}
          />
        </div>
        {(errorMessage || notValidResult) && (
          <p className="error-message">
            <LocaleFragment message={errorMessage || notValidResult} />
          </p>
        )}
      </div>
    </ModalWrapper>
  );
};

export default QrScannerModal;
