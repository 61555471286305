import { Dispatch, FC, PropsWithChildren, SetStateAction, SyntheticEvent } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { IComment, ICommentHandleSubmit } from '@lib/core/comments/types';
import { TProductInstance } from '@lib/core/products/types';
import { parseFindProduct, parseProductInstance } from '@lib/core/products/utils';
import { TRetailerLocationStoreType } from '@lib/core/retailers/types';
import { RetailerLocationStoreType } from '@lib/core/retailers/utils/consts';
import { prependBasename, promotionsFilter } from '@lib/core/service/utils';
import { TProductFeedbackValue } from '@lib/core/users/slices/productFeedback';
import MixpanelTracker from '@lib/tools/dat/mixpanel';
import { MixpanelPositionContext } from '@lib/tools/dat/mixpanel/consts';
import { localeCommon } from '@lib/tools/locale/source/web/common';
import {
  PRODUCT_CARD_VARIANTS,
  PRODUCT_ID_URL_PARAM,
  PROMOTION_BADGE_FIDELITY,
  PROMOTION_BADGE_OTHER,
  PROMOTION_BADGE_PROMOTION,
  PROMOTION_LABEL_SLUG,
  VH_VARIANTS,
} from '@lib/tools/shared/helpers/consts';
import { useAddons } from '@lib/tools/views/hooks';
import { PAGES } from '@lib/tools/views/urls';

import SkeletonWrapper from '@components/web/src/app/Skeleton/SkeletonWrapper';
import Button from '@components/web/src/atoms/Buttons/Button';
import Comment from '@components/web/src/atoms/Comment/Comment';
import Feedback from '@components/web/src/atoms/Feedback/Feedback/Feedback';
import PriceRange from '@components/web/src/atoms/PriceRange/PriceRange';
import ProductCardTopBanner from '@components/web/src/atoms/ProductCardElements/ProductCardTopBanner/ProductCardTopBanner';
import Wishlist from '@components/web/src/atoms/Wishlist/Wishlist';
import ProductCardSkeleton from '@components/web/src/components/Skeleton/ProductCardSkeleton/ProductCardSkeleton';
import { STATIC_COLORS } from '@components/web/src/foundations';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';
import { Image } from '@components/web/src/foundations/Image/Image';
import { Text } from '@components/web/src/foundations/Text/Text';
import CardFooter from '@components/web/src/organisms/CardFooter/CardFooter';
import * as S from '@components/web/src/organisms/Cards/ProductCard/styles';
import { PromotionBadges } from '@components/web/src/organisms/PromotionBadges/PromotionBadges';
import FindProductPopup from '@components/web/src/shared/FindProductPopup/FindProductPopup';
import { IDiscoveryQuiz } from '@components/web/src/templates/Modals/DiscoveryQuizModal/DiscoveryQuizModal';

export type TProductCardVariants =
  | typeof PRODUCT_CARD_VARIANTS.DEFAULT
  | typeof PRODUCT_CARD_VARIANTS.TOP_BANNER
  | typeof PRODUCT_CARD_VARIANTS.BADGES
  | typeof PRODUCT_CARD_VARIANTS.COMPACT;

interface IBaseProductCardProps {
  isLoading: boolean;
  storeType: TRetailerLocationStoreType;
  locale: string;
  variant?: TProductCardVariants;
  isResponsive?: boolean;
}

interface IBaseProductCardPropsSkeleton extends IBaseProductCardProps {
  mixpanelIndex?: undefined;
  mixpanelPositionContext?: undefined;
  productInstanceData?: undefined;
  intl?: undefined;
  className?: string;
  isEnableLocationMapAddon?: boolean;
  isEnableVusionAddon?: boolean;
  isShowProductLocationAddon?: boolean;
  isProductInstanceInWishlist?: boolean;
  isWishlistProductListLoading?: boolean;
  shouldHideComment?: boolean;
  shouldHideFeedback?: boolean;
  shouldHideWishlist?: boolean;
  feedback?: TProductFeedbackValue;
  discoveryQuiz?: IDiscoveryQuiz;
  isProductCardClickDisabled?: boolean;
  shouldShowFeedbackTooltip?: boolean;
  handleUpdateWishlistProductList?: (productId: string, productName?: string) => void;
  handleUpdateFeedback?: (feedback: TProductFeedbackValue, productId: string) => void;
  isCommentsLoaded?: boolean;
  isNewCommentsDataFetching?: boolean;
  commentsList?: IComment[];
  setIsNewCommentsDataFetching?: Dispatch<SetStateAction<boolean>>;
  handleSubmitComment?: ({ isCommentInList, commentFromList, productId, comment }: ICommentHandleSubmit) => void;
}

interface IBaseProductCardPropsFull extends IBaseProductCardProps {
  /** Numerical index of the product card in the list */
  mixpanelIndex?: number | null;
  /** Descriptive name for the parent component wrapping the product card (`Swiper`, `Scrollable Catalog`) */
  mixpanelPositionContext: MixpanelPositionContext;
  productInstanceData: TProductInstance;
  className?: string;
  isEnableLocationMapAddon?: boolean;
  isShowProductLocationAddon?: boolean;
  isEnableVusionAddon?: boolean;
  isProductInstanceInWishlist?: boolean;
  isWishlistProductListLoading?: boolean;
  shouldHideComment?: boolean;
  shouldHideFeedback?: boolean;
  shouldHideWishlist?: boolean;
  discoveryQuiz?: IDiscoveryQuiz;
  isProductCardClickDisabled?: boolean;
  shouldShowFeedbackTooltip?: boolean;
  isCommentsLoaded?: boolean;
  commentsList?: IComment[];
  isSaveCommentCtaDisabled?: boolean;
  isNewCommentsDataFetching?: boolean;
  feedback?: TProductFeedbackValue;
  setIsNewCommentsDataFetching?: Dispatch<SetStateAction<boolean>>;
  handleSubmitComment?: ({ isCommentInList, commentFromList, productId, comment }: ICommentHandleSubmit) => void;
  handleUpdateWishlistProductList?: (productId: string) => void;
  handleUpdateFeedback?: (feedback: TProductFeedbackValue, productId: string) => void;
}

export type IProductCardProps = IBaseProductCardPropsSkeleton | IBaseProductCardPropsFull;

const ProductCard: FC<IProductCardProps> = ({
  mixpanelIndex = null,
  mixpanelPositionContext,
  productInstanceData,
  className,
  locale,
  storeType,
  variant = 'default',
  shouldHideWishlist = false,
  shouldHideFeedback = false,
  shouldHideComment = false,
  isResponsive = false,
  isLoading,
  isEnableLocationMapAddon,
  isShowProductLocationAddon,
  isEnableVusionAddon,
  isProductInstanceInWishlist = false,
  isWishlistProductListLoading = false,
  isProductCardClickDisabled = false,
  feedback,
  discoveryQuiz,
  shouldShowFeedbackTooltip = false,
  handleUpdateWishlistProductList,
  handleUpdateFeedback,
  isCommentsLoaded,
  handleSubmitComment,
  setIsNewCommentsDataFetching,
  isNewCommentsDataFetching,
  commentsList,
}) => {
  const navigate = useNavigate();

  const { pathname, search } = useLocation();

  const { productCard, commonMessages } = localeCommon;

  const {
    isFindProductButtonEnable,
    productLocationDescription,
    productLocationMap,
    productEcommerceId,
    isFindProductFunctionalityEnable,
  } = parseFindProduct({
    isEnableLocationMapAddon,
    isEnableVusionAddon,
    isShowProductLocationAddon,
    productInstanceData,
  });

  const {
    productInstanceId,
    productId,
    productCategory,
    productCharacterName,
    productCharacterTechnicalCaption,
    productName,
    productImage,
    productDescription,
    productTags,
    productBadges,
    productFormat,
    productFormatSize,
    productFormatName,
    productProducerName,
    productRegionName,
    promotions,
    productRegionCountry,
    productProducerUrl,
    productDiscountPrice,
    showedProductOriginalPrice,
    showedProductDiscountPrice,
    productCurrencySymbol,
    productOriginalPrice,
  } = parseProductInstance({
    locale,
    productInstanceData,
  });

  const isShowPromoLabel = !!promotionsFilter(promotions, [PROMOTION_LABEL_SLUG]).length;

  const promotionBadges = promotionsFilter(promotions, [
    PROMOTION_BADGE_FIDELITY,
    PROMOTION_BADGE_PROMOTION,
    PROMOTION_BADGE_OTHER,
  ]);

  const { isPriceRangeAddon } = useAddons();

  const isTopBannerVariant = variant === PRODUCT_CARD_VARIANTS.TOP_BANNER;
  const isBadgesVariant = variant === PRODUCT_CARD_VARIANTS.BADGES;
  const isCompactVariant = variant === PRODUCT_CARD_VARIANTS.COMPACT;
  const isProductRegionAvailable = !!productRegionCountry && !!productRegionName;

  const handleAreaClick = (e: any) => e.stopPropagation();

  const redirectToProductPage = (e: SyntheticEvent) => {
    e.stopPropagation();

    const isExternalTarget = storeType === RetailerLocationStoreType.ecommerce;

    if (storeType === RetailerLocationStoreType.main) {
      navigate(prependBasename(PAGES.vinhood.product, { [PRODUCT_ID_URL_PARAM]: productInstanceId }), {
        state: { from: pathname + search },
      });

      MixpanelTracker.events.productClick(
        productInstanceData,
        isProductInstanceInWishlist,
        mixpanelIndex,
        mixpanelPositionContext,
      );
    } else if (isExternalTarget && productProducerUrl) {
      window.open(productProducerUrl, '_blank');
      MixpanelTracker.events.contactTheProductProducer(
        productInstanceData,
        isProductInstanceInWishlist,
        mixpanelIndex,
        mixpanelPositionContext,
      );
    }
  };

  return (
    <SkeletonWrapper showSkeleton={isLoading} skeleton={() => ProductCardSkeleton({ isResponsive })}>
      <S.ProductCardContainer
        $isResponsive={isResponsive}
        className={className}
        onClick={e => !isProductCardClickDisabled && redirectToProductPage(e)}
      >
        {isTopBannerVariant && (
          <ProductCardTopBanner
            characterName={productCharacterName}
            productCategory={productCategory}
            technicalCaption={productCharacterTechnicalCaption}
          />
        )}
        <S.ProductImageSection>
          {isShowPromoLabel && (
            <S.PromoIcon className="promo-icon">
              <Text color={STATIC_COLORS.base.white} size="body3" text={commonMessages.promo} weight="semibold" />
            </S.PromoIcon>
          )}
          <S.ProductImage alt={productId} height="85%" objectFit="contain" src={productImage} />
          <S.ProductTags>
            {productTags?.map((tag, idx) => (
              <Image key={idx} alt={`${productId}-${tag.tagName}`} height="32px" src={tag.icon} width="32px" />
            ))}
          </S.ProductTags>
          <S.ProductImageCtaContainer
            align="center"
            direction="column"
            gap={16}
            justify="space-between"
            onClick={handleAreaClick}
          >
            <Flexbox align="center" direction="column" gap={16} justify="flex-start">
              {!shouldHideWishlist && (
                <Wishlist
                  isItemInWishlist={isProductInstanceInWishlist}
                  isItemListLoading={isWishlistProductListLoading}
                  handleUpdateWishlist={() => {
                    if (handleUpdateWishlistProductList) handleUpdateWishlistProductList(productId, productName);
                    MixpanelTracker.events.productBookmark(
                      productInstanceData,
                      isProductInstanceInWishlist,
                      mixpanelIndex,
                      mixpanelPositionContext,
                    );
                  }}
                />
              )}
              {!shouldHideFeedback && (
                <Feedback
                  discoveryQuiz={discoveryQuiz}
                  feedback={feedback}
                  handleUpdateFeedback={handleUpdateFeedback}
                  productInstanceData={productInstanceData}
                  shouldShowFeedbackTooltip={shouldShowFeedbackTooltip}
                />
              )}
              {!shouldHideComment && (
                <Comment
                  commentsList={commentsList}
                  handleSubmitComment={handleSubmitComment}
                  isCommentsLoaded={isCommentsLoaded}
                  isNewCommentsDataFetching={isNewCommentsDataFetching}
                  productId={productId}
                  setIsNewCommentsDataFetching={setIsNewCommentsDataFetching}
                />
              )}
            </Flexbox>
            {/* TODO currently only made available on product details, we add the logic here  later */}
            {/* <ReactPdf product={productInstanceData} /> */}
          </S.ProductImageCtaContainer>
        </S.ProductImageSection>
        <S.ProductContentSection $isCompactVariant={isCompactVariant}>
          <S.ProducerName
            shouldRenderEmptySelector
            color={STATIC_COLORS.base.black}
            size="body1"
            text={productProducerName}
            weight="semibold"
          />
          <S.StyledProductName
            shouldRenderEmptySelector
            color={STATIC_COLORS.base.black}
            fontFamily="Fraunces"
            linesLimit={2}
            size="h5"
            text={productName}
            weight="semibold"
          />
          <S.ProductRegionText
            shouldRenderEmptySelector
            color={STATIC_COLORS.base.black}
            size="body1"
            text={(isProductRegionAvailable && `${productRegionName}, ${productRegionCountry}`) || ''}
          />
          <S.ProductCharacterDetailsText
            color={STATIC_COLORS.green['600']}
            linesLimit={1}
            size="body2"
            text={`${productCharacterName && `#${productCharacterName} • `} ${productCharacterTechnicalCaption}`}
            weight="medium"
          />
          {!isCompactVariant && (
            <>
              <S.StyledProductDescription color={STATIC_COLORS.base.black} size="body2" text={productDescription} />

              <S.ProductPriceFormatContainer>
                {productFormat && (
                  <S.StyledProductFormatText
                    size="body2"
                    text={`${productFormatName} ${productFormatSize && `, ${productFormatSize}`}`}
                  />
                )}
                {isPriceRangeAddon ? (
                  <PriceRange
                    currencySymbolValue={productCurrencySymbol}
                    originalPrice={productOriginalPrice}
                    productCategory={productCategory}
                  />
                ) : (
                  <S.ProductPriceContainer>
                    {productDiscountPrice ? (
                      <>
                        <S.StyledProductDashedPriceText size="subtitle1" text={showedProductOriginalPrice} />
                        <S.StyledProductPriceText
                          size="subtitle1"
                          text={showedProductDiscountPrice}
                          weight="semibold"
                        />
                      </>
                    ) : (
                      <S.StyledProductPriceText size="subtitle1" text={showedProductOriginalPrice} weight="semibold" />
                    )}
                  </S.ProductPriceContainer>
                )}
              </S.ProductPriceFormatContainer>
              {!!promotionBadges?.length && <PromotionBadges promotionsData={promotionBadges} />}
            </>
          )}
        </S.ProductContentSection>
        {!isCompactVariant && (
          <S.ProductCTASection>
            {(storeType === RetailerLocationStoreType.main ||
              (storeType === RetailerLocationStoreType.ecommerce && productProducerUrl)) && (
              <Button
                className="give-it-button"
                size="sm"
                text={productCard.ctaGiveItBtn}
                textWeight="semibold"
                variant={VH_VARIANTS.LINK}
                onClick={!isCompactVariant && redirectToProductPage}
              />
            )}
            {isFindProductFunctionalityEnable && (
              <div aria-hidden className="find-product" onClick={handleAreaClick}>
                {isFindProductButtonEnable && (
                  <FindProductPopup
                    isEnableLocationMapAddon={isEnableLocationMapAddon}
                    isEnableVusionAddon={isEnableVusionAddon}
                    productEcommerceId={productEcommerceId}
                    productLocationDescription={productLocationDescription}
                    productLocationMap={productLocationMap}
                  >
                    <Button
                      className="find-product-button"
                      iconVariant="location"
                      size="sm"
                      text={productCard.findMeBtn}
                      variant={VH_VARIANTS.PRIMARY}
                      onClick={() =>
                        MixpanelTracker.events.findMe(
                          productInstanceData,
                          isProductInstanceInWishlist,
                          mixpanelIndex,
                          mixpanelPositionContext,
                        )
                      }
                    />
                  </FindProductPopup>
                )}
              </div>
            )}
            {isBadgesVariant && (
              <S.StyledBadgesWrapper>
                <CardFooter badges={productBadges} />
              </S.StyledBadgesWrapper>
            )}
          </S.ProductCTASection>
        )}
      </S.ProductCardContainer>
    </SkeletonWrapper>
  );
};

// Taken from https://github.com/microsoft/TypeScript/issues/31501#issuecomment-1280579305
// This is necessary to make a Union of Omit types. Omit by design merges
// Union types, therefore the ability to use "Discriminated Unions" is lost. This
// Method preserves Discriminated Unions and allows to enforce correct behavior.
// If you don't understand how this Type works, ask ChatGPT4.
type OmitUnion<T, K extends keyof any> = T extends any ? Omit<T, K> : never;

export default ProductCard as FC<OmitUnion<PropsWithChildren<IProductCardProps>, 'intl'>>;
