import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useQuiz, useQuizView, useQuizzes, useUserQuiz, useUserQuizType } from '@lib/core/quizzes/hooks';
import { useSituationalQuiz } from '@lib/core/quizzes/hooks/useSituationalQuiz';
import QuizUtils from '@lib/core/quizzes/utils';
import { QUIZ_TYPE_RECIPE, QUIZ_TYPE_TASTE } from '@lib/core/quizzes/utils/consts';
import {
  isAnswerTagFilterCharacterContext,
  isAnswerTagProductCategoryContext,
  isAnswerTagProductPreferencesContext,
  isAnswerTagRedirectRouteContext,
} from '@lib/core/quizzes/utils/filters';
import QuizLauncher from '@lib/core/quizzes/views/QuizLauncher';
import { useRetailer } from '@lib/core/retailers/hooks/retailer';
import {
  DISABLE_USER_CHARACTER_TOGGLE_URL_PARAM,
  IS_FROM_SITUATIONAL_TEST_URL_PARAM,
  PRODUCT_PREFERENCES_URL_PARAM,
  isApplicationKiosk,
} from '@lib/core/service/consts';
import { useApp } from '@lib/core/service/hooks';
import { selectServiceProductCategory } from '@lib/core/service/selectors';
import { prependBasename } from '@lib/core/service/utils';
import { useUser } from '@lib/core/users/hooks';
import RouteUtils from '@lib/tools/routes';
import {
  ALL_PRODUCTS_TAB,
  CHARACTERS_URL_PARAM,
  PREFERENCES_URL_PARAM,
  PRODUCT_CATEGORIES_ORDERED,
  PRODUCT_CATEGORY_URL_PARAM,
  REDIRECT_TO_URL_PARAM,
  SITUATIONAL_PRESELECTED_URL_PARAM,
  START_FROM_FULL_CATALOG,
  TAB_URL_PARAM,
} from '@lib/tools/shared/helpers/consts';
import { useAddons, useEffectSkipFirst, useRetailerDesignSet } from '@lib/tools/views/hooks';
import { useCustomStyles } from '@lib/tools/views/hooks/useCustomStyles';
import { PAGES } from '@lib/tools/views/urls';

import LoadingSpinner from '@components/web/src/templates/Loading/LoadingSpinner/LoadingSpinner';
import QuizPage from '@components/web/src/templates/TestFlowsPages/QuizPage/QuizPage';

const QuizContainer: any = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { isDevToolsEnabled } = useApp();
  const { customQuizBackgroundColor, customTextColor, customSecondaryColor } = useCustomStyles();
  const { isAnonymousRetailerAccess } = useRetailer();

  const { isDesignSetVinhoodExperience, isDesignSetVinhoodApp } = useRetailerDesignSet();
  const { isQuizFetching, quizPath, quizFirstQuestionId } = useQuiz();
  const {
    userQuizId,
    isUserQuizComplete,
    isUserQuizLoading,
    userQuizCharacters,
    userQuizRetailerCharacterRedirectUrls,
    userQuizType,
  } = useUserQuiz();

  const { isQuizzesFetching, quizzes } = useQuizzes();
  const { userBestMatchCharacterByProductCategory, isUserAuthenticated } = useUser();
  const { isExternalCharacterPage } = useAddons();
  const {
    quizViewData: quizViewQuestion,
    quizViewAnswerTags,
    handleQuizViewGoBack,
    handleQuizViewSubmitAnswer,
    quizViewQuestionId,
    isQuizViewCompleted,
  } = useQuizView();

  const {
    isUserQuizTypeSituational,
    isUserQuizTypeExpert,
    isUserQuizTypeRecipe,
    isUserQuizTypeTaste,
    isUserQuizTypeMultiProduct,
  } = useUserQuizType();

  const { isSituationalQuizDropQuestion, situationalQuizSourcePage } = useSituationalQuiz();

  const preselectedAnswers = QuizUtils.getPreselectedAnswerIds();

  const [initialPreselectedAnswers, setInitialPreselectedAnswers] = useState([]);
  const [quizViewProgress, setQuizViewProgress] = useState(0);
  const productCategory = useSelector(selectServiceProductCategory);
  const isUrlParamsIncludesQuizTypeTaste = window.location.href.includes(QUIZ_TYPE_TASTE);
  const isUrlParamsIncludesQuizTypeRecipe = window.location.href.includes(QUIZ_TYPE_RECIPE);
  const isLoading =
    isQuizzesFetching ||
    isQuizFetching ||
    isQuizViewCompleted ||
    !quizViewQuestion ||
    !userQuizId ||
    preselectedAnswers.length;

  useEffect(() => {
    if (
      isUrlParamsIncludesQuizTypeTaste &&
      !isQuizzesFetching &&
      !QuizUtils.isTasteQuizSpecificPreferencesAvailable(searchParams.get(PREFERENCES_URL_PARAM))
    ) {
      navigate(
        prependBasename(PAGES.vinhood.quiz.chooseTaste, {
          [PRODUCT_CATEGORY_URL_PARAM]: productCategory,
        }),
      );
    }

    if (isUrlParamsIncludesQuizTypeRecipe && !isLoading && !QuizUtils.getQuiz(QUIZ_TYPE_RECIPE)) {
      navigate(
        prependBasename(PAGES.vinhood.recipeSearch, {
          [PRODUCT_CATEGORY_URL_PARAM]: productCategory,
        }),
      );
    }
  }, [isQuizzesFetching]);

  useEffect(() => {
    if (preselectedAnswers?.length && !initialPreselectedAnswers.length) {
      setInitialPreselectedAnswers(preselectedAnswers || []);
    }
  }, [preselectedAnswers]);

  /**
   * * Progress bar
   */
  useEffect(() => {
    setQuizViewProgress(QuizUtils.getProgressPercentage());
  }, [quizViewQuestionId]);

  const isFirstQuestion = quizFirstQuestionId === quizViewQuestionId;

  const userQuizCharacter = userQuizCharacters[0];

  const onAnswerSelectHandler = (answerId: string) => {
    handleQuizViewSubmitAnswer([answerId]);
  };

  /**
   * * Handle preselected answers from url params
   */
  useEffectSkipFirst(() => {
    if (userQuizId && preselectedAnswers.length && quizViewQuestionId && userQuizType) {
      const answerIds = quizViewQuestion.answers.map(answer => Object.keys(answer)[0]);
      preselectedAnswers.forEach(preselectedAnswer => {
        const isPreselectedAnswerIdValid = !!quizPath[quizViewQuestionId]?.answers[preselectedAnswer];
        if (answerIds.includes(preselectedAnswer) && isPreselectedAnswerIdValid) {
          onAnswerSelectHandler(preselectedAnswer);
          const newPreselectedString = preselectedAnswers.filter(answerId => answerId !== preselectedAnswer).join(',');

          if (newPreselectedString) {
            searchParams.set(
              SITUATIONAL_PRESELECTED_URL_PARAM,
              preselectedAnswers.filter(answerId => answerId !== preselectedAnswer).join(','),
            );
          } else {
            searchParams.delete(SITUATIONAL_PRESELECTED_URL_PARAM);
          }

          setSearchParams(searchParams);
        }
      });
    }
  }, [userQuizId, quizViewQuestionId, userQuizType]);

  /**
   * * Redirection cases after quiz completion
   * ! Improve answerTag handing with context. Move params to state to reduce url param proliferation.
   */
  useEffectSkipFirst(() => {
    if (isUserQuizComplete) {
      const retailerCharacterRedirectURL = userQuizCharacter
        ? userQuizRetailerCharacterRedirectUrls?.[userQuizCharacter.identifier]
        : '';
      const redirectValueUrlParam = searchParams.get(REDIRECT_TO_URL_PARAM);
      const productCategoryUrlParam = searchParams.get(PRODUCT_CATEGORY_URL_PARAM);
      const disableUserCharacterToggleUrlParam = searchParams.get(DISABLE_USER_CHARACTER_TOGGLE_URL_PARAM);
      if (isExternalCharacterPage && isAnonymousRetailerAccess && retailerCharacterRedirectURL.length > 3) {
        RouteUtils.redirectExternalCharacterPage(retailerCharacterRedirectURL);
      } else if (isUserQuizTypeSituational) {
        const productCategoryFromAnswerTags = quizViewAnswerTags.filter(isAnswerTagProductCategoryContext)[0]?.name;

        const charactersFromAnswerTagsArray = quizViewAnswerTags.filter(isAnswerTagFilterCharacterContext);
        const productPreferencesFromAnswerTagsArray = quizViewAnswerTags.filter(isAnswerTagProductPreferencesContext);

        const charactersFromAnswerTags = [...new Set(charactersFromAnswerTagsArray.map(i => i.name))]?.join('-');

        const productPreferencesFromAnswerTags = [
          ...new Set(productPreferencesFromAnswerTagsArray.map(i => i.name)),
        ]?.join(',');
        const routeFromAnswerTags = quizViewAnswerTags.find(isAnswerTagRedirectRouteContext)?.name?.replace(/_/g, '/');

        const defaultRedirectRoute = isApplicationKiosk ? PAGES.vinhood.quizResults.situational : PAGES.vinhood.catalog;
        const existsCharacterForTheProductCategory = isDesignSetVinhoodApp
          ? PRODUCT_CATEGORIES_ORDERED.every(category => category in userBestMatchCharacterByProductCategory)
          : userBestMatchCharacterByProductCategory[productCategoryFromAnswerTags];

        const isSituationalTasteTestScenario =
          existsCharacterForTheProductCategory && routeFromAnswerTags === PAGES.vinhood.quiz.chooseTaste;

        const finalRoute =
          !routeFromAnswerTags || isSituationalTasteTestScenario ? defaultRedirectRoute : routeFromAnswerTags;

        const finalUrlParams = {
          [IS_FROM_SITUATIONAL_TEST_URL_PARAM]: true,
          [REDIRECT_TO_URL_PARAM]: defaultRedirectRoute,
          [START_FROM_FULL_CATALOG]: true,
        };

        if (!isSituationalTasteTestScenario) {
          finalUrlParams[DISABLE_USER_CHARACTER_TOGGLE_URL_PARAM] = true;
        }

        if (charactersFromAnswerTags) {
          finalUrlParams[CHARACTERS_URL_PARAM] = charactersFromAnswerTags;
        }

        if (productPreferencesFromAnswerTags) {
          finalUrlParams[PRODUCT_PREFERENCES_URL_PARAM] = productPreferencesFromAnswerTags;
        }

        if (productCategoryFromAnswerTags) {
          finalUrlParams[PRODUCT_CATEGORY_URL_PARAM] = productCategoryFromAnswerTags;
        }

        navigate(prependBasename(finalRoute, finalUrlParams));
      } else if (isUserQuizTypeTaste) {
        if (isDesignSetVinhoodApp) {
          navigate(
            prependBasename(redirectValueUrlParam || PAGES.vinhood.catalog, {
              [CHARACTERS_URL_PARAM]: userQuizCharacter.identifier,
              [DISABLE_USER_CHARACTER_TOGGLE_URL_PARAM]: true,
              [PRODUCT_CATEGORY_URL_PARAM]: productCategoryUrlParam,
            }),
          );
        } else if (isDesignSetVinhoodExperience) {
          navigate(prependBasename(isUserAuthenticated ? PAGES.vinhood.catalog : PAGES.vinhood.registration));
        } else {
          navigate(prependBasename(PAGES.vinhood.quizResults.result));
        }
      } else if (isUserQuizTypeRecipe) {
        if (isDesignSetVinhoodApp && redirectValueUrlParam) {
          navigate(
            prependBasename(redirectValueUrlParam, {
              [CHARACTERS_URL_PARAM]: userQuizCharacter.identifier,
              [DISABLE_USER_CHARACTER_TOGGLE_URL_PARAM]: true,
              [PRODUCT_CATEGORY_URL_PARAM]: productCategoryUrlParam,
            }),
          );
        } else if (isDesignSetVinhoodExperience) {
          navigate(
            prependBasename(PAGES.vinhood.catalog, {
              [CHARACTERS_URL_PARAM]: userQuizCharacter.identifier,
              [TAB_URL_PARAM]: ALL_PRODUCTS_TAB,
            }),
          );
        } else if (isApplicationKiosk) {
          navigate(
            prependBasename(redirectValueUrlParam || PAGES.vinhood.quizResults.situational, {
              [CHARACTERS_URL_PARAM]: userQuizCharacter.identifier,
              [DISABLE_USER_CHARACTER_TOGGLE_URL_PARAM]: true,
              [PRODUCT_CATEGORY_URL_PARAM]: productCategoryUrlParam,
            }),
          );
        } else {
          navigate(prependBasename(PAGES.vinhood.quizResults.result));
        }
      } else if (isUserQuizTypeExpert) {
        if (redirectValueUrlParam) {
          navigate(
            prependBasename(redirectValueUrlParam, {
              [CHARACTERS_URL_PARAM]: userQuizCharacter.identifier,
              [DISABLE_USER_CHARACTER_TOGGLE_URL_PARAM]: true,
              [PRODUCT_CATEGORY_URL_PARAM]: productCategoryUrlParam,
            }),
          );
        } else if (isDesignSetVinhoodExperience) {
          navigate(prependBasename(isUserAuthenticated ? PAGES.vinhood.catalog : PAGES.vinhood.registration));
        } else if (isDesignSetVinhoodApp) {
          navigate(
            prependBasename(PAGES.vinhood.catalog, {
              [CHARACTERS_URL_PARAM]: userQuizCharacter.identifier,
              [DISABLE_USER_CHARACTER_TOGGLE_URL_PARAM]: true,
              [PRODUCT_CATEGORY_URL_PARAM]: productCategoryUrlParam,
            }),
          );
        } else {
          navigate(prependBasename(PAGES.vinhood.registration));
        }
      } else if (isUserQuizTypeMultiProduct) {
        if (disableUserCharacterToggleUrlParam) {
          navigate(
            prependBasename(PAGES.vinhood.catalog, {
              [PRODUCT_CATEGORY_URL_PARAM]: productCategoryUrlParam,
            }),
          );
        } else {
          navigate(prependBasename(isUserAuthenticated ? PAGES.vinhood.home : PAGES.vinhood.quizResults.result));
        }
      }
    }
  }, [isUserQuizComplete]);

  const onGoBackBtnClick = () => {
    /**
     * Situational test for Kiosk and App applies preselectedAnswers for each
     * product category and navigate user to the next page. On the page we show
     * Go Back button to navigate to Home page.
     */

    if (isFirstQuestion) {
      if (quizzes.length === 1) {
        navigate(prependBasename(PAGES.vinhood.home));
      } else {
        navigate(-1);
      }

      return;
    }

    if (isSituationalQuizDropQuestion) {
      navigate(situationalQuizSourcePage);
      return;
    }

    handleQuizViewGoBack();
  };

  if (isDesignSetVinhoodApp && isQuizViewCompleted && isLoading) return <LoadingSpinner />;
  if (isLoading) return <LoadingSpinner />;

  return (
    <QuizPage
      answers={quizViewQuestion.answers}
      customColors={{ customQuizBackgroundColor, customSecondaryColor, customTextColor }}
      firstQuestion={isFirstQuestion}
      goBack={onGoBackBtnClick}
      isApplicationKiosk={isApplicationKiosk}
      isDesignSetVinhoodExperience={isDesignSetVinhoodExperience}
      isDevToolsEnabled={isDevToolsEnabled}
      isUserQuizLoading={isUserQuizLoading}
      progressPercent={quizViewProgress}
      question={quizViewQuestion.text}
      withoutProgressBar={isUserQuizTypeSituational}
      onAnswerSelectHandler={onAnswerSelectHandler}
    />
  );
};

export default QuizLauncher(QuizContainer);
