import { styled } from 'styled-components';

import BackButton from '@components/web/src/atoms/Buttons/BackButton/BackButton';
import { DEFAULT_COLOR_THEME, STATIC_COLORS, STATIC_SHADOWS } from '@components/web/src/foundations';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';

export const GeneralServiceTermsContainer = styled(Flexbox)`
  background: ${DEFAULT_COLOR_THEME.secondaryColor['-100']};
`;

export const HeadingContentWrapper = styled(Flexbox)`
  padding: 32px 16px 24px;
  background: ${STATIC_COLORS.base.white};
  box-shadow: ${STATIC_SHADOWS.xl};
`;

export const BottomSectionWrapper = styled(Flexbox)`
  padding: 16px;
  border-radius: 0px 0px 16px 16px;
  background: ${DEFAULT_COLOR_THEME.secondaryColor['-100']};
`;

export const StyledBackButton = styled(BackButton)`
  padding: 8px 16px;
`;
