import { FC } from 'react';

import { localeCommon } from '@lib/tools/locale/source/web/common';

import LoadingGIF from '@components/web/src/assets/gif/loading.gif';
import VinhoodLogoWhite from '@components/web/src/assets/png/vinhood_logo_white.png';
import { STATIC_COLORS } from '@components/web/src/foundations';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';
import { Image } from '@components/web/src/foundations/Image/Image';
import { Text } from '@components/web/src/foundations/Text/Text';
import * as S from '@components/web/src/templates/SplashScreen/PartnerSplashScreen/styles';

interface IProps {
  retailerLogo: string;
  isLoading: boolean;
}

const PartnerSplashScreen: FC<IProps> = ({ retailerLogo, isLoading }) => {
  const { title, vinhoodSubtitle, vinhoodTitle } = localeCommon.splashScreen;

  return (
    <S.PartnerSplashScreenContainer $retailerLogo={retailerLogo} align="center" direction="column" justify="center">
      {retailerLogo && (
        <Flexbox align="center" className="retailer_logo" direction="column" gap={40} justify="center">
          <Text size="h6" text={title} weight="semibold" />
          <Image alt="retailer" height="167px" objectFit="contain" src={retailerLogo} width="244px" />
        </Flexbox>
      )}
      <Flexbox align="center" className="vinhood_logo" direction="column" gap={16} justify="center">
        <Text color={STATIC_COLORS.base.white} size="body1" text={vinhoodTitle} />
        <img alt="splash" src={VinhoodLogoWhite} />
        <Text color={STATIC_COLORS.base.white} size="body1" text={vinhoodSubtitle} weight="bold" />
      </Flexbox>
      {isLoading && (
        <Flexbox align="center" className="loading_gif" direction="column" justify="center">
          <img alt="loading" src={LoadingGIF} />
        </Flexbox>
      )}
    </S.PartnerSplashScreenContainer>
  );
};

export default PartnerSplashScreen;
