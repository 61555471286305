import { FC, Fragment } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useMediaQuery } from 'react-responsive';

import { TProductInstance } from '@lib/core/products/types';
import { TRetailerLocationStoreType } from '@lib/core/retailers/types';
import { TProductFeedback, TProductFeedbackValue } from '@lib/core/users/slices/productFeedback';
import { feedbackFilter, isProductInstanceInWishlistFilter } from '@lib/core/users/utils/filters';
import { MP_POSITION_CONTEXT } from '@lib/tools/dat/mixpanel/consts';

import Banner, { TBannerCard } from '@components/web/src/atoms/Banner/BannerCard';
import { DESKTOP_RESOLUTION_MIN_VALUE } from '@components/web/src/foundations/consts';
import ProductCard from '@components/web/src/organisms/Cards/ProductCard/ProductCard';
import { IDiscoveryQuiz } from '@components/web/src/templates/Modals/DiscoveryQuizModal/DiscoveryQuizModal';

export type ICatalogProps = {
  banners: TBannerCard[];
  feedbackData?: TProductFeedback[];
  isProductsRequestLoading: boolean;
  itemsCount: number;
  locale: string;
  products: TProductInstance[];
  storeType: TRetailerLocationStoreType;
  wishlistProductInstanceIds: string[];
  isWishlistProductListLoading?: boolean;
  discoveryQuiz: IDiscoveryQuiz;
  getRandomBanner: () => TBannerCard;
  handleBannerClick: (route: string, linkParams: string | '') => void;
  handleProductsRequest: ({ isPagination }: { isPagination?: boolean }) => void;
  handleUpdateFeedback?: (feedback: TProductFeedbackValue, productId: string, productName?: string) => void;
  handleUpdateWishlistProductList?: (productId: string, productName?: string) => void;
};

const AppProductList: FC<ICatalogProps> = ({
  banners,
  feedbackData,
  isProductsRequestLoading,
  itemsCount,
  locale,
  products,
  storeType,
  wishlistProductInstanceIds,
  isWishlistProductListLoading,
  discoveryQuiz,
  handleBannerClick,
  handleProductsRequest,
  handleUpdateFeedback,
  handleUpdateWishlistProductList,
}) => {
  const isDesktopResolution = useMediaQuery({ minWidth: DESKTOP_RESOLUTION_MIN_VALUE });
  return (
    <InfiniteScroll
      className="app-product-list-container"
      dataLength={products.length}
      hasMore={!itemsCount || products.length < itemsCount}
      next={isProductsRequestLoading && !products.length ? null : () => handleProductsRequest({ isPagination: true })}
      scrollableTarget={isDesktopResolution ? 'root-scroll-element' : null}
      loader={
        isProductsRequestLoading ? (
          <div className="catalog-loading">
            <ProductCard key={1} isLoading={isProductsRequestLoading} locale={locale} storeType={storeType} />
            <ProductCard key={2} isLoading={isProductsRequestLoading} locale={locale} storeType={storeType} />
          </div>
        ) : null
      }
    >
      {products?.map((productInstanceData, index) => {
        const banner = banners ? banners[Math.floor(index / 4) % banners.length] : null;

        return (
          <Fragment key={productInstanceData.identifier}>
            <ProductCard
              isResponsive
              discoveryQuiz={discoveryQuiz}
              feedback={feedbackFilter(feedbackData, productInstanceData)}
              handleUpdateFeedback={handleUpdateFeedback}
              handleUpdateWishlistProductList={handleUpdateWishlistProductList}
              isLoading={false}
              isWishlistProductListLoading={isWishlistProductListLoading}
              locale={locale}
              mixpanelIndex={index}
              mixpanelPositionContext={MP_POSITION_CONTEXT.SCROLLABLE_CATALOG}
              productInstanceData={productInstanceData}
              storeType={storeType}
              variant="badges"
              isProductInstanceInWishlist={isProductInstanceInWishlistFilter(
                wishlistProductInstanceIds,
                productInstanceData,
              )}
            />
            {(index + 1) % 4 === 0 && !!banner && <Banner {...banner} onClickHandler={handleBannerClick} />}
          </Fragment>
        );
      })}
    </InfiniteScroll>
  );
};

export default AppProductList;
