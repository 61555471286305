import { createSlice } from '@reduxjs/toolkit';

import { TProductCategory } from '@lib/core/products/types';
import { PRODUCT_CATEGORY_NONE } from '@lib/core/service/consts';
import serviceInstanceSlice, { IServiceInstance } from '@lib/core/service/slices/serviceInstanceSlice';
import { ThirdPartyConnectModalScenario, ThirdPartyConnectModalShown } from '@lib/core/service/types/interface';
import { languages } from '@lib/tools/locale/utils/consts';

interface IService {
  isSplashScreenPageShown: boolean;
  isHostMocked: boolean;
  isDevToolsEnabled: boolean;
  isLocaleEditMode: boolean;
  productCategory: TProductCategory;
  shouldShowTastePathStartPage: boolean;
  shouldHideTastePathTooltipModal: boolean;
  shouldHideDownloadAppCard: boolean;
  isThirdPartyConnectModalShown: ThirdPartyConnectModalShown;
  fidelityCardIdFromKiosk: string;
  isTasteIdGuideShown: boolean;
  thirdPartyConnectModalOpenScenario?: {
    scenario:
      | ThirdPartyConnectModalScenario.WISHLIST
      | ThirdPartyConnectModalScenario.LOCATION
      | ThirdPartyConnectModalScenario.RATE5
      | ThirdPartyConnectModalScenario.RATE4
      | '';
    productName: string;
  };
  previousRoute: string;
  route: string;
  lang: string;
  serviceInstance: IServiceInstance;
}

export const initialState: IService = {
  fidelityCardIdFromKiosk: '',
  isDevToolsEnabled: false,
  isHostMocked: false,
  isLocaleEditMode: false,
  isSplashScreenPageShown: false,
  isTasteIdGuideShown: false,
  isThirdPartyConnectModalShown: {
    location: false,
    rate4: false,
    rate5: false,
    wishlist: false,
  },
  lang: languages.ENGLISH,
  previousRoute: '',
  productCategory: PRODUCT_CATEGORY_NONE,
  route: '',
  serviceInstance: serviceInstanceSlice(undefined, { type: '' }),
  shouldHideDownloadAppCard: false,
  shouldHideTastePathTooltipModal: false,
  shouldShowTastePathStartPage: true,
  thirdPartyConnectModalOpenScenario: {
    productName: '',
    scenario: '',
  },
};

export const serviceSlice = createSlice({
  extraReducers: builder => {
    builder.addDefaultCase((state, action) => {
      state.serviceInstance = serviceInstanceSlice(state.serviceInstance, action);
    });
  },
  initialState,
  name: 'service',
  reducers: {
    setFidelityCardIdFromKiosk: (state, action) => {
      state.fidelityCardIdFromKiosk = action.payload;
    },
    setHideTastePathTooltipModal(state) {
      state.shouldHideTastePathTooltipModal = true;
    },
    setIsDevToolsEnabled(state) {
      state.isDevToolsEnabled = true;
    },
    setIsTasteIdGuideShown: (state, action) => {
      state.isTasteIdGuideShown = action.payload;
    },
    setIsThirdPartyConnectShown: (state, action) => {
      return {
        ...state,
        isThirdPartyConnectModalShown: {
          ...state.isThirdPartyConnectModalShown,
          [action.payload]: true,
        },
      };
    },
    setProductCategory(state, action) {
      state.productCategory = action.payload;
    },
    setServiceLocale(state, action) {
      state.lang = action.payload;
    },
    setShouldHideDownloadAppCard: state => {
      state.shouldHideDownloadAppCard = true;
    },
    setShowTastePathStartPage(state, action) {
      state.shouldShowTastePathStartPage = action.payload;
    },
    setSplashScreenPageShown: state => {
      state.isSplashScreenPageShown = true;
    },
    setThirdPartyConnectModalOpenScenario: (state, action) => {
      state.thirdPartyConnectModalOpenScenario = {
        productName: action.payload.productName,
        scenario: action.payload.scenario,
      };
    },
    toggleLocaleEditMode(state, action) {
      state.isLocaleEditMode = action.payload;
    },
    toggleMockHost(state, action) {
      state.isHostMocked = action.payload;
    },
    writeHistory(state, action) {
      if (state.route !== action.payload) {
        if (!state.previousRoute) {
          return {
            ...state,
            previousRoute: action.payload,
            route: action.payload,
          };
        }
        return {
          ...state,
          previousRoute: state.route,
          route: action.payload,
        };
      }
      return {
        ...state,
      };
    },
  },
});

export default serviceSlice.reducer;

export const {
  setServiceLocale,
  setIsDevToolsEnabled,
  setProductCategory,
  setShowTastePathStartPage,
  setHideTastePathTooltipModal,
  writeHistory,
  toggleLocaleEditMode,
  toggleMockHost,
  setSplashScreenPageShown,
  setIsTasteIdGuideShown,
  setShouldHideDownloadAppCard,
  setThirdPartyConnectModalOpenScenario,
  setIsThirdPartyConnectShown,
  setFidelityCardIdFromKiosk,
} = serviceSlice.actions;
