import { FC, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { EXPERIENCES_MAX_PRICE_QUERY, EXPERIENCES_MIN_PRICE_QUERY } from '@app/web/src/helpers/consts';

import { BANNER_POSITION_EXPERIENCES } from '@lib/core/banners/consts';
import { useBanners } from '@lib/core/banners/hooks';
import { fetchExperiences } from '@lib/core/experiences/slices/experienceSearchSlice';
import { B2C_GET_EXPERIENCES_PAGE_SIZE, MOOD_QUERY } from '@lib/core/service/consts';
import { getMultipleUniqueRandomItemsFromArray, prependBasename } from '@lib/core/service/utils';
import MixpanelTracker from '@lib/tools/dat/mixpanel';
import { useCatalogFilter } from '@lib/tools/filterManager/hooks';
import {
  actionResetCatalogFilters,
  actionResetFilterValuesUpdatedTime,
} from '@lib/tools/filterManager/slices/productFilter';
import {
  FILTER_TYPE_MOOD,
  FILTER_TYPE_PRICE_RANGE_MAX,
  FILTER_TYPE_PRICE_RANGE_MIN,
  FILTER_TYPE_PRODUCT_CATEGORY,
  PRODUCT_CATEGORY_QUERY,
} from '@lib/tools/shared/helpers/consts';
import { useTypedSelector } from '@lib/tools/views/hooks/useTypedSelector';

import { TBannerCard } from '@components/web/src/atoms/Banner/BannerCard';
import {
  FILTER_VALUE_EXPERIENCES_PRICE_MAX,
  FILTER_VALUE_EXPERIENCES_PRICE_MIN,
  PRODUCT_CATEGORY_QUERY_KEY,
} from '@components/web/src/foundations/consts';
import ExperienceSearchPage from '@components/web/src/pages/app/ExperienceSearchPage/ExperienceSearchPage';

const ExperienceSearchContainer: FC = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const {
    handleUpdateFilterType,
    handleResetPriceRange,
    handleSetPriceRangeValues,
    handleUpdatePromotionToggleActive,
    handleUpdateCharactersToggleActive,
  } = useCatalogFilter();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { search } = useLocation();
  const { experienceType } = useParams();

  const {
    isExperienceRequestLoading,
    experiencesData: { results: experiencesLists, count: searchCount },
  } = useTypedSelector(state => state.experienceSearch);
  const {
    filterManager: { updatedFilterValuesTime },
  } = useTypedSelector(state => state.productFilters);

  const productCategory = new URLSearchParams(search).get(PRODUCT_CATEGORY_QUERY_KEY);

  const {
    [FILTER_TYPE_PRODUCT_CATEGORY]: selectedProductCategoriesQuery,
    [FILTER_TYPE_MOOD]: selectedMoodsQuery,
    [FILTER_TYPE_PRICE_RANGE_MAX]: priceRangeMaxQuery,
    [FILTER_TYPE_PRICE_RANGE_MIN]: priceRangeMinQuery,
  } = useCatalogFilter();
  const handleExperiencesRequest = ({ isPagination }: { isPagination?: boolean }) => {
    const params = {
      [PRODUCT_CATEGORY_QUERY]: productCategory,
      experience_type: experienceType,
      page: isPagination ? Math.floor(experiencesLists.length / B2C_GET_EXPERIENCES_PAGE_SIZE) + 1 : 1,
      page_size: B2C_GET_EXPERIENCES_PAGE_SIZE,
    };
    if (selectedProductCategoriesQuery) {
      params[PRODUCT_CATEGORY_QUERY_KEY] = selectedProductCategoriesQuery;
    }
    if (selectedMoodsQuery) {
      params[MOOD_QUERY] = selectedMoodsQuery;
    }

    if (priceRangeMaxQuery) {
      params[EXPERIENCES_MAX_PRICE_QUERY] = priceRangeMaxQuery;
      params[EXPERIENCES_MIN_PRICE_QUERY] = priceRangeMinQuery;
    }
    dispatch(
      fetchExperiences({
        isPagination,
        params,
      }),
    );
  };

  useEffect(() => {
    if (updatedFilterValuesTime) {
      handleExperiencesRequest({});
    }
  }, [updatedFilterValuesTime]);

  useEffect(() => {
    handleExperiencesRequest({ isPagination: false });
    handleSetPriceRangeValues({
      appMaxPriceRangeValue: FILTER_VALUE_EXPERIENCES_PRICE_MAX,
      appMinPriceRangeValue: FILTER_VALUE_EXPERIENCES_PRICE_MIN,
      userLowerPriceRangeValue: FILTER_VALUE_EXPERIENCES_PRICE_MIN,
      userUpperPriceRangeValue: FILTER_VALUE_EXPERIENCES_PRICE_MAX,
    });

    return () => {
      dispatch(actionResetCatalogFilters());
      dispatch(actionResetFilterValuesUpdatedTime());
    };
  }, [dispatch, experienceType, productCategory]);

  const filteredExperiencesData = useMemo(
    () => experiencesLists.filter(item => item.name.toLowerCase().includes(searchQuery)),
    [experiencesLists, searchQuery],
  );

  const banners = useBanners({
    bannerPosition: BANNER_POSITION_EXPERIENCES,
    returnAllBanners: true,
  });

  const getBanner: () => TBannerCard = () => getMultipleUniqueRandomItemsFromArray(banners, 1)[0];

  const topBanner = useMemo(() => getBanner(), [productCategory, banners]);

  const searchProps = {
    inputValue: searchQuery,
    searchHandler: setSearchQuery,
  };

  useEffect(() => {
    MixpanelTracker.events.experienceCategoryCatalogView(experienceType);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleBannerClick = (route, linkParams) =>
    navigate(`${prependBasename(`/${route}/`)}${linkParams ? `?${linkParams}` : ''}`);

  return (
    <ExperienceSearchPage
      experienceType={experienceType}
      experiencesData={filteredExperiencesData}
      getRandomBanner={getBanner}
      handleBannerClick={handleBannerClick}
      handleExperiencesRequest={handleExperiencesRequest}
      handleResetPriceRange={handleResetPriceRange}
      handleUpdateCharactersToggleActive={handleUpdateCharactersToggleActive}
      handleUpdateFilterType={handleUpdateFilterType}
      handleUpdatePromotionToggleActive={handleUpdatePromotionToggleActive}
      isExperienceRequestLoading={isExperienceRequestLoading}
      searchCount={searchCount}
      searchProps={searchProps}
      topBanner={topBanner}
    />
  );
};

export default ExperienceSearchContainer;
