import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { useEffect, useState } from 'react';

import { themeDevTools } from '@lib/tools/devtools/components/theme';

/**
 * ? This component should take in a list of
 * strings as inputs and provide a callback to the parent when the selected options are changed.
 */

export const MultiSelectChips = ({ listItems, onChange }) => {
  const [chips, setChips] = useState<string[]>(listItems);

  useEffect(() => {
    onChange(chips);
  }, [chips]);

  const MenuProps = {
    PaperProps: {
      style: {
        background: themeDevTools.color.black,
        border: `1px solid ${themeDevTools.color.white}`,
        borderRadius: 0,
        color: themeDevTools.color.white,
        fontSize: 10,
        maxHeight: 48 * 4.5 + 8,
      },
    },
    disablePortal: true,
  };

  const handleChange = (event: SelectChangeEvent<typeof chips>) => {
    const {
      target: { value },
    } = event;
    setChips(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  if (!listItems.length) return null;

  return (
    <Box
      sx={{
        margin: themeDevTools.spacing.medium,
        marginTop: themeDevTools.spacing.large,
      }}
    >
      <FormControl
        sx={{
          '.Mui-focused': {
            color: themeDevTools.color.white,
          },
          '.MuiOutlinedInput-notchedOutline': {
            border: `1px dashed ${themeDevTools.color.white} !important`,
            borderRadius: 0,
            fontSize: themeDevTools.font.small,
          },
          color: themeDevTools.color.white,
          width: '100%',
        }}
      >
        <InputLabel
          sx={{
            color: themeDevTools.color.white,
            fontSize: themeDevTools.font.small,
          }}
        >
          Options
        </InputLabel>
        <Select
          multiple
          MenuProps={MenuProps}
          input={<OutlinedInput label="Chip" />}
          value={chips}
          renderValue={selected => (
            <Box sx={{ color: themeDevTools.color.white, display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map(value => (
                <Chip
                  key={value}
                  label={value}
                  sx={{
                    backgroundColor: themeDevTools.color.blue,
                    color: themeDevTools.color.white,
                    fontSize: themeDevTools.font.tiny,
                    fontWeight: 700,
                    height: 24,
                  }}
                />
              ))}
            </Box>
          )}
          sx={{
            '.MuiSelect-icon': {
              color: themeDevTools.color.white,
            },
            '.MuiSelect-outlined': {
              color: themeDevTools.color.white,
            },
            fontSize: 10,
          }}
          onChange={handleChange}
        >
          {listItems.map(name => (
            <MenuItem
              key={name}
              value={name}
              sx={{
                '&&.Mui-selected': {
                  backgroundColor: themeDevTools.color.blue,
                  color: themeDevTools.color.white,
                },
                fontSize: themeDevTools.font.small,
              }}
            >
              {name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export const DropdownSelect = ({ title, options, onChange, isDisabled = false }) => {
  return (
    <Box>
      {title}

      <select disabled={isDisabled} name={title} onChange={onChange}>
        {options}
      </select>
    </Box>
  );
};
