import { FC } from 'react';

import { TDietaryPreferenceOption } from '@lib/core/users/types';
import { localeApp } from '@lib/tools/locale/source/web/app';
import LocaleFragment from '@lib/tools/locale/views/LocaleFragment';

import CloseButton from '@components/web/src/atoms/Buttons/CloseButton/CloseButton';
import ModalWrapper from '@components/web/src/templates/Modals/ModalWrapper/ModalWrapper';

interface IProps {
  isModalOpen: boolean;
  dietaryPreferenceTagsOptions: TDietaryPreferenceOption[];
  hideModal: () => void;
}

const TagsModal: FC<IProps> = ({ isModalOpen, dietaryPreferenceTagsOptions, hideModal }) => {
  const { heading, headingDescription } = localeApp.tagsModal;

  return (
    <ModalWrapper hideModal={hideModal} isOpen={isModalOpen} modalClassName="tags-modal-wrapper">
      <div className="tags-modal-container">
        <div className="header-wrapper">
          <CloseButton handleClick={hideModal} />
          <p className="title">
            <LocaleFragment message={heading} />
          </p>
          <p className="description">
            <LocaleFragment message={headingDescription} />
          </p>
        </div>
        <div className="tags-wrapper">
          {dietaryPreferenceTagsOptions.map(({ name, description }, index) => (
            <div key={index}>
              <div className="tag-title">{name}</div>
              <div className="tag-description">{description}</div>
            </div>
          ))}
        </div>
      </div>
    </ModalWrapper>
  );
};

export default TagsModal;
