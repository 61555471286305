import { FC } from 'react';

import { localeApp } from '@lib/tools/locale/source/web/app';

import BannerCard, { TBannerCard } from '@components/web/src/atoms/Banner/BannerCard';
import BackButton from '@components/web/src/atoms/Buttons/BackButton/BackButton';
import SectionHeader from '@components/web/src/atoms/Headers/SectionHeader/SectionHeader';
import ProductCardTopBanner from '@components/web/src/atoms/ProductCardElements/ProductCardTopBanner/ProductCardTopBanner';

interface IProps {
  characterBanners: {
    [key: string]: TBannerCard[];
  };
  handleBackButtonClick: () => void;
}

const HintPage: FC<IProps> = ({ characterBanners, handleBackButtonClick }) => {
  const { bannerSectionHeadingText, bannerSectionDescText, backButtonText } = localeApp.hintPage;

  return (
    <div className="hint-page-container">
      <BackButton handleClick={handleBackButtonClick} text={backButtonText} />
      <SectionHeader className="section-header" subtitle={bannerSectionDescText} title={bannerSectionHeadingText} />
      <div className="hints-section">
        {Object.keys(characterBanners).map(productCategory => {
          if (characterBanners[productCategory]?.length > 0) {
            return (
              <div key={productCategory} className="hints-list">
                <ProductCardTopBanner productCategory={productCategory} />
                {characterBanners[productCategory].map(banner => (
                  <BannerCard {...banner} key={banner.identifier} />
                ))}
              </div>
            );
          }
          return null;
        })}
      </div>
    </div>
  );
};

export default HintPage;
