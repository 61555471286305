import React from 'react';

import CommentIcon from '@components/web/src/assets/icons/registrationPageAnimation/comment-animation-icon.svg';
import FeedbackIcon from '@components/web/src/assets/icons/registrationPageAnimation/feedback-animation-icon.svg';
import WishlistIcon from '@components/web/src/assets/icons/registrationPageAnimation/wishlist-animation-icon.svg';
import * as S from '@components/web/src/organisms/Authentication/AuthBanners/RegisterDetailsBanner/styles';

const RegisterDetailsBannerIcons: React.FC = () => {
  return (
    <S.AnimationContainer align="center" gap={0} justify="center">
      <S.FeedbackIconWrapper alt="feedback-icon-animation" src={FeedbackIcon} />
      <S.WishlistIconWrapper alt="wishlist-icon-animation" src={WishlistIcon} />
      <S.CommentIconWrapper alt="comment-icon-animation" src={CommentIcon} />
    </S.AnimationContainer>
  );
};

export default RegisterDetailsBannerIcons;
