import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useCharacters } from '@lib/core/characters/hooks';
import { selectEssenceGroupsLoading } from '@lib/core/essences/selectors/groups';
import { useRecycling, useWellness } from '@lib/core/productAttributes/hooks';
import { useProducts } from '@lib/core/products/hooks/products';
import { useRetailer } from '@lib/core/retailers/hooks/retailer';
import { selectRetailerData } from '@lib/core/retailers/selectors/retailer';
import { setSplashScreenPageShown } from '@lib/core/service/slices';

import LoadingSpinner, {
  LOADING_SPINNER_VARIANTS,
} from '@components/web/src/templates/Loading/LoadingSpinner/LoadingSpinner';
import PartnerSplashScreen from '@components/web/src/templates/SplashScreen/PartnerSplashScreen/PartnerSplashScreen';

const SplashScreenContainer = () => {
  const { retailerLogo, isRetailerLoading } = useRetailer();
  const retailerData = useSelector(selectRetailerData);
  const { isCharactersLoading } = useCharacters();
  const { isWellnessLoading } = useWellness();
  const { isRecyclingLoading } = useRecycling();
  const { isProductsLoading } = useProducts();
  const iEssenceLoading = useSelector(selectEssenceGroupsLoading);

  const dispatch = useDispatch();

  const isLoading =
    isCharactersLoading || iEssenceLoading || isWellnessLoading || isRecyclingLoading || isProductsLoading;

  const [showLoadingGIF, setShowLoadingGIF] = useState(false);
  const [animationCompleted, setAnimationCompleted] = useState(false);

  useEffect(() => {
    // Timer for the 6s animation of vinhoodLogoWhite
    if (!isRetailerLoading && retailerData) {
      const animationTime = retailerLogo ? 6000 : 3000;
      const logoTimer = setTimeout(() => {
        setAnimationCompleted(true);
        if (isLoading) {
          setShowLoadingGIF(true); // Show loading GIF after vinhoodLogoWhite animation completes
        }
      }, animationTime);
      return () => clearTimeout(logoTimer);
    }
    return () => {};
  }, [retailerData, isRetailerLoading, isLoading]);

  useEffect(() => {
    if (animationCompleted && !isLoading) {
      dispatch(setSplashScreenPageShown());
    }
  }, [animationCompleted, isLoading]);
  return !isRetailerLoading ? (
    <PartnerSplashScreen isLoading={showLoadingGIF} retailerLogo={retailerLogo} />
  ) : (
    <LoadingSpinner variant={LOADING_SPINNER_VARIANTS.TRANSPARENT} />
  );
};

export default SplashScreenContainer;
