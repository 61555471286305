import { Tooltip, TooltipProps, tooltipClasses } from '@mui/material';
import { styled } from '@mui/system';

import { themeDevTools } from '@lib/tools/devtools/components/theme';

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow={props.arrow} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: `${themeDevTools.color.black}`,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#EEEEEE',
    border: '1px solid #121212',
    borderRadius: `${themeDevTools.spacing.large}`,
    color: '#121212',
    fontFamily: 'monospace',
    fontSize: `${themeDevTools.font.tiny}`,
    maxWidth: '100vw',
    padding: `${themeDevTools.spacing.small} ${themeDevTools.spacing.large}`,
    textAlign: 'center',
  },
}));

export default StyledTooltip;
