import { FC, useMemo } from 'react';

import { getMultipleUniqueRandomItemsFromArray } from '@lib/core/service/utils';
import { localeWidget } from '@lib/tools/locale/source/web/widget';
import { ILocaleText } from '@lib/tools/locale/views/LocaleFragment';
import { VH_VARIANTS } from '@lib/tools/shared/helpers/consts';

import BackButton from '@components/web/src/atoms/Buttons/BackButton/BackButton';
import Button from '@components/web/src/atoms/Buttons/Button';
import { STATIC_COLORS } from '@components/web/src/foundations';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';
import { Text } from '@components/web/src/foundations/Text/Text';
import { TastingTip } from '@components/web/src/organisms/Cards/TastingTip/TastingTip';
import * as S from '@components/web/src/templates/TestFlowsPages/AgePage/styles';

interface IProps {
  birthYear: string;
  maxValidBirthYear: string;
  isValidBirthYear: boolean;
  handleContinueClick: () => void;
  handleBirthYearChange: (birthYear: string) => void;
  handleSkipClick: () => void;
  handleGoBack: () => void;
}

export const AgePage: FC<IProps> = ({
  birthYear,
  maxValidBirthYear,
  isValidBirthYear,
  handleContinueClick,
  handleBirthYearChange,
  handleSkipClick,
  handleGoBack,
}) => {
  const {
    continueBtn,
    ageTitle,
    skipBtn,
    agePreTitle,
    randomAgeTastingTip1,
    randomAgeTastingTip2,
    randomAgeTastingTip3,
    randomAgeTastingTip4,
    randomAgeTastingTip5,
    randomAgeTastingTip6,
    randomAgeTastingTip7,
    randomAgeTastingTip8,
    ageError,
  } = localeWidget.ageAndGenderPages;

  const tastingTipText: ILocaleText = useMemo(
    () =>
      getMultipleUniqueRandomItemsFromArray(
        [
          randomAgeTastingTip1,
          randomAgeTastingTip2,
          randomAgeTastingTip3,
          randomAgeTastingTip4,
          randomAgeTastingTip5,
          randomAgeTastingTip6,
          randomAgeTastingTip7,
          randomAgeTastingTip8,
        ],
        1,
      )[0],
    [],
  );

  const errorText =
    birthYear?.length === 4 &&
    (Number.isNaN(Number(birthYear)) || Number(birthYear) < 1920 || Number(birthYear) > Number(maxValidBirthYear))
      ? ageError
      : '';

  return (
    <S.AgePageContainer>
      <S.Wrapper direction="column" gap={8}>
        <Text color={STATIC_COLORS.green[600]} size="body1" text={agePreTitle} weight="medium" />
        <Text color={STATIC_COLORS.base.black} fontFamily="Fraunces" size="h5" text={ageTitle} weight="semibold" />
      </S.Wrapper>
      <S.Wrapper direction="column" gap={12}>
        <S.AgeInput
          maxLength={4}
          placeholder="0000"
          value={birthYear}
          onChange={e => handleBirthYearChange(e.target.value)}
        />
        <S.ErrorText
          shouldRenderEmptySelector
          color={STATIC_COLORS.error[600]}
          localeOptions={{ maxValidBirthYear }}
          size="body1"
          text={errorText}
          weight="medium"
        />
      </S.Wrapper>
      <Flexbox direction="column" gap={16}>
        <Button
          disabled={!isValidBirthYear}
          fontSize="subtitle2"
          handleClick={handleContinueClick}
          size="md"
          text={continueBtn}
        />
        <Button fontSize="body1" handleClick={handleSkipClick} size="sm" text={skipBtn} variant={VH_VARIANTS.LINK} />
        <TastingTip text={tastingTipText} />
        <BackButton handleClick={handleGoBack} variant="long" />
      </Flexbox>
    </S.AgePageContainer>
  );
};
