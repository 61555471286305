import { FC, useMemo } from 'react';

import { useDietaryPreferences } from '@lib/core/users/hooks';
import { currencies } from '@lib/tools/locale/utils/consts';
import {
  FILTER_TYPE_MOOD,
  FILTER_TYPE_PRICE_RANGE,
  FILTER_TYPE_PRODUCT_CATEGORY,
  PRODUCT_CATEGORY_BEER,
  PRODUCT_CATEGORY_COFFEE,
  PRODUCT_CATEGORY_WINE,
} from '@lib/tools/shared/helpers/consts';

import {
  FILTER_VALUE_EXPERIENCES_PRICE_MAX,
  FILTER_VALUE_EXPERIENCES_PRICE_MIN,
} from '@components/web/src/foundations/consts';
import FilterComponent from '@components/web/src/organisms/Filter/FilterComponent/FilterComponent';

type Props = {
  handleClose: () => void;
  handleApply: () => void;
  handleUpdatePromotionToggleActive: (value: boolean) => void;
  handleUpdateCharactersToggleActive: (value: boolean) => void;
};

const ExperienceFilterContainer: FC<Props> = ({
  handleClose,
  handleApply,
  handleUpdatePromotionToggleActive,
  handleUpdateCharactersToggleActive,
}) => {
  const { dietaryPreferenceMoodOptions } = useDietaryPreferences();

  const moodFilterData = useMemo(
    () =>
      dietaryPreferenceMoodOptions.map(({ name, exposure, slug }) => ({
        filterType: exposure,
        isActive: true,
        name,
        value: slug,
      })),
    [dietaryPreferenceMoodOptions],
  );

  const filtersDataParsed: any[] = [
    {
      filterType: FILTER_TYPE_PRICE_RANGE,
      listContent: [
        {
          currency: currencies.EUR,
          max: FILTER_VALUE_EXPERIENCES_PRICE_MAX,
          min: FILTER_VALUE_EXPERIENCES_PRICE_MIN,
        },
      ],
    },
    {
      filterType: FILTER_TYPE_MOOD,
      listContent: moodFilterData,
    },
    {
      filterType: FILTER_TYPE_PRODUCT_CATEGORY,
      listContent: [
        {
          filterType: FILTER_TYPE_PRODUCT_CATEGORY,
          isActive: true,
          name: PRODUCT_CATEGORY_WINE,
          value: PRODUCT_CATEGORY_WINE,
        },
        {
          filterType: FILTER_TYPE_PRODUCT_CATEGORY,
          isActive: true,
          name: PRODUCT_CATEGORY_BEER,
          value: PRODUCT_CATEGORY_BEER,
        },
        {
          filterType: FILTER_TYPE_PRODUCT_CATEGORY,
          isActive: true,
          name: PRODUCT_CATEGORY_COFFEE,
          value: PRODUCT_CATEGORY_COFFEE,
        },
      ],
    },
  ];

  return (
    <FilterComponent
      data={filtersDataParsed}
      handleApply={handleApply}
      handleClose={handleClose}
      handleUpdateCharactersToggleActive={handleUpdateCharactersToggleActive}
      handleUpdatePromotionToggleActive={handleUpdatePromotionToggleActive}
    />
  );
};

export { ExperienceFilterContainer };
