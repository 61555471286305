import { FC, useMemo } from 'react';

import { getMultipleUniqueRandomItemsFromArray } from '@lib/core/service/utils';
import { localeWidget } from '@lib/tools/locale/source/web/widget';
import { ILocaleText } from '@lib/tools/locale/views/LocaleFragment';
import { VH_VARIANTS } from '@lib/tools/shared/helpers/consts';

import BackButton from '@components/web/src/atoms/Buttons/BackButton/BackButton';
import Button from '@components/web/src/atoms/Buttons/Button';
import {
  CheckboxAnswer,
  ICheckboxAnswer,
} from '@components/web/src/atoms/Checkboxes&Radio/CheckboxAnswer/CheckboxAnswer';
import { STATIC_COLORS } from '@components/web/src/foundations';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';
import { Text } from '@components/web/src/foundations/Text/Text';
import { TastingTip } from '@components/web/src/organisms/Cards/TastingTip/TastingTip';
import * as S from '@components/web/src/templates/TestFlowsPages/GenderPage/styles';

interface IProps {
  genderOptions: ICheckboxAnswer[];
  isDisabledBtn: boolean;
  handleGenderOptionClick: (id: string) => void;
  handleContinueClick: () => void;
  handleSkipClick: () => void;
  handleGoBack: () => void;
}

export const GenderPage: FC<IProps> = ({
  genderOptions,
  isDisabledBtn,
  handleGenderOptionClick,
  handleContinueClick,
  handleSkipClick,
  handleGoBack,
}) => {
  const {
    genderTitle,
    genderSubtitle,
    skipBtn,
    continueBtn,
    randomGenderTastingTip1,
    randomGenderTastingTip2,
    randomGenderTastingTip3,
    randomGenderTastingTip4,
    randomGenderTastingTip5,
    randomGenderTastingTip6,
  } = localeWidget.ageAndGenderPages;

  const tastingTipText: ILocaleText = useMemo(
    () =>
      getMultipleUniqueRandomItemsFromArray(
        [
          randomGenderTastingTip1,
          randomGenderTastingTip2,
          randomGenderTastingTip3,
          randomGenderTastingTip4,
          randomGenderTastingTip5,
          randomGenderTastingTip6,
        ],
        1,
      )[0],
    [],
  );

  return (
    <S.GenderPageContainer>
      <Flexbox align="center" direction="column" gap={8}>
        <Text color={STATIC_COLORS.green['600']} size="body1" text={genderSubtitle} weight="medium" />
        <S.Title
          color={STATIC_COLORS.base.black}
          fontFamily="Fraunces"
          size="h5"
          text={genderTitle}
          weight="semibold"
        />
      </Flexbox>
      <S.GendersWrapper>
        {genderOptions?.map((data, idx) => (
          <CheckboxAnswer key={idx} isResponsive data={data} handleClick={handleGenderOptionClick} />
        ))}
      </S.GendersWrapper>
      <S.CtaWrapper>
        <Button
          disabled={isDisabledBtn}
          fontSize="subtitle2"
          handleClick={handleContinueClick}
          size="md"
          text={continueBtn}
        />
        <Button fontSize="body1" handleClick={handleSkipClick} size="sm" text={skipBtn} variant={VH_VARIANTS.LINK} />
      </S.CtaWrapper>
      <Flexbox direction="column" gap={16}>
        <TastingTip text={tastingTipText} />
        <BackButton handleClick={handleGoBack} variant="long" />
      </Flexbox>
    </S.GenderPageContainer>
  );
};
