import { FC, useMemo, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { BANNER_POSITION_RECIPE_PAGE } from '@lib/core/banners/consts';
import { useBanners } from '@lib/core/banners/hooks';
import { useQuizView, useQuizzes } from '@lib/core/quizzes/hooks';
import QuizUtils from '@lib/core/quizzes/utils';
import { QUIZ_TYPE_RECIPE } from '@lib/core/quizzes/utils/consts';
import { isAnswerTagProductCategoryContext } from '@lib/core/quizzes/utils/filters';
import { useRetailer } from '@lib/core/retailers/hooks/retailer';
import { isApplicationKiosk } from '@lib/core/service/consts';
import { useApp } from '@lib/core/service/hooks';
import backendApiUrls from '@lib/core/service/requests/backend_api_urls';
import request from '@lib/core/service/requests/request';
import { createBackendRetailerUrl, prependBasename } from '@lib/core/service/utils';
import MixpanelTracker from '@lib/tools/dat/mixpanel';
import { getProductCategoryPairingIcon } from '@lib/tools/shared/helpers';
import {
  PRODUCT_CATEGORY_URL_PARAM,
  REDIRECT_TO_URL_PARAM,
  SITUATIONAL_PRESELECTED_URL_PARAM,
} from '@lib/tools/shared/helpers/consts';
import { useRetailerDesignSet } from '@lib/tools/views/hooks';
import { useDebounceEffect } from '@lib/tools/views/hooks/useDebounceEffect';
import { PAGES } from '@lib/tools/views/urls';

import RecipePage from '@components/web/src/pages/RecipePage/RecipePage';
import LoadingSpinner from '@components/web/src/templates/Loading/LoadingSpinner/LoadingSpinner';
import FoodPairingPage from '@components/web/src/templates/TestFlowsPages/FoodPairingPage/FoodPairingPage';

const RecipePageContainer: FC = () => {
  const [recipes, setRecipes] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [isNoRecipesFound, setIsNoRecipesFound] = useState(false);
  const [isRecipesLoading, setIsRecipesLoading] = useState(false);
  const [searchParams] = useSearchParams();

  const { isDesignSetVinhoodApp, isDesignSetVinhoodExperience } = useRetailerDesignSet();
  const { retailerSlug } = useRetailer();
  const { productCategory } = useApp();
  const { quizViewAnswerTags } = useQuizView();

  const productCategoryUrlParam: any = searchParams.get(PRODUCT_CATEGORY_URL_PARAM);

  const searchHandler = searchText => setSearchQuery(searchText);

  const productCategoryMemoized = useMemo(() => {
    if (isDesignSetVinhoodApp) {
      return quizViewAnswerTags.filter(isAnswerTagProductCategoryContext)[0]?.name;
    }

    return productCategory;
  }, [quizViewAnswerTags, isDesignSetVinhoodApp, productCategory]);

  useDebounceEffect(
    () => {
      if (searchQuery.length > 3) {
        const apiUrl = createBackendRetailerUrl(backendApiUrls.apiUrlRecipeAutoSuggest, retailerSlug);

        setIsRecipesLoading(true);

        request(apiUrl, {
          params: { product_category: productCategoryMemoized, text: searchQuery },
        })
          .then(res => {
            setRecipes(res);
            setIsNoRecipesFound(!res.length);
          })
          .catch(error => console.error(error))
          .finally(() => setIsRecipesLoading(false));
      }

      if (searchQuery === '') {
        setRecipes(null);
        setIsNoRecipesFound(false);
      }
    },
    [searchQuery, productCategoryMemoized],
    1000,
  );

  const useRecipePageBanner = () => {
    const bannerData = useBanners({
      bannerPosition: BANNER_POSITION_RECIPE_PAGE,
    });

    const redirectUrl = searchParams.get(REDIRECT_TO_URL_PARAM);
    const linkParams = isDesignSetVinhoodApp
      ? `${bannerData[0].link_params}&${PRODUCT_CATEGORY_URL_PARAM}=${productCategoryMemoized}`
      : `${
          redirectUrl ? `${REDIRECT_TO_URL_PARAM}=${redirectUrl}` : ''
        }&${PRODUCT_CATEGORY_URL_PARAM}=${productCategoryMemoized}`;

    return {
      ...bannerData[0],
      link_params: linkParams,
    };
  };

  const recipePageBanner = useRecipePageBanner();
  const { isQuizzesFetching } = useQuizzes();

  const navigate = useNavigate();
  const { search } = useLocation();

  const redirectToPreviousPage = () => {
    let url;
    if (isDesignSetVinhoodApp) {
      const situationalPreselected = new URLSearchParams(search).get(SITUATIONAL_PRESELECTED_URL_PARAM);

      url = prependBasename(PAGES.vinhood.quiz.situational, { preselected: situationalPreselected });
    } else if (isDesignSetVinhoodExperience) {
      url = prependBasename(PAGES.vinhood.home, {
        ...(productCategoryUrlParam ? { [PRODUCT_CATEGORY_URL_PARAM]: productCategoryUrlParam } : {}),
      });
    } else if (isApplicationKiosk) {
      url = prependBasename(PAGES.vinhood.quiz.situational);
    }

    if (url) {
      navigate(url);
    }
  };

  const handleBannerClick = (route, linkParams) => {
    navigate(`${prependBasename(`/${route}/`)}${linkParams ? `?${linkParams}` : ''}`);
  };

  const redirectToRecipeTest = () => {
    navigate(prependBasename(PAGES.vinhood.quiz.recipe));
    MixpanelTracker.events.startSituationalTestClick();
  };

  const isRecipeQuizAvailable = !isQuizzesFetching && !!QuizUtils.getQuiz(QUIZ_TYPE_RECIPE);

  if (isQuizzesFetching) {
    return <LoadingSpinner />;
  }

  return isApplicationKiosk ? (
    <RecipePage
      handleBannerClick={handleBannerClick}
      isApplicationKiosk={isApplicationKiosk}
      isDesignSetVinhoodApp={isDesignSetVinhoodApp}
      isNoRecipesFound={isNoRecipesFound}
      isRecipesLoading={isRecipesLoading}
      productCategory={productCategoryMemoized}
      recipePageBanner={recipePageBanner}
      recipes={recipes}
      redirectToPreviousPage={redirectToPreviousPage}
      searchHandler={searchHandler}
      value={searchQuery}
    />
  ) : (
    <FoodPairingPage
      foodPairingIcon={getProductCategoryPairingIcon(productCategory)}
      isLoading={isRecipesLoading}
      isNoRecipesFound={isNoRecipesFound}
      isRecipeQuizAvailable={isRecipeQuizAvailable}
      productCategory={productCategoryUrlParam || productCategory}
      recipes={recipes}
      redirectToPreviousPage={redirectToPreviousPage}
      redirectToTakeTest={redirectToRecipeTest}
      searchHandler={searchHandler}
      searchQuery={searchQuery}
    />
  );
};

export default RecipePageContainer;
