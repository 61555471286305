export const parseExploreLocation = data => {
  const {
    identifier = '',
    store_type: storeType,
    producer,
    slug = '',
    phone_number: phone = '',
    email = '',
    description = '',
    images = [],
    location,
    taste_match: tasteMatch = null,
    catalog_type: catalogType = '',
    name = '',
    associated_kiosk_retailer_location: associatedKioskRetailerLocation,
  } = data || {};

  const locationName = name || slug;
  const locationAddress = location?.address || '';
  const { latitude = '', longitude = '' } = location?.location || {};
  const locationCoordinates = latitude && longitude ? [Number(latitude), Number(longitude)] : null;
  const producerId = producer?.identifier || '';
  const producerLogo = producer?.image || '';
  const producerWebsite = producer?.website || '';

  const producerData = {
    producerId,
    producerLogo,
    producerWebsite,
  };
  const locationData = {
    associatedRetailerLocation: associatedKioskRetailerLocation,
    locationAddress,
    locationCatalogType: catalogType,
    locationCoordinates,
    locationDescription: description,
    locationEmail: email,
    locationId: identifier,
    locationImages: images,
    locationName,
    locationPhone: phone,
    locationSlug: slug,
    locationStoreType: storeType,
    locationTasteMatch: tasteMatch,
  };

  return {
    locationData,
    producerData,
  };
};
