import styled, { css } from 'styled-components';

import BackButton from '@components/web/src/atoms/Buttons/BackButton/BackButton';

export const PrivacyPolicyPage = styled.div<{ $isApplicationKiosk: boolean }>`
  position: relative;
  width: 100%;
  padding: 16px 16px;
  color: var(--vh-black);
  p,
  li,
  ul {
    padding: 0;
    margin: 0;
  }

  ul {
    padding-left: 15px;
    list-style-type: disc;
  }

  ${({ $isApplicationKiosk }) =>
    $isApplicationKiosk &&
    `
    padding: 32px 32px 92px;
  `}
`;

export const MainTitle = styled.p<{ $isApplicationKiosk: boolean }>`
  margin-bottom: 4px;

  ${({ $isApplicationKiosk }) =>
    $isApplicationKiosk &&
    css`
      margin-bottom: 16px;
    `}
`;
export const Section = styled.div<{ $isApplicationKiosk: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 40px;
  ${({ $isApplicationKiosk }) =>
    $isApplicationKiosk &&
    css`
      margin-top: 50px;
    `}
`;

export const SectionMainTitle = styled.h2.attrs({ className: 'tp-subtitle2-medium' })`
  text-transform: uppercase;
`;

export const SectionTitle = styled.h2.attrs({ className: 'tp-body2-bold' })``;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  margin: 20px 0;
  background-color: var(--color-secondary-600);
`;

export const StyledBackButton = styled(BackButton)`
  padding: 8px 16px;
`;

export const PrivacyPolicyContainer = styled.div`
  position: relative;
  width: 100%;
  padding: 0;
`;
