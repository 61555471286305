import { Dispatch, FC, Fragment, SetStateAction } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import { IComment, ICommentHandleSubmit } from '@lib/core/comments/types';
import { TProductInstance } from '@lib/core/products/types';
import { TRetailerLocationStoreType } from '@lib/core/retailers/types';
import { IThirdPartyNewsletterTexts } from '@lib/core/service/types/interface';
import { TProductFeedback, TProductFeedbackValue } from '@lib/core/users/slices/productFeedback';
import { feedbackFilter, isProductInstanceInWishlistFilter } from '@lib/core/users/utils/filters';
import { MP_POSITION_CONTEXT } from '@lib/tools/dat/mixpanel/consts';
import { IHandleProductsRequestParams } from '@lib/tools/shared/helpers/interfaces';

import * as S from '@components/web/src/components/Catalog/ProductList/styles';
import ProductCard, { TProductCardVariants } from '@components/web/src/organisms/Cards/ProductCard/ProductCard';
import ThirdPartyNewsletter from '@components/web/src/organisms/ThirdPartyNewsletter/ThirdPartyNewsletter';
import { IDiscoveryQuiz } from '@components/web/src/templates/Modals/DiscoveryQuizModal/DiscoveryQuizModal';

export type ICatalogProps = {
  isProductsRequestLoading: boolean;
  disablePagination?: boolean;
  products: TProductInstance[];
  itemsCount?: number;
  storeType: TRetailerLocationStoreType;
  isEnableLocationMapAddon?: boolean;
  isEnableVusionAddon?: boolean;
  isShowProductLocationAddon?: boolean;
  locale: string;
  retailerName?: string;
  isMatchedProductsAvailable?: boolean;
  shouldHideFeedback: boolean;
  shouldHideComment: boolean;
  shouldHideWishlist: boolean;
  feedbackData?: TProductFeedback[];
  wishlistProductInstanceIds?: string[];
  isWishlistProductListLoading?: boolean;
  discoveryQuiz?: IDiscoveryQuiz;
  variant?: TProductCardVariants;
  isShowThirdPartyAcceptance?: boolean;
  shouldShowDownloadAppCard?: boolean;
  isCommentsLoaded?: boolean;
  commentsList?: IComment[];
  isNewCommentsDataFetching?: boolean;
  thirdPartyNewsletterTexts: IThirdPartyNewsletterTexts;
  handleThirdPartyAcceptance?: (value: boolean) => void;
  handleProductsRequest: (args: IHandleProductsRequestParams) => void;
  handleUpdateWishlistProductList?: (productId: string, productName?: string) => void;
  handleUpdateFeedback?: (feedback: TProductFeedbackValue, productId: string, productName?: string) => void;
  setIsNewCommentsDataFetching?: Dispatch<SetStateAction<boolean>>;
  handleSubmitComment?: ({ isCommentInList, commentFromList, productId, comment }: ICommentHandleSubmit) => void;
};

const CatalogueResult: FC<ICatalogProps> = ({
  disablePagination = false,
  products,
  isProductsRequestLoading = false,
  itemsCount,
  storeType,
  isEnableLocationMapAddon,
  isShowProductLocationAddon,
  isEnableVusionAddon,
  locale,
  retailerName,
  feedbackData = [],
  shouldHideFeedback,
  shouldHideWishlist,
  shouldHideComment,
  wishlistProductInstanceIds = [],
  isWishlistProductListLoading,
  discoveryQuiz,
  variant,
  isMatchedProductsAvailable,
  shouldShowDownloadAppCard,
  isShowThirdPartyAcceptance,
  isCommentsLoaded,
  isNewCommentsDataFetching,
  commentsList,
  thirdPartyNewsletterTexts,
  handleUpdateWishlistProductList,
  handleUpdateFeedback,
  handleProductsRequest,
  handleThirdPartyAcceptance,
  handleSubmitComment,
  setIsNewCommentsDataFetching,
}) => {
  return (
    <S.ProductListContainer>
      <InfiniteScroll
        dataLength={products.length}
        hasMore={!itemsCount || products.length < itemsCount}
        scrollableTarget="root-scroll-element"
        loader={
          isProductsRequestLoading ? (
            <>
              {new Array(6).fill(null).map((_i, index) => (
                <S.ProductCardWrapper key={index} justify="center">
                  <ProductCard
                    isResponsive
                    isLoading={isProductsRequestLoading}
                    locale={locale}
                    storeType={storeType}
                  />
                </S.ProductCardWrapper>
              ))}
            </>
          ) : null
        }
        next={
          (isProductsRequestLoading || disablePagination) && !products.length
            ? null
            : () => handleProductsRequest({ isPagination: true })
        }
      >
        {products?.map((productInstanceData, index) => (
          <Fragment key={productInstanceData.identifier}>
            <S.ProductCardWrapper align="center" direction="column" gap={24} justify="center">
              <ProductCard
                isResponsive
                commentsList={commentsList}
                discoveryQuiz={discoveryQuiz}
                feedback={feedbackFilter(feedbackData, productInstanceData)}
                handleSubmitComment={handleSubmitComment}
                handleUpdateFeedback={handleUpdateFeedback}
                handleUpdateWishlistProductList={handleUpdateWishlistProductList}
                isCommentsLoaded={isCommentsLoaded}
                isEnableLocationMapAddon={isEnableLocationMapAddon}
                isEnableVusionAddon={isEnableVusionAddon}
                isLoading={isProductsRequestLoading}
                isNewCommentsDataFetching={isNewCommentsDataFetching}
                isShowProductLocationAddon={isShowProductLocationAddon}
                isWishlistProductListLoading={isWishlistProductListLoading}
                locale={locale}
                mixpanelIndex={index}
                mixpanelPositionContext={MP_POSITION_CONTEXT.SCROLLABLE_CATALOG}
                productInstanceData={productInstanceData}
                setIsNewCommentsDataFetching={setIsNewCommentsDataFetching}
                shouldHideComment={shouldHideComment}
                shouldHideFeedback={shouldHideFeedback}
                shouldHideWishlist={shouldHideWishlist}
                storeType={storeType}
                variant={variant}
                isProductInstanceInWishlist={isProductInstanceInWishlistFilter(
                  wishlistProductInstanceIds,
                  productInstanceData,
                )}
              />
            </S.ProductCardWrapper>
            {index === 0 && isShowThirdPartyAcceptance && !isMatchedProductsAvailable && (
              <ThirdPartyNewsletter
                handleThirdPartyAcceptance={handleThirdPartyAcceptance}
                retailerName={retailerName}
                thirdPartyNewsletterTexts={thirdPartyNewsletterTexts}
                withMarginTop={shouldShowDownloadAppCard}
              />
            )}
          </Fragment>
        ))}
      </InfiniteScroll>
    </S.ProductListContainer>
  );
};

export default CatalogueResult;
