import { FC } from 'react';

import { STATIC_COLORS } from '@components/web/src/foundations';
import { Text } from '@components/web/src/foundations/Text/Text';
import * as S from '@components/web/src/organisms/TastePathProgress/styles';

export type TJourneyStep = {
  category: string;
  subCategory: {
    id: number | string;
    isActive: boolean;
    isDone: boolean;
  }[];
};

interface IProps {
  steps: TJourneyStep[];
}

export const TastePathProgress: FC<IProps> = ({ steps = [] }) => {
  const stepsQuantity = steps?.length || 0;
  return (
    <S.TastePathProgressContainer
      isFullHeight
      isFullWidth
      $stepsQuantity={stepsQuantity}
      gap={0}
      justify="space-evenly"
    >
      {steps.map(({ category, subCategory }, idx) => (
        <S.StepItemsGroup key={idx} $stepsQuantity={stepsQuantity} direction="column" gap={4}>
          <Text color={STATIC_COLORS.base.black} size="body3" text={category} weight="medium" />
          <S.StepItemsWrapper $stepsQuantity={stepsQuantity}>
            {subCategory.map(({ isActive, isDone }, index) => (
              <S.StepItem key={index} $isActive={isActive} $isDone={isDone} />
            ))}
          </S.StepItemsWrapper>
        </S.StepItemsGroup>
      ))}
    </S.TastePathProgressContainer>
  );
};
