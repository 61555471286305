import { FC } from 'react';

import { localeCommon } from '@lib/tools/locale/source/web/common';

import * as S from '@components/web/src/components/Authentication/Registration/RegistrationNavigation/styles';
import { STATIC_COLORS } from '@components/web/src/foundations';
import { Text } from '@components/web/src/foundations/Text/Text';

interface IProps {
  shouldDisplayCatalogLink: boolean;
  navigateToLoginPage: () => void;
  navigateToCatalog: () => void;
}

const RegistrationNavigation: FC<IProps> = ({ shouldDisplayCatalogLink, navigateToLoginPage, navigateToCatalog }) => {
  const { loginTitle, loginLinkTitle, catalogTitle, catalogLinkTitle } = localeCommon.registerPage;

  return (
    <S.RegistrationNavigation>
      <S.LinkButton key={loginLinkTitle.id} onClick={navigateToLoginPage}>
        <Text color={STATIC_COLORS.base.black} size="body1" text={loginTitle} />
        <S.LinkText size="body1" text={loginLinkTitle} weight="semibold" />
      </S.LinkButton>
      {shouldDisplayCatalogLink && (
        <S.LinkButton key={catalogLinkTitle.id} onClick={navigateToCatalog}>
          <Text color={STATIC_COLORS.base.black} size="body1" text={catalogTitle} />
          <S.LinkText size="body1" text={catalogLinkTitle} weight="semibold" />
        </S.LinkButton>
      )}
    </S.RegistrationNavigation>
  );
};

export default RegistrationNavigation;
