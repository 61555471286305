/* eslint-disable class-methods-use-this */
import mixpanel from 'mixpanel-browser';

import { TCharacter } from '@lib/core/characters/types';
import { SERVICE_TERMS_CATEGORIES } from '@lib/core/serviceTerms/consts';
import { IParsedServiceTerm } from '@lib/core/serviceTerms/types';
import { TProductFeedback } from '@lib/core/users/slices/productFeedback';
import { MP_PROPERTIES } from '@lib/tools/dat/mixpanel/consts';
import { checkIfMixpanelExists, disableOnKiosk, disableOnWidget } from '@lib/tools/dat/mixpanel/decorators';
import Utilities from '@lib/tools/dat/mixpanel/utils';
import { languages } from '@lib/tools/locale/utils/consts';
import {
  PRODUCT_CATEGORY_BEER as BEER,
  PRODUCT_CATEGORY_COFFEE as COFFEE,
  PRODUCT_CATEGORY_WINE as WINE,
} from '@lib/tools/shared/helpers/consts';

/** Methods to manange User identity and properties in Mixpanel. */
@checkIfMixpanelExists()
export default class Profile {
  private utils: Utilities;

  constructor() {
    this.utils = new Utilities();
  }
  // @todo create a method for each different way that a user can update their profile:
  // - ✅ handle identity
  // - ✅ update personal info (email, name, surname, country)
  // - ✅ update characters (when finishing a multi-prod test)
  // - ✅ update privacy options
  // - ✅ update preferences and tags (e.g. ecopackaging, inclusinevess, no-gluten blablablah...)
  // - ❓ probably update article likes, product likes, etc...

  /** Remove personal info from user profile in Mixpanel. */
  private anonymizeUserProfile() {
    mixpanel.people.unset([
      MP_PROPERTIES.email,
      MP_PROPERTIES.firstName,
      MP_PROPERTIES.lastName,
      MP_PROPERTIES.homeCountry,
      MP_PROPERTIES.gender,
      MP_PROPERTIES.birthYear,
    ]);
  }

  /**
   * Invoke when the user identity is verified, that is to say, when the user PK is available in the user slice.
   *
   * It should be invoked when the user authenticates (either through registration or login)
   * as well as when the app loads if the user is already authenticated.
   *
   * Failing to do so will cause other methods that set user properties to fail.
   *
   * @param userID - Primary key of the user
   */
  @disableOnWidget()
  @disableOnKiosk()
  public handleUserIdentity(userID: string | number) {
    if (userID) this.utils.identify(String(userID));
  }

  /**
   * Indicates if the user has been identified by Mixpanel.
   * Identification can happen via signup, login, or fidelity card scan.
   */
  public get didIdentify() {
    return this.utils.didIdentify;
  }

  /**
   * Invoke when the auth user data have been updated
   *
   * @param email - User email
   * @param firstName - User first name
   * @param lastName - User last name
   */
  @disableOnKiosk()
  public updateUserPersonalInfo(email: string, firstName: string, lastName: string) {
    const enabledProfiling = this.utils.getSuperProperty(MP_PROPERTIES.enabledProfiling);
    if (!enabledProfiling) return;
    if (!email) return;

    const args = { email, firstName, lastName };
    this.utils.setUserProfileProperties(args, true);
  }

  /**
   * Invoke when the (anon or auth) user profile data have been updated
   *
   * @param gender - Profile gender slug
   * @param birthYear - Profile birth year
   * @param countryCode - Profile code of the user's country
   */
  @disableOnKiosk()
  public updateUserSociodemographicData(gender: string, birthYear: number, countryCode: string) {
    const regionNameInEnglish = new Intl.DisplayNames([languages.ENGLISH], { type: 'region' });
    const homeCountry = countryCode ? regionNameInEnglish.of(countryCode) : countryCode;

    const args = { birthYear, gender, homeCountry };
    const superArgs = { birthYear, gender };

    this.utils.setUserProfileProperties(args, true);
    if (birthYear || gender) this.utils.setSuperProperties(superArgs, true);
  }

  /**
   * Invoke when the user is authenticated and their characters have been updated.
   *
   * @param characters - Object where keys are the product type (e.g. {@link WINE wine})
   * and values are the {@link TCharacter details} about the respective character
   */
  public updateUserCharacters(characters: Record<string, TCharacter>) {
    if (!Object.keys(characters).length) return;

    const userBeerCharacterID = characters?.[BEER]?.identifier;
    const userCoffeeCharacterID = characters?.[COFFEE]?.identifier;
    const userWineCharacterID = characters?.[WINE]?.identifier;

    const args = { userBeerCharacterID, userCoffeeCharacterID, userWineCharacterID };
    this.utils.setUserProfileProperties(args, true);
    this.utils.setSuperProperties(args, true);
  }

  /**
   * Invoke when the user is anonymous and chooses whether to be profiled or not.
   *
   * @param term - service terms to be tracked
   */
  @disableOnKiosk()
  public updateAnonymousProfilingOption(term: IParsedServiceTerm) {
    const enabledProfiling = term.is_selected;
    if (enabledProfiling !== undefined) {
      this.utils.setSuperProperties({ enabledProfiling }, true);
    }
  }

  /**
   * Invoke when the user is authenticated and their privacy options have been updated.
   *
   * @param serviceTerms - List of objects where each object represents a specific type of service term selection
   * as per {@link IParsedServiceTerm this interface}
   */
  @disableOnKiosk()
  public updateUserPrivacyOptions(terms: IParsedServiceTerm[]) {
    // WARN: privacy logs don't get POSTed for social registration
    if (!terms?.length) return;

    const enabledProfiling =
      terms.find(({ category }) => category === SERVICE_TERMS_CATEGORIES.profiling)?.is_selected || false;
    const enabledMarketing =
      terms.find(({ category }) => category === SERVICE_TERMS_CATEGORIES.marketing)?.is_selected || false;
    // const enabledPartnerVinhood = privacyLogs.find(({ privacy_id }) => privacy_id === PARTNER)?.selection || false;

    const args = { enabledMarketing, enabledProfiling };
    this.utils.setUserProfileProperties(args, true);
    this.utils.setSuperProperties({ enabledProfiling }, true);

    if (!enabledProfiling) this.anonymizeUserProfile();
  }

  /**
   * Invoke when the user is authenticated and their preferences have been updated.
   *
   * @param userFoodPreferences - Food preference slugs
   * @param userTagsActive - Tag preference slugs
   */
  @disableOnKiosk()
  public updateUserPreferences(userFoodPreferences: string[], userTagsActive: string[]) {
    if (!(userFoodPreferences?.length || userTagsActive?.length)) return;

    const args = { userFoodPreferences, userTagsActive };
    this.utils.setUserProfileProperties(args, true);
    this.utils.setSuperProperties(args, true);
  }

  /**
   * Invoke when the user is authenticated and their product wishlist has been updated.
   *
   * @param productList - wishlist products Ids
   */
  public updateUserProductWishlist(wishlistIds: string[]) {
    const args = { userProductWishlist: wishlistIds };
    this.utils.setUserProfileProperties(args, true);
  }

  /**
   * Invoke when the user is authenticated and their feedback list has been updated.
   *
   * @param feedbackData - List of rated products as per {@link TProductFeedback this interface}
   */
  @disableOnKiosk()
  public updateUserProductFeedback(feedbackData: TProductFeedback[]) {
    const getProductIdentifiersForScore = (score: number) => {
      return feedbackData.filter(data => data.feedback === score).map(data => data.product.product.identifier);
    };

    const userProductsScore1 = getProductIdentifiersForScore(1);
    const userProductsScore2 = getProductIdentifiersForScore(2);
    const userProductsScore3 = getProductIdentifiersForScore(3);
    const userProductsScore4 = getProductIdentifiersForScore(4);
    const userProductsScore5 = getProductIdentifiersForScore(5);

    const args = { userProductsScore1, userProductsScore2, userProductsScore3, userProductsScore4, userProductsScore5 };
    this.utils.setUserProfileProperties(args, true);
  }
}
