import { FC } from 'react';

import { useSearchTags } from '@lib/core/service/hooks/useSearchTags';
import { IFilterChangeAction } from '@lib/tools/filterManager/slices/sublistFilterSlice';
import { localeApp } from '@lib/tools/locale/source/web/app';
import { LocaleUtils } from '@lib/tools/locale/utils';
import LocaleFragment, { ILocaleText } from '@lib/tools/locale/views/LocaleFragment';
import { FILTER_TYPE_PRICE_RANGE } from '@lib/tools/shared/helpers/consts';

import { ReactComponent as SearchIcon } from '@components/web/src/assets/legacy/icons/icon_search.svg';
import { ReactComponent as FilterLinesIcon } from '@components/web/src/assets/legacy/icons/icons_filter_lines.svg';
import Badge from '@components/web/src/atoms/Badge/Badge';
import InputField from '@components/web/src/components/Input/InputFields';

export type IExperienceSearchProps = {
  experienceType: ILocaleText;
  inputValue: string;
  searchHandler?: (searchText: string) => void;
  onFilterClick?: () => void;
  handleFilterApply: () => void;
  handleUpdateFilterType: (filterItem: IFilterChangeAction) => void;
  handleResetPriceRange: () => void;
};

const ExperienceSearch: FC<IExperienceSearchProps> = ({
  searchHandler,
  onFilterClick,
  handleFilterApply,
  inputValue,
  experienceType,
  handleResetPriceRange,
  handleUpdateFilterType,
}) => {
  const selectedTags = useSearchTags();

  const { experienceSearch } = localeApp;

  const { publishedTerms } = LocaleUtils;

  const searchText = publishedTerms[experienceSearch.searchText.id];
  const experienceTypePlaceholder =
    typeof experienceType !== 'string' ? publishedTerms[experienceType?.id] : experienceType;

  return (
    <div className="experience-search-wrapper catalogue-filter-section">
      <div className="experience-search-container">
        <div className="search-input-wrapper d-none">
          <InputField
            className="cursor-not-allowed"
            icon={<SearchIcon />}
            name="search-products"
            placeholder={`${searchText} ${experienceTypePlaceholder}`}
            value={inputValue}
            onChange={e => searchHandler(e.target.value.toLowerCase())}
          />
        </div>
        <button className="filter-button cursor-pointer" type="button" onClick={onFilterClick} onKeyDown={() => {}}>
          <FilterLinesIcon />
          <span>
            <LocaleFragment message={experienceSearch.filterButtonText} />
          </span>
        </button>
        {!!selectedTags.length && (
          <div className="applied-filters-container">
            {selectedTags.map(tag => (
              <Badge
                key={tag.name}
                slug={tag.name}
                text={tag.name}
                handleCloseClick={() => {
                  const { filterType } = tag;
                  if (filterType === FILTER_TYPE_PRICE_RANGE) {
                    handleResetPriceRange();
                  }
                  handleUpdateFilterType({ filterItem: tag });
                  handleFilterApply();
                }}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default ExperienceSearch;
