import { FC, Fragment } from 'react';

import { IUpdateDietaryPreferenceParams, TDietaryPreferenceOption } from '@lib/core/users/types';
import { localeApp } from '@lib/tools/locale/source/web/app';

import { ReactComponent as QuestionMarkIcon } from '@components/web/src/assets/legacy/b2c/icons/icon_questionmark.svg';
import BackButton from '@components/web/src/atoms/Buttons/BackButton/BackButton';
import ProfileSectionHeader from '@components/web/src/atoms/Headers/ProfileSectionHeader/ProfileSectionHeader';
import ToggleButton from '@components/web/src/atoms/ToggleButton/ToggleButton';
import { STATIC_COLORS } from '@components/web/src/foundations';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';
import { Text } from '@components/web/src/foundations/Text/Text';
import * as S from '@components/web/src/templates/TasteId/TasteIdTagsPage/styles';

interface IProps {
  toggleTagsOptionsData: (TDietaryPreferenceOption & { isEnabled: boolean })[];
  onTagsInfoClick: () => void;
  handleUpdateToggleDietaryPreferenceOptionsData: ({
    slugToUpdate,
    exposureToUpdate,
  }: IUpdateDietaryPreferenceParams) => void;
  handleBackBtnClick?: () => void;
}

const TasteIdTagsPage: FC<IProps> = ({
  toggleTagsOptionsData,
  handleUpdateToggleDietaryPreferenceOptionsData,
  onTagsInfoClick,
  handleBackBtnClick,
}) => {
  const { headingTitle, headingDescription, subHeadingTitle, subHeadingDescription } = localeApp.tagsTabContainer;

  return (
    <Flexbox direction="column" gap={0}>
      <BackButton handleClick={handleBackBtnClick} />
      <S.TagsTabContainer>
        <ProfileSectionHeader description={headingDescription} title={headingTitle} />
        <Flexbox gap={4} margin="32px 0 16px">
          <Flexbox direction="column" gap={0}>
            <S.TitleText color={STATIC_COLORS.base.black} size="body1" text={subHeadingTitle} weight="semibold" />
            <Text color={STATIC_COLORS.base.black} size="body1" text={subHeadingDescription} />
          </Flexbox>
          <S.Icon onClick={onTagsInfoClick}>
            <QuestionMarkIcon />
          </S.Icon>
        </Flexbox>
        <Flexbox direction="column" gap={20}>
          {toggleTagsOptionsData.map(({ isEnabled, name, slug, exposure }, index) => {
            const shouldDisplayDivider = toggleTagsOptionsData.length === index + 1;
            return (
              <Fragment key={index}>
                {shouldDisplayDivider && <div className="divider" />}
                <ToggleButton
                  isToggled={isEnabled}
                  text={name}
                  onToggle={() =>
                    handleUpdateToggleDietaryPreferenceOptionsData({
                      exposureToUpdate: exposure,
                      slugToUpdate: slug,
                    })
                  }
                />
              </Fragment>
            );
          })}
        </Flexbox>
      </S.TagsTabContainer>
    </Flexbox>
  );
};

export default TasteIdTagsPage;
