import { FC } from 'react';

import { localeCommon } from '@lib/tools/locale/source/web/common';
import { localeWidget } from '@lib/tools/locale/source/web/widget';
import { VH_VARIANTS } from '@lib/tools/shared/helpers/consts';

import errorIcon from '@components/web/src/assets/legacy/b2b/icons/exit_page_error_icon.svg';
import Button from '@components/web/src/atoms/Buttons/Button';
import CloseButton from '@components/web/src/atoms/Buttons/CloseButton/CloseButton';
import { STATIC_COLORS } from '@components/web/src/foundations';
import { Text } from '@components/web/src/foundations/Text/Text';
import * as S from '@components/web/src/templates/Modals/LogoutModal/styles';
import ModalWrapper from '@components/web/src/templates/Modals/ModalWrapper/ModalWrapper';

interface IProps {
  isModalOpen: boolean;
  hideModal: () => void;
  handleResetUserData: () => void;
}

const LogoutModal: FC<IProps> = ({ isModalOpen, hideModal, handleResetUserData }) => {
  const { modalLogoutDeleteDataBtn, modalLogoutDeleteData } = localeWidget.homePage;

  return (
    <ModalWrapper maxContentHeight hideModal={hideModal} isOpen={isModalOpen}>
      <S.LogoutModalContainer>
        <CloseButton handleClick={hideModal} />
        <S.ContentWrapper>
          <S.Icon alt="attention icon" src={errorIcon} />
          <Text color={STATIC_COLORS.base.black} size="subtitle1" text={modalLogoutDeleteData} weight="medium" />
          <Button
            fontSize="subtitle2"
            size="md"
            text={localeCommon.commonMessages.cancelBtn}
            variant={VH_VARIANTS.LIGHT}
            onClick={hideModal}
          />
          <Button
            fontSize="subtitle2"
            size="md"
            text={modalLogoutDeleteDataBtn}
            onClick={() => {
              handleResetUserData();
              hideModal();
            }}
          />
        </S.ContentWrapper>
      </S.LogoutModalContainer>
    </ModalWrapper>
  );
};

export default LogoutModal;
