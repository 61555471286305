import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import {
  ACTION_PERFORMED_URL_PARAM,
  MIXPANEL_EVENT_SLUG_URL_PARAM,
  REDIRECT_URL_PARAM,
} from '@lib/core/service/consts';
import { prependBasename } from '@lib/core/service/utils';
import { useLogging } from '@lib/tools/dat/hooks';
import MixpanelTracker from '@lib/tools/dat/mixpanel';
import { MP_EVENTS, MP_TOKEN, MixpanelActionPerformedContext } from '@lib/tools/dat/mixpanel/consts';
import { FIDELITY_ID_URL_PARAM } from '@lib/tools/shared/helpers/consts';

import LoadingSpinner from '@components/web/src/templates/Loading/LoadingSpinner/LoadingSpinner';

const RedirectContainer = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const { mixPanelState } = useLogging();

  const [isTrackedEvent, setIsTrackedEvent] = useState(!MP_TOKEN);

  const redirectUrl = searchParams.get(REDIRECT_URL_PARAM);
  const mixpanelEventSlug = searchParams.get(MIXPANEL_EVENT_SLUG_URL_PARAM);
  const actionPerformed = searchParams.get(ACTION_PERFORMED_URL_PARAM);
  const fidelityCardId = searchParams.get(FIDELITY_ID_URL_PARAM);

  useEffect(() => {
    if (!isTrackedEvent && mixPanelState) {
      switch (mixpanelEventSlug) {
        case MP_EVENTS.SPECIAL_PROMO_DISPLAYED.SLUG:
          MixpanelTracker.events.specialPromoDisplayed(
            fidelityCardId,
            actionPerformed as MixpanelActionPerformedContext,
          );
          break;
        default:
          break;
      }

      setTimeout(() => setIsTrackedEvent(true), 1000);
    }
  }, [mixPanelState]);

  if (isTrackedEvent) {
    if (redirectUrl.includes('http')) {
      // for Promotion coupon case
      window.location.href = redirectUrl;
    } else {
      navigate(prependBasename(redirectUrl));
    }
  }

  return <LoadingSpinner />;
};

export default RedirectContainer;
