import { FC, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { TLanguage } from '@lib/core/retailers/types';
import { isAppInIframe } from '@lib/core/service/utils';
import { localeApp } from '@lib/tools/locale/source/web/app';
import { localeCommon } from '@lib/tools/locale/source/web/common';
import { useCustomStyles } from '@lib/tools/views/hooks';

import { ReactComponent as HamburgerMenuCloseIcon } from '@components/web/src/assets/icons/hamburger/hamburger-menu-close.svg';
import { ReactComponent as HamburgerMenuOpenIcon } from '@components/web/src/assets/icons/hamburger/hamburger-menu-open.svg';
import { ReactComponent as ExitIcon } from '@components/web/src/assets/icons/hamburger/header-exit-icon.svg';
import { ReactComponent as LanguageIcon } from '@components/web/src/assets/icons/hamburger/header-language-icon.svg';
import { ReactComponent as SettingsIcon } from '@components/web/src/assets/icons/hamburger/header-settings-icon.svg';
import backgroundImage from '@components/web/src/assets/legacy/stairs.jpeg';
import { HeaderDropDownMenu } from '@components/web/src/atoms/Headers/HeaderWidget/HeaderDropDownMenu/HeaderDropDownMenu';
import * as S from '@components/web/src/atoms/Headers/HeaderWidget/styles';
import { Image } from '@components/web/src/foundations/Image/Image';
import VinhoodLogo from '@components/web/src/shared/Logo';
import { ExitModal } from '@components/web/src/templates/Modals/ExitModal/ExitModal';

interface IProps {
  shouldShowHeaderDropDownMenu: boolean;
  producerName?: string;
  isEnableExitNavigation: boolean;
  isEnableSettingsNavigation: boolean;
  isEnableLanguageSwitcher: boolean;
  retailerLanguages: TLanguage[];
  locale: string;
  handleLogoClick: () => void;
  handleExitNavigation: () => void;
  handleSettingPageNavigation: () => void;
  handleSelectLanguage: (selectedLanguageCode: string) => void;
}

export const HeaderWidget: FC<IProps> = ({
  shouldShowHeaderDropDownMenu,
  retailerLanguages,
  locale,
  isEnableExitNavigation,
  isEnableSettingsNavigation,
  isEnableLanguageSwitcher,
  producerName,
  handleLogoClick,
  handleExitNavigation,
  handleSettingPageNavigation,
  handleSelectLanguage,
}) => {
  const { exitPartnerNavigation, switchToLanguage } = localeCommon?.headerDropDownMenu;
  const { settingsTitle } = localeApp?.settings;

  const { pathname } = useLocation();
  const { customHeaderLogo } = useCustomStyles();

  const [isMenuOpened, setIsMenuOpened] = useState(false);
  const [isExitModalOpened, setIsExitModalOpened] = useState(false);

  useEffect(() => {
    setIsMenuOpened(false);
  }, [pathname]);

  const handleSelectLanguageOptionClick = (selectedLanguageCode: string) => {
    setIsMenuOpened(false);
    handleSelectLanguage(selectedLanguageCode);
  };

  const handleExitOptionClick = () => {
    setIsMenuOpened(false);
    setIsExitModalOpened(prevState => !prevState);
  };

  const handleSettingsOptionClick = () => {
    setIsMenuOpened(false);
    handleSettingPageNavigation();
  };

  const menuOptions = useMemo(() => {
    const switcherLanguages = retailerLanguages
      .filter(({ code }) => code !== locale)
      .map(({ code, language }) => ({
        icon: <LanguageIcon />,
        localeIndex: { language },
        localeOptions: { language },
        onClick: () => handleSelectLanguageOptionClick(code),
        text: switchToLanguage,
      }));

    return [
      isEnableSettingsNavigation && {
        icon: <SettingsIcon />,
        onClick: handleSettingsOptionClick,
        text: settingsTitle,
      },
      ...(isEnableLanguageSwitcher ? switcherLanguages : []),
      isEnableExitNavigation && {
        icon: <ExitIcon />,
        localeIndex: { producerName },
        localeOptions: { producerName },
        onClick: handleExitOptionClick,
        text: exitPartnerNavigation,
      },
    ].filter(Boolean);
  }, [
    retailerLanguages,
    locale,
    isEnableSettingsNavigation,
    isEnableLanguageSwitcher,
    isEnableExitNavigation,
    producerName,
  ]);

  const headerLogo = customHeaderLogo ? <Image alt="logo" src={customHeaderLogo} /> : <VinhoodLogo />;

  const menuIcon = isMenuOpened ? <HamburgerMenuCloseIcon /> : <HamburgerMenuOpenIcon />;

  return (
    <S.HeaderWidgetContainer isFullWidth $isAppInIframe={isAppInIframe} $isCenteredLogo={!shouldShowHeaderDropDownMenu}>
      <S.LogoWrapper onClick={handleLogoClick}>{headerLogo}</S.LogoWrapper>
      {shouldShowHeaderDropDownMenu && (
        <>
          <S.MenuIconWrapper align="center" justify="center" onClick={() => setIsMenuOpened(prevState => !prevState)}>
            {menuIcon}
          </S.MenuIconWrapper>

          {isMenuOpened && (
            <HeaderDropDownMenu isMenuOpened={isMenuOpened} menuOptions={menuOptions} producerName={producerName} />
          )}
        </>
      )}

      {isExitModalOpened && (
        <ExitModal
          backgroundImage={backgroundImage}
          hideModal={handleExitOptionClick}
          isModalOpen={isExitModalOpened}
          retailerName={producerName}
          onClose={handleExitOptionClick}
          onHandleExit={handleExitNavigation}
        />
      )}
    </S.HeaderWidgetContainer>
  );
};
