import { FC, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { useServiceInstance } from '@lib/core/service/hooks/useServiceInstance';
import { useTechnical } from '@lib/core/service/hooks/useTechnical';
import { prependBasename } from '@lib/core/service/utils';
import { retailerServiceTermsCategories } from '@lib/core/serviceTerms/consts';
import { useServiceTerms } from '@lib/core/serviceTerms/hooks/useServiceTerms';
import { useUser } from '@lib/core/users/hooks';
import {
  actionLogin,
  actionResetAuthSlice,
  facebookLoginCallback,
  googleLoginCallback,
  resetAuthError,
} from '@lib/core/users/slices/auth';
import RouteUtils from '@lib/tools/routes';
import { useRetailerDesignSet } from '@lib/tools/views/hooks';
import { useTypedDispatch } from '@lib/tools/views/hooks/useTypedDispatch';
import { PAGES } from '@lib/tools/views/urls';

import { LoginFormInputObject } from '@components/web/src/pages/Authentication/AuthFormInterface';
import LoadingSpinner, {
  LOADING_SPINNER_VARIANTS,
} from '@components/web/src/templates/Loading/LoadingSpinner/LoadingSpinner';
import LoginPage from '@components/web/src/templates/Login&Registration/LoginPage/LoginPage';

const LoginContainer: FC = () => {
  const dispatch = useTypedDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const recaptchaRef: any = useRef();
  const [searchParams] = useSearchParams();

  const { isServiceInstanceFeatureEnabled } = useServiceInstance();
  const { isDesignSetVinhoodApp, isDesignSetVinhoodExperience } = useRetailerDesignSet();
  const {
    serviceTermsListUpdateIsLoading,
    serviceTermsByCategory: { [retailerServiceTermsCategories.thirdParty]: thirdParty },
  } = useServiceTerms();
  const { isCaptchaDisabled } = useTechnical();
  const {
    isUserFullUserDataLoadedTemporaryHint,
    isUserDataLoading,
    isUserAuthenticated,
    isProfileLoading,
    authError,
    isAuthDataLoading,
  } = useUser();

  const isFromWidgetHomePage = location.state?.isFromWidgetHomePage;
  const isFromResultPage = location.state?.isFromResultPage;
  const isSocialAuthTesting = searchParams.get('is_social_test') === 'true';
  const isLoading = isUserDataLoading || isAuthDataLoading || isProfileLoading || serviceTermsListUpdateIsLoading;

  const [isEmailAuthorization, setIsEmailAuthorization] = useState(false);
  const [showTimer, setShowTimer] = useState(false);
  const [loginFormData, setLoginFormData] = useState({ email: '', password: '' });

  useEffect(() => {
    if (isUserFullUserDataLoadedTemporaryHint && !isLoading && isUserAuthenticated) {
      if (isServiceInstanceFeatureEnabled) {
        /** Service Instance navigation after form/social authenticating into the account and receiving all data */

        RouteUtils.navigateToWidgetServiceInstance(PAGES.vinhood.catalog);
        return;
      }

      if (isDesignSetVinhoodExperience) {
        navigate(prependBasename(thirdParty?.is_selected ? PAGES.vinhood.home : PAGES.vinhood.catalog));
      }
    }
  }, [isDesignSetVinhoodExperience, isUserFullUserDataLoadedTemporaryHint, isLoading, thirdParty, isUserAuthenticated]);

  useEffect(() => {
    if (authError && !showTimer) {
      setShowTimer(true);
    }

    return () => setShowTimer(false);
  }, [authError]);

  const handleFormSubmit = (data: LoginFormInputObject) => {
    const { email, password } = data;
    // form submitBtn click
    // sets state and executes the recaptcha
    setLoginFormData({ email, password });
    if (recaptchaRef.current) {
      recaptchaRef.current.execute();
    }
  };

  const handleLogin = async (captchaValue: string) => {
    // called after recaptcha successfully executes
    dispatch(actionResetAuthSlice());
    dispatch(
      actionLogin({
        captchaValue,
        email: loginFormData.email,
        isCaptchaDisabled,
        password: loginFormData.password,
      }),
    );
  };

  const handleForgotPasswordClick = () => {
    navigate(prependBasename(PAGES.vinhood.resetPassword), { state: { ...location?.state } });
  };

  const handleGoBack = () => {
    if (isDesignSetVinhoodExperience) {
      navigate(prependBasename(isFromWidgetHomePage ? PAGES.vinhood.home : PAGES.vinhood.registration));
    } else if (isDesignSetVinhoodApp) {
      navigate(prependBasename(isFromResultPage ? PAGES.vinhood.quizResults.result : PAGES.vinhood.start));
    }
  };

  const handleEmailAuthButtonClick = () => {
    setIsEmailAuthorization(state => !state);
    dispatch(resetAuthError());
  };

  return isLoading ? (
    <LoadingSpinner variant={LOADING_SPINNER_VARIANTS.LOGIN} />
  ) : (
    <LoginPage
      errors={authError}
      handleEmailAuthButtonClick={handleEmailAuthButtonClick}
      handleForgotPasswordClick={handleForgotPasswordClick}
      handleFormSubmit={handleFormSubmit}
      handleGoBack={handleGoBack}
      handleLogin={handleLogin}
      isCaptchaDisabled={isCaptchaDisabled}
      isDesignSetVinhoodExperience={isDesignSetVinhoodExperience}
      isEmailAuthorization={isEmailAuthorization}
      isSocialAuthTesting={isSocialAuthTesting}
      recaptchaRef={recaptchaRef}
      responseApple={response => console.info(response)}
      responseFacebook={facebookLoginCallback(dispatch, false)}
      responseGoogle={googleLoginCallback(dispatch, false)}
      setShowTimer={setShowTimer}
      showTimer={showTimer}
    />
  );
};

export default LoginContainer;
