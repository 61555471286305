import { FC } from 'react';

import LocaleFragment, { ILocaleText } from '@lib/tools/locale/views/LocaleFragment';

import CloseButton from '@components/web/src/atoms/Buttons/CloseButton/CloseButton';

interface IProps {
  title: ILocaleText;
  description: ILocaleText;
  handleCloseClick?: () => void;
  className?: string;
}

const Tooltip: FC<IProps> = ({ handleCloseClick, title, description, className }) => {
  return (
    <div className={`tooltip-wrapper ${className}`}>
      <CloseButton handleClick={handleCloseClick} variant="light" />
      <p className="title">
        <LocaleFragment message={title} />
      </p>
      <p className="sub-title">
        <LocaleFragment message={description} />
      </p>
    </div>
  );
};

export default Tooltip;
