import styled from 'styled-components';

import { STATIC_GRADIENTS } from '@components/web/src/foundations';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';
import { Text } from '@components/web/src/foundations/Text/Text';

export const AgePageContainer = styled(Flexbox)`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  flex: 1;
  padding: 16px 16px 30px;
  background-color: var(--color-secondary-100);
`;

export const Wrapper = styled(Flexbox)`
  text-align: center;
`;

export const AgeInput = styled.input`
  all: unset;
  height: 66px;
  margin: 0;
  padding: 0;
  border: none;

  font-family: Fraunces;
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  line-height: 60px;
  letter-spacing: -0.96px;

  background: ${STATIC_GRADIENTS.primaryGradient};
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  &::placeholder {
    opacity: 0.3;
    background: ${STATIC_GRADIENTS.primaryGradient};
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`;

export const ErrorText = styled(Text)`
  min-height: 36px;
`;
