import { FC, ReactNode } from 'react';

import { ReactComponent as CheckIcon } from '@components/web/src/assets/icons/check.svg';
import { ReactComponent as IndeterminateIcon } from '@components/web/src/assets/icons/minus.svg';
import * as S from '@components/web/src/atoms/Checkboxes&Radio/Checkbox/styles';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';
import { Text } from '@components/web/src/foundations/Text/Text';

export interface ICheckbox {
  label?: ReactNode;
  id?: string;
  description?: string;
  text?: string;
  isSelected?: boolean;
  isDisabled?: boolean;
  isRequired?: boolean;
  isIndeterminate?: boolean;
  onChange?: () => void;
}

const Checkbox: FC<ICheckbox> = ({
  id,
  description,
  isSelected,
  isDisabled = false,
  isRequired = false,
  isIndeterminate = false,
  onChange,
  label,
  text,
}) => {
  return (
    <S.CheckboxContainer isFullWidth align="flex-start" gap={12} justify="flex-start">
      <S.HiddenCheckbox checked={isSelected} disabled={isDisabled} id={id} required={isRequired} onChange={onChange} />
      <S.StyledCheckbox
        $isChecked={isSelected}
        $isDisabled={isDisabled}
        align="center"
        justify="center"
        onClick={() => !isDisabled && onChange()}
      >
        {isSelected && !isIndeterminate && (
          <S.Icon>
            <CheckIcon />
          </S.Icon>
        )}
        {isIndeterminate && (
          <S.Icon>
            <IndeterminateIcon />
          </S.Icon>
        )}
      </S.StyledCheckbox>
      <S.CheckboxLabel $isDisabled={isDisabled} className="text" htmlFor={id}>
        <Flexbox direction="column" gap={4}>
          {text ? <Text size="body1" text={text} weight="medium" /> : label}
          {!!description && <Text size="body1" text={description} />}
        </Flexbox>
      </S.CheckboxLabel>
    </S.CheckboxContainer>
  );
};

export default Checkbox;
