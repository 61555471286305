import { styled } from 'styled-components';

import { STATIC_SHADOWS } from '@components/web/src/foundations';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';

export const HeaderFooterWidgetContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: -webkit-fill-available;
  height: 100%;
  z-index: 1;
  background-color: var(--color-secondary-100);
  box-shadow: ${STATIC_SHADOWS.xl};
  border-radius: 8px;

  &::-webkit-scrollbar {
    width: 0;
  }
`;

export const BodyContainer = styled(Flexbox)<{ $isFixedBody: boolean }>`
  flex: 1;
  width: 100%;
  overflow: hidden;
  overflow-y: auto;
  scrollbar-width: none;

  ${({ $isFixedBody }) =>
    $isFixedBody &&
    `overflow-y: hidden;
  }`}
`;

export const FooterWidgetAnonymousWrapper = styled.div`
  margin-top: auto;
`;

export const FooterContent = styled(Flexbox)`
  margin: -2px 0;
  background-color: var(--color-primary-800);
`;
