import styled, { css } from 'styled-components';

import BackButton from '@components/web/src/atoms/Buttons/BackButton/BackButton';
import { Text } from '@components/web/src/foundations/Text/Text';

export const TermsConditionsWrapper = styled.div<{ $isApplicationKiosk: boolean }>`
  position: relative;
  width: 100%;
  padding: 16px 16px 20px;
  color: var(--vh-black);

  p,
  li,
  ul {
    padding: 0;
    margin: 0;
  }

  ul {
    padding-left: 15px;
    list-style-type: disc;
  }

  ${({ $isApplicationKiosk }) =>
    $isApplicationKiosk &&
    `
    padding: 32px 32px 92px;
  `}
`;

export const MainTitle = styled.p<{ $isApplicationKiosk: boolean }>`
  margin-bottom: 4px;

  ${({ $isApplicationKiosk }) =>
    $isApplicationKiosk &&
    css`
      margin-bottom: 16px;
    `}
`;

export const Section = styled.div<{ $isApplicationKiosk: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 40px;
  ${({ $isApplicationKiosk }) =>
    $isApplicationKiosk &&
    css`
      margin-top: 50px;
    `}
`;

export const StyledText = styled(Text)`
  margin-right: 4px;
`;

export const SectionTitle = styled.div`
  font-weight: bold;
`;

export const Order = styled.span.attrs({ className: 'order' })`
  font-weight: bold;
`;

export const PreviousCta = styled.div`
  justify-content: left;
  padding: 8px 0 16px;
  margin-top: 30px;
`;

export const StyledBackButton = styled(BackButton)`
  padding: 8px 16px;
`;

export const TermsConditionsContainer = styled.div`
  position: relative;
  width: 100%;
  padding: 0;
`;
