import { FC, useMemo } from 'react';
import { FieldErrors } from 'react-hook-form';
import Select, { StylesConfig, components } from 'react-select';

import { TProfileCountryOption, TProfileGenderOption } from '@lib/core/users/types';
import { ILocaleText } from '@lib/tools/locale/views/LocaleFragment';

import { STATIC_COLORS, STATIC_SHADOWS } from '@components/web/src/foundations';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';
import { Image } from '@components/web/src/foundations/Image/Image';
import { Text } from '@components/web/src/foundations/Text/Text';
import * as S from '@components/web/src/templates/TasteId/TasteIdSettingsPage/SelectionInput/styles';

const { SingleValue, Option } = components;

interface IOption extends Partial<TProfileCountryOption>, Partial<TProfileGenderOption> {}

interface IProps {
  labelTitle: ILocaleText;
  name: string;
  errors?: FieldErrors;
  value: string;
  options: IOption[];
  isSearchable: boolean;
  placeholderText: string;
  onChange: (value: string) => void;
}

const customStyles: StylesConfig<IOption, false> = {
  container: defaultStyles => ({
    ...defaultStyles,
    border: `1px solid ${STATIC_COLORS.gray[300]}`,
    borderRadius: '8px',
    boxShadow: STATIC_SHADOWS.xs,
    height: '44px',
  }),
  control: defaultStyles => ({
    ...defaultStyles,
    border: 'none',
    borderRadius: 8,
    height: '100%',
    width: '100%',
  }),
  input: defaultStyles => ({
    ...defaultStyles,
    margin: 0,
    padding: 0,
    paddingLeft: 16,
  }),
  menu: defaultStyles => ({
    ...defaultStyles,
    borderBottomLeftRadius: 12,
    borderBottomRightRadius: 12,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    marginBottom: 0,
    marginTop: 1,
    overflow: 'hidden',
    paddingTop: 4,
  }),
  option: (defaultStyles, props) => {
    return {
      ...defaultStyles,
      backgroundColor: props.isSelected ? STATIC_COLORS.green[600] : STATIC_COLORS.base.white,
      borderBottom: `1px solid ${STATIC_COLORS.gray[200]}`,
      color: props.isSelected ? STATIC_COLORS.base.white : STATIC_COLORS.base.black,
      margin: 0,
      paddingBottom: 16,
      paddingLeft: 0,
      paddingTop: 16,
    };
  },
  placeholder: defaultStyles => ({
    ...defaultStyles,
    color: STATIC_COLORS.gray[500],
    fontFamily: 'Montserrat, sans-serif',
    marginLeft: 16,
  }),
  singleValue: defaultStyles => ({
    ...defaultStyles,
    margin: 0,
  }),
  valueContainer: defaultStyles => ({
    ...defaultStyles,
    padding: 0,
  }),
};

const Item = (props, Component) => {
  const { data } = props;
  const icon = data?.flag || '';
  const label = data?.name;

  return (
    <Component {...props} className="item">
      <Flexbox align="center" gap={8} padding="0 0 0 16px">
        {icon && <Image alt="" borderRadius="50%" height="20px" src={icon} width="20px" />}
        <Text size="subtitle2" text={label} />
      </Flexbox>
    </Component>
  );
};

const ItemSingleValue = props => Item(props, SingleValue);
const ItemOption = props => Item(props, Option);

const SelectionInput: FC<IProps> = ({
  errors,
  name,
  value,
  labelTitle,
  options,
  isSearchable,
  placeholderText,
  onChange,
}) => {
  const error = !!errors && !!errors[name] && errors[name].message;
  const getOptionValue = option => option.code || option.slug;
  const getOptionLabel = option => option.name;

  const selectedValue = useMemo(
    () => options?.find(current => current.code === value || current.slug === value),
    [options, value],
  );

  return (
    <S.SelectionContainer $isInvalid={!!error} htmlFor="country">
      <S.TitleText color={STATIC_COLORS.gray[700]} size="body1" text={labelTitle} weight="medium" />
      <Select
        components={{ IndicatorSeparator: null, Option: ItemOption, SingleValue: ItemSingleValue }}
        getOptionLabel={getOptionLabel}
        getOptionValue={getOptionValue}
        isSearchable={isSearchable}
        options={options}
        placeholder={placeholderText}
        styles={customStyles}
        value={selectedValue}
        onChange={selectedItem => onChange(selectedItem.slug || selectedItem.code)}
      />
      <S.InfoText $isError={!!error}>
        <Text size="body1" text={JSON.stringify(error)} weight="medium" />
      </S.InfoText>
    </S.SelectionContainer>
  );
};

export default SelectionInput;
