import { FC } from 'react';

import { ILocaleText } from '@lib/tools/locale/views/LocaleFragment';

import { STATIC_COLORS } from '@components/web/src/foundations';
import { Image } from '@components/web/src/foundations/Image/Image';
import { Text } from '@components/web/src/foundations/Text/Text';
import * as S from '@components/web/src/organisms/Cards/PreparationCard/styled';

export type IPreparationItem = {
  title: ILocaleText;
  subtitle: ILocaleText;
  description: ILocaleText;
  image: string;
};

const PreparationCard: FC<IPreparationItem> = ({ image, title, subtitle, description }) => {
  return (
    <S.PreparationCardContainer>
      <Image alt={`preparation_${title}`} height="120px" objectFit="cover" src={image} width="100%" />
      <S.ContentContainer>
        <Text color={STATIC_COLORS.gray[500]} size="body3" text={subtitle} />
        <br />
        <Text size="body1" text={title} weight="semibold" />
        <br />
        <Text size="body2" text={description} />
      </S.ContentContainer>
    </S.PreparationCardContainer>
  );
};

export default PreparationCard;
