import { FC, HTMLAttributes } from 'react';

import { localeApp } from '@lib/tools/locale/source/web/app';

import loadingGIF from '@components/web/src/assets/gif/loading.gif';
import { Text } from '@components/web/src/foundations/Text/Text';
import * as S from '@components/web/src/templates/Loading/LoadingSpinner/styles';

export const LOADING_SPINNER_VARIANTS = {
  FULL_TEXT: 'fullText',
  LOGIN: 'login',
  SPINNER: 'spinner',
  TRANSPARENT: 'transparent',
} as const;

export type LoadingSpinnerVariant = (typeof LOADING_SPINNER_VARIANTS)[keyof typeof LOADING_SPINNER_VARIANTS];

interface IProps extends HTMLAttributes<HTMLDivElement> {
  variant?: LoadingSpinnerVariant;
}

const LoadingSpinner: FC<IProps> = ({ variant = LOADING_SPINNER_VARIANTS.SPINNER, ...rest }) => {
  const { textOne, textTwo } = localeApp.loadingScreen;
  const isWithNoText = variant === LOADING_SPINNER_VARIANTS.SPINNER || variant === LOADING_SPINNER_VARIANTS.TRANSPARENT;

  return (
    <S.LoadingSpinnerContainer $isTransparent={variant === LOADING_SPINNER_VARIANTS.TRANSPARENT} {...rest}>
      <img alt="loading" src={loadingGIF} />
      {!isWithNoText && (
        <S.TextContainer>
          <Text size="subtitle1" text={textOne} />
          {variant === LOADING_SPINNER_VARIANTS.FULL_TEXT && <Text size="subtitle1" text={textTwo} weight="semibold" />}
        </S.TextContainer>
      )}
    </S.LoadingSpinnerContainer>
  );
};

export default LoadingSpinner;
