import styled from 'styled-components';

import { Text } from '@components/web/src/foundations/Text/Text';

export const GenderPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
  padding: 16px 16px 30px;
  background-color: var(--color-secondary-100);
`;

export const Title = styled(Text)`
  text-align: center;
  margin-bottom: 24px;
`;

export const GendersWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px 16px;
`;

export const CtaWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 36px 0 28px;
`;
