import { FC, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { TProductCategory } from '@lib/core/products/types';
import { getMultipleUniqueRandomItemsFromArray, prependBasename } from '@lib/core/service/utils';
import { ILocation, IUpdateLocationListParams } from '@lib/core/users/types';
import MixpanelTracker from '@lib/tools/dat/mixpanel';
import { MixpanelPositionContext } from '@lib/tools/dat/mixpanel/consts';
import { localeWidget } from '@lib/tools/locale/source/web/widget';
import { RETAILER_LOCATION_ID_URL_PARAM, RETAILER_SLUG_URL_PARAM } from '@lib/tools/shared/helpers/consts';
import { PAGES } from '@lib/tools/views/urls';

import Wishlist from '@components/web/src/atoms/Wishlist/Wishlist';
import { STATIC_COLORS } from '@components/web/src/foundations';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';
import { Text } from '@components/web/src/foundations/Text/Text';
import * as S from '@components/web/src/organisms/Cards/PlacesCard/styles';

interface IProps {
  productCategory: TProductCategory;
  locationData: ILocation;
  isLocationInWishlist: boolean;
  isLocationListLoading: boolean;
  isCurrentLocation?: boolean;
  locationPositionIndex: number;
  locationPositionContext: MixpanelPositionContext;
  isResponsive?: boolean;
  handleUpdateLocationList: ({ retailerLocationSlug }: IUpdateLocationListParams) => void;
}

export const PlacesCard: FC<IProps> = ({
  productCategory,
  locationData,
  isLocationInWishlist,
  isLocationListLoading,
  isCurrentLocation,
  isResponsive = false,
  locationPositionIndex,
  locationPositionContext,
  handleUpdateLocationList,
}) => {
  const navigate = useNavigate();

  const { placeCardSubtitle } = localeWidget.explorePage;

  const {
    images = [],
    identifier = '',
    slug = '',
    name = '',
    producer,
    physical_address: address = '',
    retailer,
  } = locationData?.retailer_location;
  const { image: logo = '', name: locationName = '' } = producer || {};

  const randomLocationImage = useMemo(() => getMultipleUniqueRandomItemsFromArray(images, 1)[0], [images]);

  const handleCardClick = () =>
    navigate(
      prependBasename(PAGES.vinhood.explore, {
        [RETAILER_LOCATION_ID_URL_PARAM]: identifier,
        [RETAILER_SLUG_URL_PARAM]: retailer,
      }),
    );

  return (
    <S.PlacesCardContainer $isResponsive={isResponsive} onClick={() => handleCardClick()}>
      <S.ImageWrapper>
        <S.Overlay>
          <img alt="card" src={randomLocationImage} />
        </S.Overlay>
        <S.WishlistWrapper>
          <Wishlist
            isItemInWishlist={isLocationInWishlist}
            isItemListLoading={isLocationListLoading}
            variant="light"
            handleUpdateWishlist={() => {
              MixpanelTracker.events.locationBookmark({
                listName: '',
                locationBookmarked: isLocationInWishlist,
                locationId: identifier,
                locationName,
                locationPosition: locationPositionContext,
                locationPositionIndex,
                locationSlug: slug,
              });

              handleUpdateLocationList({ retailerLocationSlug: slug });
            }}
          />
        </S.WishlistWrapper>
      </S.ImageWrapper>
      <S.ContentWrapper>
        {logo && <img alt="logo" src={logo} />}
        <Text color={STATIC_COLORS.base.black} size="subtitle1" text={name} weight="semibold" />
        {isCurrentLocation && (
          <Text
            color={STATIC_COLORS.productColors[productCategory][500] || STATIC_COLORS.base.black}
            size="subtitle2"
            text={placeCardSubtitle}
            weight="semibold"
          />
        )}
        <Flexbox direction="column" gap={0}>
          <Text color={STATIC_COLORS.base.black} size="body1" text={address} />
          {false && <Text color={STATIC_COLORS.base.black} size="body1" text="... km" />}
        </Flexbox>
      </S.ContentWrapper>
    </S.PlacesCardContainer>
  );
};
