import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { TProductInstance } from '@lib/core/products/types';
import { getLimitedProductsListData, getProductsListData } from '@lib/core/products/utils';
import { useRetailer, useRetailerLocation } from '@lib/core/retailers/hooks';
import { ASSOCIATED_RETAILER_SLUG_QUERY, GPRL_CHARACTER_QUERY } from '@lib/core/service/consts';
import { useApp } from '@lib/core/service/hooks';
import { useThirdParty } from '@lib/core/service/hooks/useThirdParty';
import { setShowTastePathStartPage } from '@lib/core/service/slices';
import { prependBasename } from '@lib/core/service/utils';
import { useProductFeedback, useUser } from '@lib/core/users/hooks';
import { setStaticMatchedProducts } from '@lib/tools/productCatalog/slices';
import { LIMITED_CATALOG, PRODUCT_CATEGORY_QUERY } from '@lib/tools/shared/helpers/consts';
import { useAddons } from '@lib/tools/views/hooks';
import { PAGES } from '@lib/tools/views/urls';

import LoadingSpinner from '@components/web/src/templates/Loading/LoadingSpinner/LoadingSpinner';
import { ThirdPartyConsentPage } from '@components/web/src/templates/ThirdPartyConsentPage/ThirdPartyConsentPage';

import useTasteIdData from 'hooks/useTasteIdData';

export const ThirdPartyConsentContainer = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { productCategory } = useApp();
  const { isEnableTastePathAddon } = useAddons();
  const { userCharactersForServiceProductCategoryArray, isUserDataLoading, isUserCharactersLoading } = useUser();
  const { associatedRetailerLocation, retailerLocationCatalogType, isRetailerLocationLoading } = useRetailerLocation();
  const { retailerName, isRetailerLoading } = useRetailer();
  const { isFeedbackLoading } = useProductFeedback();
  const { userBestMatchCharacterByProductCategory } = useTasteIdData();
  const {
    thirdPartyData,
    isShowThirdPartyAcceptance,
    isTermsInitialDataLoading,
    serviceTermsListUpdateIsLoading,
    handleThirdPartyAcceptance,
  } = useThirdParty();

  const characterName = userBestMatchCharacterByProductCategory?.[productCategory]?.name;

  const [productInstanceData, setProductInstanceData] = useState<TProductInstance>(undefined);
  const [isProductInstanceDataLoading, setIsProductInstanceDataLoading] = useState(false);

  useEffect(() => {
    const fetchProductInstanceData = async (retryIndex = 1) => {
      setIsProductInstanceDataLoading(true);

      const isLimitedCatalogType = retailerLocationCatalogType === LIMITED_CATALOG;

      let productInstanceDataResponse: TProductInstance = null;

      try {
        const userCharacterId = userCharactersForServiceProductCategoryArray.at(-retryIndex)?.identifier;

        const params = {
          [GPRL_CHARACTER_QUERY]: userCharacterId,
          [PRODUCT_CATEGORY_QUERY]: productCategory,
          ...(isLimitedCatalogType && { [ASSOCIATED_RETAILER_SLUG_QUERY]: associatedRetailerLocation?.slug }),
          limit: 1,
          offset: 0,
        };

        if (isLimitedCatalogType) {
          const res = await getLimitedProductsListData(params);
          productInstanceDataResponse =
            res?.first_priority?.[0] || res?.second_priority?.[0] || res?.third_priority?.[0];
        } else {
          const res = await getProductsListData(params);
          productInstanceDataResponse = res.results?.[0];
        }

        if (!productInstanceDataResponse && retryIndex < userCharactersForServiceProductCategoryArray.length) {
          return await fetchProductInstanceData(retryIndex + 1);
        }

        setProductInstanceData(productInstanceDataResponse);
        dispatch(setStaticMatchedProducts([productInstanceDataResponse]));
        return productInstanceDataResponse;
      } finally {
        setIsProductInstanceDataLoading(false);
      }
    };

    if (!isProductInstanceDataLoading && !productInstanceData && !isEnableTastePathAddon) {
      fetchProductInstanceData();
    }

    if (isEnableTastePathAddon) dispatch(setShowTastePathStartPage(false));
  }, []);

  const navigateToCatalog = () => navigate(prependBasename(PAGES.vinhood.catalog));

  const handleThirdPartyClick = (isSelected: boolean) => {
    handleThirdPartyAcceptance(isSelected);
    navigateToCatalog();
  };

  const isLoading =
    isProductInstanceDataLoading ||
    isUserDataLoading ||
    isRetailerLocationLoading ||
    isRetailerLoading ||
    isTermsInitialDataLoading ||
    serviceTermsListUpdateIsLoading ||
    isFeedbackLoading ||
    isUserCharactersLoading;

  return isLoading ? (
    <LoadingSpinner variant="spinner" />
  ) : (
    <ThirdPartyConsentPage
      characterName={characterName}
      handleThirdPartyClick={handleThirdPartyClick}
      isShowThirdPartyAcceptance={isShowThirdPartyAcceptance}
      navigateToCatalog={navigateToCatalog}
      productCategory={productCategory}
      productInstanceData={productInstanceData}
      retailerName={retailerName}
      thirdPartyData={thirdPartyData}
      variant={isEnableTastePathAddon ? 'tastePath' : 'default'}
    />
  );
};
