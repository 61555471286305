import styled, { keyframes } from 'styled-components';

import Button from '@components/web/src/atoms/Buttons/Button';
import { STATIC_COLORS, STATIC_GRADIENTS } from '@components/web/src/foundations';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';

const scaleAnimation = keyframes`
  from {
    transform: scale(0.8);
  }
  to {
    transform: scale(1);
  }
`;

export const ThirdPartyConsentPageContainer = styled(Flexbox)`
  background: ${STATIC_GRADIENTS.primaryGradient};
`;

export const ContentWrapper = styled(Flexbox)`
  span {
    text-align: center;
  }

  & > div {
    position: fixed;
    max-width: 500px;
  }

  .arrow {
    width: 24px;
    height: 24px;
  }
`;

export const ProductImageWrapper = styled(Flexbox)`
  width: 210px;
  height: 251px;
  background: ${STATIC_COLORS.base.white};
  border-radius: 15px;
  animation: ${scaleAnimation} 0.5s ease-out;

  img {
    height: 95%;
  }
`;

export const ThirdPartyConnector = styled(Flexbox)<{ $marginTop: number }>`
  flex: 1;
  z-index: 1;
  margin-top: ${({ $marginTop }) => `${$marginTop || 0}px`};
  border-radius: 15px 15px 0px 0px;
  background: ${STATIC_COLORS.base.white};
  box-shadow: 0px -4px 12px 0px rgba(0, 0, 0, 0.25);

  span {
    text-align: center;
  }
`;

export const StyledLinkButton = styled(Button)`
  width: 100%;
  padding: 10px 14px;
  border-radius: 0;
  span {
    color: ${STATIC_COLORS.base.black};
  }
`;

export const ContinueButtonWrapper = styled.div`
  margin-top: auto;
  padding: 8px 16px;
  background: ${STATIC_COLORS.base.white};
`;

export const Divider = styled.div`
  border-right: 1px solid ${STATIC_COLORS.warmGray['600']};
  height: 100%;
  width: 1px;
`;
