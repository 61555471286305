import { localeApp } from '@lib/tools/locale/source/web/app';
import { PAGES } from '@lib/tools/views/urls';

import BackButton from '@components/web/src/atoms/Buttons/BackButton/BackButton';
import ProfileSectionHeader from '@components/web/src/atoms/Headers/ProfileSectionHeader/ProfileSectionHeader';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';
import LegalCard from '@components/web/src/organisms/Cards/LegalCard/LegalCard';

const TasteIdLegalPage = ({ handleLegalCardBtnClick, handleBackBtnClick }) => {
  const {
    legalDescription,
    legalTitle,
    permissionCardButtonText,
    permissionCardDescriptionText,
    permissionCardTitle,
    privacyCardButtonText,
    privacyCardDescriptionText,
    privacyCardTitle,
    cookieCardButtonText,
    cookieCardDescriptionText,
    cookieCardTitle,
    conditionsCardButtonText,
    conditionsCardDescriptionText,
    conditionsCardTitle,
  } = localeApp.legalComponent;

  const legalCardsProps = [
    {
      buttonText: permissionCardButtonText,
      cardDescription: permissionCardDescriptionText,
      cardTitle: permissionCardTitle,
      onBtnClickHandler: () => handleLegalCardBtnClick(PAGES.vinhood.legal.generalServiceTerms),
    },
    {
      buttonText: privacyCardButtonText,
      cardDescription: privacyCardDescriptionText,
      cardTitle: privacyCardTitle,
      onBtnClickHandler: () => handleLegalCardBtnClick(PAGES.vinhood.legal.privacy),
    },
    {
      buttonText: cookieCardButtonText,
      cardDescription: cookieCardDescriptionText,
      cardTitle: cookieCardTitle,
      onBtnClickHandler: () => handleLegalCardBtnClick(PAGES.vinhood.legal.cookies),
    },
    {
      buttonText: conditionsCardButtonText,
      cardDescription: conditionsCardDescriptionText,
      cardTitle: conditionsCardTitle,
      onBtnClickHandler: () => handleLegalCardBtnClick(PAGES.vinhood.legal.termsConditions),
    },
  ];

  return (
    <Flexbox direction="column" gap={0}>
      <BackButton handleClick={handleBackBtnClick} />
      <Flexbox isFullWidth direction="column" gap={16} padding="0 16px 32px">
        <ProfileSectionHeader description={legalDescription} title={legalTitle} />
        <Flexbox direction="column" gap={16}>
          {legalCardsProps.map((cardData, idx) => (
            <LegalCard {...cardData} key={idx} />
          ))}
        </Flexbox>
      </Flexbox>
    </Flexbox>
  );
};

export default TasteIdLegalPage;
