/* eslint-disable class-methods-use-this */
/* eslint-disable no-dupe-class-members */
import mixpanel from 'mixpanel-browser';

import { IB2CExperience } from '@app/native/src/interfaces/experience';

import { TCharacter } from '@lib/core/characters/types';
import { TProduct, TProductCategory, TProductInstance } from '@lib/core/products/types';
import { TQuizType } from '@lib/core/quizzes/types';
import { TUserQuizDetail } from '@lib/core/quizzes/types/userQuiz';
import { EXPERIENCES_TYPES } from '@lib/core/service/consts';
import { TProductFeedbackValue } from '@lib/core/users/slices/productFeedback';
import { MixpanelExtras } from '@lib/tools/dat/mixpanel';
import { MP_EVENTS, MixpanelActionPerformedContext, MixpanelPositionContext } from '@lib/tools/dat/mixpanel/consts';
import {
  checkIfMixpanelExists,
  disableOnApp,
  disableOnKiosk,
  disableOnWidget,
  trackFacebookPixelEvent,
} from '@lib/tools/dat/mixpanel/decorators';
import {
  TMPBannerClick,
  TMPCharacterDescriptionClick,
  TMPContactTheProductProducer,
  TMPDiscoverMorePairingsClick,
  TMPDiscoverQuizSubmit,
  TMPEntryPoint,
  TMPExperienceCategoryCatalogView,
  TMPExploreSectionView,
  TMPFindMe,
  TMPFooterNavigationClick,
  TMPLocationBookmark,
  TMPLocationTasteMatchClick,
  TMPProductBookmark,
  TMPProductCatalogFilter,
  TMPProductCatalogView,
  TMPProductClick,
  TMPProductData,
  TMPProductDataProps,
  TMPProductFeedback,
  TMPProductStory,
  TMPPromotionDescriptionClick,
  TMPRecipeClick,
  TMPResultPageView,
  TMPScanFailed,
  TMPScanProduct,
  TMPSeeSimilarProducts,
  TMPSkipCharacterType,
  TMPSpecialPromoDisplayed,
  TMPTasteMatchClick,
  TMPTestCompleted,
  TMPTestStarted,
  TMPViewProducerExperiencePage,
} from '@lib/tools/dat/mixpanel/types';
import Utilities from '@lib/tools/dat/mixpanel/utils';
import { IProductFilterState } from '@lib/tools/filterManager/slices/productFilter';
import { languages } from '@lib/tools/locale/utils/consts';
import RouteUtils from '@lib/tools/routes';
import {
  DIETARY_PREFERENCE_EXPOSURE,
  FILTER_TYPE_CHARACTERISTICS,
  FILTER_TYPE_ORIGIN,
  FILTER_TYPE_STYLE,
  PRODUCT_CATEGORY_WINE,
} from '@lib/tools/shared/helpers/consts';

import { IBannerLink, IBannerPosition } from '@components/web/src/atoms/Banner/BannerCard';
import { IB2CRecipe } from '@components/web/src/components/Recipe/RecipeItem/RecipeItem';

// NOTE: for some reason, tracking event for actions that open an external website
// (such as product click on widget) get triggered twice. (only the event, not the whole method).
// However it's not a big deal because Mixpanel servers recognize it as being
// duplicate and automatically dedupe it.

/** Mixpanel tracking events. */
@checkIfMixpanelExists()
export default class Events {
  private utils: Utilities;

  constructor() {
    this.utils = new Utilities();
  }

  /**
   * This method triggers when the solution loads.
   * It's used to check where user land when coming from mktg campaigns and to allow Mixpanel to track UTM params.
   */
  @disableOnKiosk()
  public entryPoint() {
    const page = RouteUtils.getPage();
    const entryPage = page.split('?')[0];
    const args: TMPEntryPoint = { entryPage };

    this.utils.track(MP_EVENTS.ENTRY_POINT, args);
  }

  // ###########################################################################
  // #                                                                         #
  // #                      Start page and identification                      #
  // #                                                                         #
  // ###########################################################################

  /**
   * Invoke this event when the Start Page is loaded.
   */
  @trackFacebookPixelEvent(MP_EVENTS.START_PAGE_VIEW)
  public startPageView() {
    this.utils.track(MP_EVENTS.START_PAGE_VIEW);
  }

  /**
   * Invoke this event when the user clicks "Enter" in the Start Page.
   */
  public startPageEnterClick() {
    this.utils.track(MP_EVENTS.START_PAGE_ENTER_CLICK);
  }

  /**
   * Invoke this event when the user successfully completes the registration process.
   *
   * @beta
   */
  @trackFacebookPixelEvent(MP_EVENTS.USER_REGISTRATION_COMPLETED)
  public userRegistrationCompleted() {
    // WARN: currently only implemented for email registration
    const registrationDate = this.utils.getDateTime();

    this.utils.track(MP_EVENTS.USER_REGISTRATION_COMPLETED);
    this.utils.setUserProfileProperties({ registrationDate }, false);
  }

  /**
   * Invoke this event when the user successfully logs in and an account already exists.
   *
   * @beta
   */
  public userLogin(isSocial: boolean, isRegister: boolean) {
    // NOTE: probably merge login and registration if necessary and use props to
    // distinguish between the two.
    if (isSocial && isRegister) {
      // pass: placeholder for when is_register_new_social_user works
    }
    const lastLogin = this.utils.getDateTime();

    this.utils.track(MP_EVENTS.LOGIN);
    this.utils.setUserProfileProperties({ lastLogin }, true);
  }

  /**
   * Invoke this event when the user logs out, either explicitly or implicitly
   * (e.g. due to clearing local storage).
   *
   * Sends a "Sign Out" event to Mixpanel event stream, then clears all Mixpanel
   * super properties, generates a new random distinct_id for this instance and sets
   * Retailer super properties back again.
   */
  public signOut() {
    // NOTE: this is kind of a hack.
    // The purpose is to prevent invoking the method `mixpanel.reset()` when a user is not authenticated,
    // which would incorrectly create many anonymous profiles in the Mixpanel platform.
    const vinhoodUserPkPattern = /^[0-9]+$/;
    if (vinhoodUserPkPattern.test(mixpanel.get_distinct_id())) {
      this.utils.track(MP_EVENTS.SIGN_OUT);
      this.utils.reset();
      MixpanelExtras.initializeSuperProperties();
    }
  }

  /**
   * Invoke this event when the user clicks "Play as anonymous" CTA in the kiosk.
   */
  public playAsAnonymous() {
    this.utils.track(MP_EVENTS.PLAY_AS_ANONYMOUS);
  }

  /**
   * Invoke this event when the user scans their retailer fidelity card in a physical kiosk.
   *
   * @param fidelityCardID - The ID of the fidelity card
   */
  @disableOnWidget()
  @disableOnApp()
  public scanFidelityCard(fidelityCardID: string, retailerSlug: string) {
    const combinedFidelityCardId = `${retailerSlug}_${fidelityCardID}`;

    if (!combinedFidelityCardId) return;
    if (this.utils.didIdentify) {
      MixpanelExtras.initializeSuperProperties();
    }

    this.utils.identify(combinedFidelityCardId);
    this.utils.setUserProfileProperties({ fidelityCardID: combinedFidelityCardId }, false);
    this.utils.track(MP_EVENTS.SCAN_FIDELITY_CARD, { fidelityCardID: combinedFidelityCardId });
  }

  /**
   * Invoke this event when the user manually disconnects their fidelity card from a physical kiosk.
   */
  @disableOnWidget()
  @disableOnApp()
  public detachFidelityCard() {
    this.utils.track(MP_EVENTS.DETACH_FIDELITY_CARD);
    MixpanelExtras.initializeSuperProperties();
  }

  // ###########################################################################
  // #                                                                         #
  // #                                Products                                 #
  // #                                                                         #
  // ###########################################################################

  /**
   * @param productInstanceData - Product {@link TProductInstance} data
   * @param isLike - Whether the user has bookmarked the product or not
   * @param productIndex - Numerical index of the product in the displayed list,
   *  we pass {@param productIndex} only when using Swiper or Scrollable Catalog
   * @param productPosition - Name of the parent component {@link MixpanelPositionContext} wrapping the product card
   */
  private getProductData({
    productInstanceData,
    isLike,
    productIndex = null,
    productPosition = null,
  }: TMPProductDataProps): TMPProductData {
    // NOTE: properties that end with "Name" should always be the english/default name if possible

    const {
      character,
      preferences = [],
      product,
      price,
      identifier: gprlID,
      discount = 0,
      promotions = [],
    } = productInstanceData;
    const productCharacterID = character?.identifier || null;
    const {
      characteristics = [],
      region: regionOpenSearch = [],
      regions: regionOldCatalog = [],
      producer,
      format,
      identifier: productID = null,
      name: productName = null,
      category: categoryStringOrObj = null,
    } = (product as TProduct & { regions: { identifier: string; country: string; name: string }[] }) || {};
    // WARN: This shitty typecasting (and subsequent array expansion later) is necessary
    // because openSearch catalog has `region` field, but old catalog has `regions`.
    // Remove this shit when everything moves to openSearch catalog.
    const productPositionIndex = typeof productIndex === 'number' ? productIndex + 1 : null;

    const { identifier: productFormatID, name: productFormatName } = format || {};

    // WARN: this stupid shit is necessary because `category` typing is wrong and
    // happens to be an object in the widget designset instead of a string
    const category = categoryStringOrObj as string | { name: string };
    const productCategory = typeof category === 'string' ? category : category?.name;

    const productBookmarked = !!isLike;
    const productCharacteristics = characteristics?.map(v => v.name) || [];
    const productPromotions = promotions?.map(v => v.typeSlug) || [];
    const productTags =
      preferences?.filter(v => v.exposure === DIETARY_PREFERENCE_EXPOSURE.TAGS).map(v => v.slug) || [];

    const region = [...(regionOpenSearch || []), ...(regionOldCatalog || [])];
    const productOriginsID = region?.map(v => v.identifier) || [];
    const productOriginsName = region?.map(v => v.name) || [];
    const productOriginsCountry = region?.map(v => v.country) || [];

    const { identifier: productProducerID = null } = producer || {};
    const { name: productProducerName = null } = producer || {};
    const { country: productProducerCountry = null } = producer || {};

    const { value, currency: priceCurrency = null } = price || {};
    const productPrice = value || null;
    const productDiscounted = productPrice ? !!discount : null;
    const productDiscountedPrice = productPrice && discount ? productPrice - Number(discount) : null;

    return {
      gprlID,
      priceCurrency,
      productBookmarked,
      productCategory,
      productCharacterID,
      productCharacteristics,
      productDiscounted,
      productDiscountedPrice,
      productFormatID,
      productFormatName,
      productID,
      productName,
      productOriginsCountry,
      productOriginsID,
      productOriginsName,
      productPosition,
      productPositionIndex,
      productPrice,
      productProducerCountry,
      productProducerID,
      productProducerName,
      productPromotions,
      productTags,
    };
  }

  /**
   * Invoke this event when a user clicks on a product card.
   *
   * @param productInstanceData - Product information conforming to {@link TProductInstance} data structure
   * @param isLike - Whether the user bookmarked the product or not
   * @param productIndex - Numerical index of the product in the displayed list
   * @param productPosition - Descriptive name for the parent component wrapping the product card
   */
  public productClick(
    productInstanceData: Partial<TProductInstance>,
    isLike: boolean,
    productIndex: number,
    productPosition: MixpanelPositionContext,
  ) {
    const args: TMPProductClick = this.getProductData({
      isLike,
      productIndex,
      productInstanceData,
      productPosition,
    });

    this.utils.track(MP_EVENTS.PRODUCT_CLICK, args);
  }

  /**
   * Invoke this event when a user clicks on Download Product Details as PDF.
   *
   * @param productInstanceData - Product information conforming to {@link TProductInstance} data structure
   */
  public createProductPDF(productInstanceData: Partial<TProductInstance>) {
    const args: TMPProductClick = this.getProductData({
      productInstanceData,
    });

    this.utils.track(MP_EVENTS.CREATE_PRODUCT_PDF, args);
  }

  /**
   * Invoke this event when the user clicks on a product card or CTA that redirects
   * the user to an external product page.
   *
   * @param productInstanceData - Product information conforming to {@link TProductInstance} data structure
   * @param isLike - Whether the user bookmarked the product or not
   * @param productIndex - Numerical index of the product in the displayed list
   * @param productPosition - Descriptive name for the component displaying the product
   */
  public contactTheProductProducer(
    productInstanceData: Partial<TProductInstance>,
    isLike?: boolean,
    productIndex?: number,
    productPosition?: MixpanelPositionContext,
  ) {
    const args: TMPContactTheProductProducer = this.getProductData({
      isLike,
      productIndex,
      productInstanceData,
      productPosition,
    });

    this.utils.track(MP_EVENTS.CONTACT_THE_PRODUCT_PRODUCER, args);
  }

  /**
   * Invoke this event when the user clicks on the "Find Me" CTA.
   *
   * @param productInstanceData - Product information conforming to {@link TProductInstance} data structure
   * @param isLike - Whether the user bookmarked the product or not
   * @param productIndex - Numerical index of the product in the displayed list
   * @param productPosition - Descriptive name for the component displaying the product
   */
  public findMe(
    productInstanceData: Partial<TProductInstance>,
    isLike?: boolean,
    productIndex?: number,
    productPosition?: MixpanelPositionContext,
  ) {
    const args: TMPFindMe = this.getProductData({ isLike, productIndex, productInstanceData, productPosition });

    this.utils.track(MP_EVENTS.FIND_ME, args);
  }

  /**
   * Invoke this event when the user clicks on the "See Similar Products" CTA in Product page.
   *
   * @param productInstanceData - Product information conforming to {@link TProductInstance} data structure
   */
  public seeSimilarProductsClick(productInstanceData: Partial<TProductInstance>) {
    const args: TMPSeeSimilarProducts = this.getProductData({ productInstanceData });

    this.utils.track(MP_EVENTS.SEE_SIMILAR_PRODUCTS_CLICK, args);
  }

  /**
   * Invoke this event when the user clicks on the "Discover More Pairings Click" CTA in kiosk Product page.
   *
   * @param productInstanceData - Product information conforming to {@link TProductInstance} data structure
   */
  public discoverMorePairingsClick(productInstanceData: Partial<TProductInstance>) {
    const args: TMPDiscoverMorePairingsClick = this.getProductData({ productInstanceData });

    this.utils.track(MP_EVENTS.DISCOVER_MORE_PAIRINGS_CLICK, args);
  }

  /**
   * Invoke this event when the user clicks on the "more" CTA in Product page.
   *
   * @param productInstanceData - Product information conforming to {@link TProductInstance} data structure
   * @param isLike - Whether the user bookmarked the product or not
   * @param productPosition - Descriptive name for the component where the click occurs
   */
  public productStoryClick(
    productInstanceData: Partial<TProductInstance>,
    isLike?: boolean,
    productPosition?: MixpanelPositionContext,
  ) {
    const args: TMPProductStory = this.getProductData({ isLike, productInstanceData, productPosition });

    this.utils.track(MP_EVENTS.PRODUCT_STORY_CLICK, args);
  }

  /**
   * Invoke this event when the user clicks the bookmark in a product card.
   *
   * @param productInstanceData - Product information conforming to {@link TProductInstance} data structure
   * @param isLike - Whether the user bookmarked the product or not
   * @param productIndex - Numerical index of the product in the displayed list
   * @param productPosition - Descriptive name for the component displaying the product
   */
  public productBookmark(
    productInstanceData: Partial<TProductInstance>,
    isLike: boolean,
    productIndex: number,
    productPosition: MixpanelPositionContext,
  ) {
    // NOTE: `isLike` represents the value BEFORE the action is executed, so we invert it.
    const args: TMPProductBookmark = this.getProductData({
      isLike: !isLike,
      productIndex,
      productInstanceData,
      productPosition,
    });

    this.utils.track(MP_EVENTS.PRODUCT_BOOKMARK, args);
  }

  /**
   * Invoke this event when a product is scanned and the scan is successful
   * (i.e. it redirects to the product page).
   *
   * @param productInstanceDataOld - Object containing product information
   * @param code - The code scanned
   */
  public scanProduct(productInstanceDataOld: any, code: string) {
    // WARN: This ugly spaghetti is necessary because the product object coming from a scan
    // does not fully conform to IB2BB2CGRPL, but follows the old structure.
    // The next 4 lines should be removed after the object structure has been updated.
    const { character } = productInstanceDataOld.product;
    const { currency, price: valueStr } = productInstanceDataOld;
    const price = { currency, value: Number(valueStr) };
    const productInstanceData: TProductInstance = { ...productInstanceDataOld, character, price };

    const productData = this.getProductData({ productInstanceData });
    const args: TMPScanProduct = { ...productData, productEAN: code };

    this.utils.track(MP_EVENTS.SCAN_PRODUCT, args);
  }

  /**
   * Invoke this event when the user leaves feedback to a product.
   *
   * @param productInstanceData - Object containing product information
   * @param feedback - Product feedback, see {@link TProductFeedbackValue this type}
   */
  public productFeedback(productInstanceData: Partial<TProductInstance>, feedback: TProductFeedbackValue) {
    const productData = this.getProductData({ productInstanceData });
    const args: TMPProductFeedback = { ...productData, feedbackScore: feedback };

    this.utils.track(MP_EVENTS.PRODUCT_FEEDBACK, args);
  }

  /**
   * Invoke this event when the scan action fails, i.e. it's neither a product nor a
   * fidelity card.
   *
   * @param code - The code scanned
   */
  public scanFailed(code: string) {
    const args: TMPScanFailed = { codeScanned: code };

    this.utils.track(MP_EVENTS.SCAN_FAILED, args);
  }

  /**
   * Invoke this event when the user clicks on a CTA that leads directly to the product catalog.
   */
  public productCatalogClick() {
    this.utils.track(MP_EVENTS.PRODUCT_CATALOG_CLICK);
  }

  /**
   * Invoke this event when the user lands on the Product Catalog in the `vinhood-experience` designset.
   *
   * @param productCategory - Type of product being displayed as per {@link TProductCategory this interface}
   */
  public productCatalogView(productCategory: Exclude<TProductCategory, typeof PRODUCT_CATEGORY_WINE>): void;
  /**
   * Invoke this event when the user lands on the Product Catalog in the `vinhood-app` or `kiosk` designset.
   *
   * @param productCategory - Type of product being displayed as per {@link TProductCategory this interface}
   * @param shouldUseUserCharacter - Whether the product list reflects the user's character(s) or not
   */
  public productCatalogView(productCategory: TProductCategory, shouldUseUserCharacter: boolean | string): void;
  public productCatalogView(productCategory: TProductCategory, shouldUseUserCharacter?: boolean | string) {
    let followMyCharacters: boolean = null;
    if (shouldUseUserCharacter !== undefined) {
      followMyCharacters =
        typeof shouldUseUserCharacter === 'string'
          ? JSON.parse(shouldUseUserCharacter.toLowerCase())
          : !!shouldUseUserCharacter;
    }

    const args: TMPProductCatalogView = { followMyCharacters, productCategory };

    this.utils.track(MP_EVENTS.PRODUCT_CATALOG_VIEW, args);
  }

  /**
   * Invoke this event when the user clicks on a CTA to show more products.
   *
   * @param isOpenedState - Optional flag indicating whether the expanded product
   * list is opened or closed (where available)
   */
  public seeAllProducts(isOpenedState?: boolean) {
    if (isOpenedState) return; // if already opened, don't track
    this.utils.track(MP_EVENTS.SEE_ALL_PRODUCTS);
  }

  // ###########################################################################
  // #                                                                         #
  // #                               Experiences                               #
  // #                                                                         #
  // ###########################################################################

  /**
   * Invoke this event when the user clicks on an experience card ("Contact Organizer" CTA).
   *
   * @param experience - Experience data. See {@link IB2CExperience} for the data structure
   * @param currency - Currency in ISO 4217 format
   * @param isLike - Whether the user bookmarked the experience or not
   *
   * @beta
   */
  public viewProducerExperiencePage(experience: Partial<IB2CExperience>, currency: string, isLike: boolean) {
    const {
      region,
      producer,
      experience_type: experienceType,
      product_category: productType,
      user_product_preferences: userProductPreferences = [],
      price,
      identifier: experienceID,
      name: experienceName,
    } = experience;
    const { identifier: experienceRegionID = null, name: experienceRegionName = null } = region || {};
    const { identifier: experienceProducerID = null, name: experienceProducerName = null } = producer || {};
    const { identifier: experienceCategoryID = null } = experienceType || {};
    const { name: productCategory = null } = productType || {};
    const experienceTags = userProductPreferences.map(v => v.slug) || [];
    const experienceBookmarked = !!isLike;

    const experienceCategoryName =
      Object.keys(this.utils.pickBy(EXPERIENCES_TYPES, v => v === experienceCategoryID))[0] || null;

    const priceCurrencyInEnglish = new Intl.DisplayNames([languages.ENGLISH], { type: 'currency' });
    const experiencePrice = Number(price) || null;
    let priceCurrency = null;
    try {
      priceCurrency = priceCurrencyInEnglish.of(currency) || null;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error when assigning priceCurrency:', error);
    }

    const args: TMPViewProducerExperiencePage = {
      experienceBookmarked,
      experienceCategoryID,
      experienceCategoryName,
      experienceID,
      experienceName,
      experiencePrice,
      experienceProducerID,
      experienceProducerName,
      experienceRegionID,
      experienceRegionName,
      experienceTags,
      priceCurrency,
      productCategory,
    };

    this.utils.track(MP_EVENTS.VIEW_PRODUCER_EXPERIENCE_PAGE, args);
  }

  /**
   * Invoke this event when the user lands on the Experience Catalog not filtered
   * by category (e.g. when clicking on the footer).
   */
  public experienceGenericCatalogView() {
    this.utils.track(MP_EVENTS.EXPERIENCE_GENERIC_CATALOG_VIEW);
  }

  /**
   * Invoke this event when the user lands on the Experience Search Catalog, i.e.
   * the experience catalog already filtered by category (e.g. "Online classes", "Courses", etc).
   *
   * @param experienceCategoryID - Identifier of the experience type
   */
  public experienceCategoryCatalogView(experienceCategoryID: string = null) {
    const experienceCategoryName =
      Object.keys(this.utils.pickBy(EXPERIENCES_TYPES, v => v === experienceCategoryID))[0] || null;

    const args: TMPExperienceCategoryCatalogView = {
      experienceCategoryID,
      experienceCategoryName,
    };

    this.utils.track(MP_EVENTS.EXPERIENCE_CATEGORY_CATALOG_VIEW, args);
  }

  // ###########################################################################
  // #                                                                         #
  // #                                  Test                                   #
  // #                                                                         #
  // ###########################################################################

  private getTestData(testJson: any) {
    const { pk: userTestID = null }: { pk: number } = testJson || {};
    const { characters = [] } = testJson || {};
    const testCharacters = characters?.map(v => v.identifier) || [];

    const { quiz } = testJson || {};
    const {
      identifier: quizID = null,
      name: testName = null,
      quiz_type: quizTypeData = null,
      product_categories: categories = [],
    }: {
      identifier: string;
      name: string;
      quiz_type: { name: TQuizType; slug: TQuizType };
      product_categories: { name: string }[];
    } = quiz || {};
    const quizType = quizTypeData?.slug;
    const testProductCategories = categories?.map(v => v.name) || [];

    return { quizID, quizType, testCharacters, testName, testProductCategories, userTestID };
  }

  /**
   * Invoke this event when a user test starts (e.g. the first question shows up).
   *
   * @param testJson - JSON body of test data
   */
  @trackFacebookPixelEvent(MP_EVENTS.TEST_STARTED)
  public testStarted(testJson: TUserQuizDetail, foodPreferences?: string[] | null) {
    const { userTestID, quizID, testName, quizType, testProductCategories } = this.getTestData(testJson);
    const args: TMPTestStarted = { foodPreferences, quizID, quizType, testName, testProductCategories, userTestID };

    this.utils.track(MP_EVENTS.TEST_STARTED, args);
  }

  /**
   * Invoke this event when a user test successfully completes.
   *
   * @param testJson - JSON body of test data
   */
  @trackFacebookPixelEvent(MP_EVENTS.TEST_COMPLETED)
  public testCompleted(testJson: any) {
    const { userTestID, quizID, testName, quizType, testProductCategories, testCharacters } =
      this.getTestData(testJson);
    const args: TMPTestCompleted = { quizID, quizType, testCharacters, testName, testProductCategories, userTestID };

    this.utils.track(MP_EVENTS.TEST_COMPLETED, args);
  }

  /**
   * Invoke this event when the user clicks on a CTA that starts the taste test.
   */
  public startTasteTestClick() {
    this.utils.track(MP_EVENTS.START_TASTE_TEST_CLICK);
  }

  /**
   * Invoke this event when the user clicks on a CTA that starts a situational test.
   */
  public startSituationalTestClick() {
    this.utils.track(MP_EVENTS.START_SITUATIONAL_TEST_CLICK);
  }

  /**
   * Invoke this event when the user clicks on the "Start Food Pairing" CTA.
   */
  public startFoodPairingClick() {
    this.utils.track(MP_EVENTS.START_FOOD_PAIRING_CLICK);
  }

  /**
   * Invoke this event when the user lands on the result page.
   *
   * @param characters - Characters data of the result page as per {@link TCharacter this interface}
   */
  public resultPageView(characters: TCharacter[]) {
    const testCharacters = characters.map(c => c.identifier) || [];
    const args: TMPResultPageView = { testCharacters };

    this.utils.track(MP_EVENTS.RESULT_PAGE_VIEW, args);
  }

  // ###########################################################################
  // #                                                                         #
  // #                                  Other                                  #
  // #                                                                         #
  // ###########################################################################

  /**
   * Invoke this event when the user clicks on a banner.
   *
   * @param bannerID - Identifier of the banner (e.g. `NL0005`)
   * @param bannerText - Text of the banner
   * @param linkParams - Link query parameters or empty string
   * @param link - Describes where the banner leads as per {@link IBannerLink this interface}
   * @param currentPosition - Describes where the banner was clicked as per {@link IBannerPosition this interface}
   *
   * @beta
   */
  public bannerClick(
    bannerID: string = null,
    bannerText: string = null,
    link: IBannerLink,
    currentPosition: Partial<IBannerPosition>,
  ) {
    const { identifier: bannerDestinationID = null, route: bannerDestination = null } = link || {};
    const { identifier: bannerPositionID = null, route: bannerPosition = null } = currentPosition || {};

    const args: TMPBannerClick = {
      bannerDestination,
      bannerDestinationID,
      bannerID,
      bannerPosition,
      bannerPositionID,
      bannerText,
    };

    this.utils.track(MP_EVENTS.BANNER_CLICK, args);
  }

  /**
   * Invoke this event when the user clicks on a recipe card during recipe search.
   *
   * @param recipe - Data of the recipe as per {@link IB2CRecipe this interface}
   */
  public recipeClick(recipe: IB2CRecipe) {
    const { identifier: recipeID = null, name: recipeName = null, slug: recipeSlug = null } = recipe;
    const { characters } = recipe;
    const recipeCharactersID = characters.map(v => v.character.identifier) || [];

    const args: TMPRecipeClick = {
      recipeCharactersID,
      recipeID,
      recipeName,
      recipeSlug,
    };

    this.utils.track(MP_EVENTS.RECIPE_CLICK, args);
  }

  /**
   * Invoke this event when the user clicks on "Skip to <next-character-type>" CTA during taste path experience.
   *
   * @param characterTypeID - The ID of the character type that was skipped
   */
  public skipCharacterType(characterTypeID: string) {
    const args: TMPSkipCharacterType = { characterTypeID };
    this.utils.track(MP_EVENTS.SKIP_CHARACTER_TYPE, args);
  }

  /**
   * Invoke this event when the user clicks on "Discover the aroma and test" CTA during taste path experience.
   */
  public discoverQuizStart() {
    this.utils.track(MP_EVENTS.DISCOVER_QUIZ_START);
  }

  /**
   * Invoke this event when the user clicks on "Next, Please" CTA during taste path experience.
   *
   * @param questionType - The value of the quiz opened by the user
   * @param isCorrectAnswer - The boolean value of the answer (true or false)
   */
  public discoverQuizSubmit(questionType: string, correctAnswer: boolean) {
    const args: TMPDiscoverQuizSubmit = { correctAnswer, questionType };
    this.utils.track(MP_EVENTS.DISCOVER_QUIZ_SUBMIT, args);
  }

  /**
   * Invoke this event when the user skips the "Discover quiz" during taste path experience.
   */
  public discoverQuizSkip() {
    this.utils.track(MP_EVENTS.DISCOVER_QUIZ_SKIP);
  }

  /**
   * Invoke this event when a user clicks on Apply button in Product Catalog Filter:
   * in App, Widget, Mobile and physical kiosks
   *
   * @param productFilters - product filter state data.
   * See {@link IProductFilterState} for the data structure
   */
  public productCatalogFilter(productFilters: IProductFilterState) {
    const {
      toggle: { isCharacterToggleActive },
      range: { userLowerPriceRangeValue, userUpperPriceRangeValue },
      showOnly: { isWishlistToggleActive },
      sublist: {
        [FILTER_TYPE_STYLE]: styles = {},
        [FILTER_TYPE_CHARACTERISTICS]: characteristics = {},
        [FILTER_TYPE_ORIGIN]: origins = {},
      } = {},
    } = productFilters;

    const productOriginsID = Object.values(origins)
      .filter(({ isActive }) => isActive)
      .map(({ value }) => value);

    const productOriginsName = Object.values(origins)
      .filter(({ isActive }) => isActive)
      .map(({ name }) => name);

    const productCharacteristics = Object.values(characteristics)
      .filter(({ isActive }) => isActive)
      .map(({ value }) => value);

    const productStyles = Object.values(styles)
      .filter(({ isActive }) => isActive)
      .map(({ value }) => value);

    const args: TMPProductCatalogFilter = {
      followMyCharacters: isCharacterToggleActive,
      productCharacteristics,
      productMaximumPrice: userUpperPriceRangeValue,
      productMinimumPrice: userLowerPriceRangeValue,
      productOriginsID,
      productOriginsName,
      productStyles,
      showOnlySaved: isWishlistToggleActive,
    };

    this.utils.track(MP_EVENTS.PRODUCT_CATALOG_FILTER, args);
  }

  /**
   * Invoke this event when a user opens the "Promotion Coupon".
   *
   * @param fidelityCardID - The ID of the fidelity card
   * @param actionPerformed - Name of the action that executed this event
   */
  public specialPromoDisplayed(fidelityCardID: string, actionPerformed: MixpanelActionPerformedContext) {
    const args: TMPSpecialPromoDisplayed = {
      actionPerformed,
      fidelityCardID,
    };

    this.utils.track(MP_EVENTS.SPECIAL_PROMO_DISPLAYED.NAME, args);
  }

  /**
   * Invoke this event when the user clicks on a TasteMatch button on the product page.
   *
   * @param productInstanceData - Object containing {@link TProductInstance} data structure
   */
  public tasteMatchClick(productInstanceData: Partial<TProductInstance>) {
    const args: TMPTasteMatchClick = this.getProductData({ productInstanceData });

    this.utils.track(MP_EVENTS.TASTE_MATCH_CLICK, args);
  }

  /**
   * Invoke this event when the user clicks on a TasteMatch button on the explore page.
   *
   * @param locationName - retailer location name
   * @param locationId - retailer location Id
   * @param locationSlug - retailer location slug
   * @param tasteMatchLevel - retailer location taste_match value
   */
  public locationTasteMatchClick({
    locationName,
    locationId,
    locationSlug,
    tasteMatchLevel,
  }: TMPLocationTasteMatchClick) {
    const args: TMPLocationTasteMatchClick = { locationId, locationName, locationSlug, tasteMatchLevel };

    this.utils.track(MP_EVENTS.LOCATION_TASTE_MATCH_CLICK, args);
  }

  /**
   * Invoke this event when the user lands on the explore page.
   * @param locationName - retailer location name
   * @param locationId - retailer location Id
   * @param locationSlug - retailer location slug
   * @param tasteMatchLevel - retailer location taste_match value
   */
  public exploreSectionView({ locationName, locationId, locationSlug, tasteMatchLevel }: TMPExploreSectionView) {
    const args: TMPExploreSectionView = { locationId, locationName, locationSlug, tasteMatchLevel };

    this.utils.track(MP_EVENTS.EXPLORE_SECTION_VIEW, args);
  }

  /**
   * Invoke this event when the user bookmarks location on the explore page
   *
   * @param locationName - retailer location name
   * @param locationSlug - retailer location slug
   * @param locationId- retailer location id
   * @param locationBookmarked - status of location (saved or unsaved)
   * @param locationPositionIndex - location position index
   * @param locationPosition - location position
   * @param listName - location list name TBC

   */
  public locationBookmark({
    locationName,
    locationId,
    locationSlug,
    locationBookmarked,
    locationPositionIndex,
    locationPosition,
    listName,
  }: TMPLocationBookmark) {
    // NOTE: `locationBookmarked` represents the value BEFORE the action is executed, so we invert it.
    const args: TMPLocationBookmark = {
      listName,
      locationBookmarked: !locationBookmarked,
      locationId,
      locationName,
      locationPosition,
      locationPositionIndex,
      locationSlug,
    };

    this.utils.track(MP_EVENTS.LOCATION_BOOKMARK, args);
  }

  /**
   * Invoke this event when the user clicks on footer link.
   * @param pageName - page name
   */
  public footerNavigationClick({ pageName }: TMPFooterNavigationClick) {
    const args: TMPFooterNavigationClick = { pageName };

    this.utils.track(MP_EVENTS.FOOTER_NAVIGATION_CLICK, args);
  }

  /**
   * Invoke this event when the user clicks on a PromotionsBadges on productCard or ProductDetails page.
   * @param promotionId - promotion identifier
   * @param promotionSlug - retailer promotion slug
   * @param promotionTypeSlug - retailer promotion type slug
   * @param promotionDescription - promotion description text
   */
  public promotionDescriptionClick({
    promotionId,
    promotionSlug,
    promotionTypeSlug,
    promotionDescription,
  }: TMPPromotionDescriptionClick) {
    const args: TMPPromotionDescriptionClick = { promotionDescription, promotionId, promotionSlug, promotionTypeSlug };

    this.utils.track(MP_EVENTS.PROMOTION_DESCRIPTION_CLICK, args);
  }

  /**
   * Invoke this event when the user clicks Result page buttons to get character info.
   * @param productCharacterID - character Id
   */
  public characterDescriptionClick({ productCharacterID }: TMPCharacterDescriptionClick) {
    const args: TMPCharacterDescriptionClick = { productCharacterID };

    this.utils.track(MP_EVENTS.CHARACTER_DESCRIPTION_CLICK, args);
  }
}
